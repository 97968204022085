import React, { useState } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import { CustomLegend } from './CustomLegend';

const CustomLineChart = ({
  data,
  xAxisKey,
  keys,
  dot = false,
  connectNulls = false,
  formatter,
}) => {
  const [series, setSeries] = useState(keys.map(({ key }) => key));
  const labels = {};
  keys.forEach((key) => {
    labels[key.key] = key.label;
  });

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ left: -30 }}>
        <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip
          formatter={(value, name) => [
            formatter ? formatter(value) : value,
            labels[name],
          ]}
        />
        <Legend
          content={
            <CustomLegend
              series={series}
              setSeries={setSeries}
              labels={labels}
            />
          }
        />
        {keys.map(
          ({ key, color, show }) =>
            show && (
              <Line
                connectNulls={connectNulls}
                dot={dot}
                key={`line-${key}`}
                dataKey={series.indexOf(key) >= 0 ? key : `${key} `}
                stroke={color}
              />
            ),
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;

CustomLineChart.propTypes = {
  data: PropTypes.array,
  xAxisKey: PropTypes.string,
  keys: PropTypes.array,
  dot: PropTypes.bool,
  connectNulls: PropTypes.bool,
  formatter: PropTypes.func,
};
