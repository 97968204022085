import { stringify } from 'query-string';
import { action, observable } from 'mobx';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class NlaStore {
  @observable isLoadingNla = false;

  @observable isLoadingTable = false;

  @observable tableData = [];

  @observable totalRowsNumber = 0;

  @observable nla;

  @action fetchTable = async ({
    mediaName,
    mediaCode,
    organizationName,
    customerId,
    appType,
    creationFrom,
    creationTo,
    page,
    pageSize,
  }) => {
    this.isLoadingTable = true;
    try {
      const query = {
        media_name: mediaName,
        media_code: mediaCode,
        organization_name: organizationName,
        customer_id: customerId,
        ftse: appType,
        created_at_from: creationFrom,
        created_at_to: creationTo,
        limit: parseInt(pageSize.toString(), 10),
        skip: parseInt((page * pageSize).toString(), 10),
      };
      const response = await API.get(
        `${API_ROUTES.NLA.ALL}?${stringify(query)}`,
      );

      this.tableData = response.data.data.nla_usage_aggregates;
      this.totalRowsNumber = response.data.data.nla_usage_aggregates_count;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingTable = false;
    }
  };

  @action fetchNla = async ({ id }) => {
    this.isLoadingNla = true;
    try {
      const response = await API.get(`${API_ROUTES.NLA.BASE}?id=${id}`);
      this.nla = response.data.data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingNla = false;
    }
  };

  @action deleteNla = ({ id }) => {
    try {
      return API.delete(`${API_ROUTES.NLA.DELETE}?id=${id}`);
    } catch (e) {
      console.log(e);
    }
  };
}

export default new NlaStore();
