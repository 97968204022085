import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

export const CustomTooltipWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
`;

const CustomTooltip = ({ active, payload, label, type }) => {
  if (active && payload && payload.length) {
    const hasCompanyInside = payload[0]?.name.includes('company');
    return (
      <CustomTooltipWrapper className="custom-tooltip">
        {type === 'hourly' ? (
          <b>
            {moment(payload[0].payload.timeFrom).format('DD/MM/YYYY hh:mm')}
            &nbsp;-&nbsp;
            {moment(payload[0].payload.timeTo).format('hh:mm')}
          </b>
        ) : (
          <b>{`${moment(label).format('DD/MM/YYYY')}`}</b>
        )}
        <br />
        {!hasCompanyInside ? (
          <>
            Number of alerts: <b>{payload[0].value}</b>
          </>
        ) : (
          payload.map((p) => {
            const { companyName } = p.payload[p.name] || {};
            return (
              <Fragment key={`company-tooltip-${p.name}`}>
                {companyName}:<b> {p.value}</b>
                <br />
              </Fragment>
            );
          })
        )}
      </CustomTooltipWrapper>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default CustomTooltip;
