import React, { useState } from 'react';
import { StyledTabs, TabPanel, Tab } from './utils/tab';
import { GraphTab, TableTab } from './tabs';

const tabs = [
  { label: 'Graphic relations', page: <GraphTab /> },
  { label: 'Companies', page: <TableTab /> },
];

const CompaniesRelations = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <StyledTabs variant="fullWidth" value={value} onChange={handleChange}>
        {tabs.map(({ label }) => (
          <Tab key={`tab-gh-${label}`} label={label} />
        ))}
      </StyledTabs>
      {tabs.map(({ page }, index) => (
        <TabPanel key={`tab-gh-panel-${index}`} value={value} index={index}>
          {page}
        </TabPanel>
      ))}
    </>
  );
};

export default CompaniesRelations;
