import React, { useEffect } from 'react';
import Graph from 'react-vis-network-graph';
import { inject, observer, PropTypes } from 'mobx-react';
import { options } from '../utils/graphStyle';

const NetworkGraph = ({ companiesRelationsStore: { nodes, edges, fetchGraph } }) => {
  useEffect(() => {
    fetchGraph();
  }, []);

  return (
    <div style={{ height: 'calc(100vh - 210px)' }}>
      <Graph
        graph={{
          nodes,
          edges,
        }}
        options={options}
      />
    </div>
  );
};

export default inject('companiesRelationsStore')(observer(NetworkGraph));

NetworkGraph.propTypes = {
  companiesRelationsStore: {
    nodes: PropTypes.any,
    edges: PropTypes.any,
    fetchGraph: PropTypes.func,
  },
};
