import React, { useState } from 'react';
import ReactPropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Backdrop from '@material-ui/core/Backdrop';
import { Paper } from '@material-ui/core';
import Loader from '../../_common/components/Loader';
import DeleteModal from './DeleteModal';
import ReportTable from '../../_common/components/ReportTable';
import { APP_ROUTES } from '../../_app/routes';
import { COLUMNS } from '../constants';

function Table({
  nlaStore: { tableData, totalRowsNumber, deleteNla, isLoadingTable },
  page,
  setPage,
  pageSize,
  setPageSize,
  fetchData,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [removedId, setRemovedId] = useState();

  const onSubmit = (params) => {
    setPageSize(params.limit || 10);
    setPage(params.page || 0);
  };

  const handleDelete = async () => {
    await deleteNla({ id: removedId });
    setModalOpen(false);
    fetchData();
  };

  const columns = COLUMNS;
  const actionButtonsColumn = columns.find((column) => (column.id === 'action_buttons'));
  actionButtonsColumn.text = (_, row) => (
    <Button
      color="secondary"
      type="button"
      onClick={() => {
        setRemovedId(row.id);
        setModalOpen(true)
      }}
    >
      Delete
    </Button>
  );

  return (
    <Paper style={{ width: '100%', marginTop: '16px' }}>
      {isLoadingTable &&
        <Backdrop open style={{ zIndex: 1000, backgroundColor: 'white', opacity: 0.5 }}>
          <Loader size={60} />
        </Backdrop>
      }
      <ReportTable
        report={tableData}
        columns={columns}
        idToPath={(id) => generatePath(APP_ROUTES.NLA_PREVIEW, { id })}
        onSubmit={onSubmit}
        totalRowsNumber={totalRowsNumber}
        limit={pageSize}
        page={page}
      />
      <DeleteModal
        isOpen={isModalOpen}
        setClose={() => setModalOpen(false)}
        handleDelete={handleDelete}
      />
    </Paper>
  );
}

Table.propTypes = {
  nlaStore: ReactPropTypes.shape({
    tableData: MobXPropTypes.array,
    totalRowsNumber: MobXPropTypes.array,
    fetchTable: MobXPropTypes.func,
    isLoadingTable: MobXPropTypes.bool,
    deleteNla: MobXPropTypes.func,
  }),
  page: ReactPropTypes.number,
  pageSize: ReactPropTypes.number,
  setPage: ReactPropTypes.func,
  setPageSize: ReactPropTypes.func,
  fetchData: ReactPropTypes.func,
};

export default inject('nlaStore')(observer(Table));
