import queryString from 'query-string';
import ReactPropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from './table';
import Filters, {
  BRIEFING_TYPE_KEY,
  CREATION_FROM_KEY,
  CREATION_TO_KEY,
  PUBLICATION_FROM_KEY,
  PUBLICATION_TO_KEY,
} from './filters';
import { APP_ROUTES } from '../../_app/routes';
import Loader from '../../_common/components/Loader';

function ListingContent({
  summaryReportsStore: { fetchTable, isLoadingTable },
}) {
  const history = useHistory();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const getValues = () => {
    const { search } = history.location;
    const params = new URLSearchParams(search);
    return {
      briefingId: params.get(BRIEFING_TYPE_KEY) || undefined,
      creationFrom: params.get(CREATION_FROM_KEY) || undefined,
      creationTo: params.get(CREATION_TO_KEY) || undefined,
      publicationFrom: params.get(PUBLICATION_FROM_KEY) || undefined,
      publicationTo: params.get(PUBLICATION_TO_KEY) || undefined,
    };
  };

  const handleFilterSubmit = (values) => {
    setPage(0);
    history.push({
      pathname: APP_ROUTES.SUMMARY_REPORTS,
      search: queryString.stringify(values),
    });
  };

  useEffect(() => {
    fetchTable({
      pageSize,
      page,
      ...getValues(),
    });
  }, [pageSize, page, history.location]);

  if (isLoadingTable) return <Loader size={60} />;

  return (
    <>
      <Filters
        getInitialValues={getValues}
        onSubmit={handleFilterSubmit}
        isOpen={isFilterOpen}
        setOpen={setFilterOpen}
      />
      <Table
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
}

ListingContent.propTypes = {
  summaryReportsStore: ReactPropTypes.shape({
    fetchTable: MobXPropTypes.func,
    isLoadingTable: MobXPropTypes.bool,
  }),
};

export default inject('summaryReportsStore')(observer(ListingContent));
