import React from 'react';
import PropTypes from 'prop-types';
// import Mobile from '@material-ui/icons/MobileFriendly';
import Mail from '@material-ui/icons/MailOutline';
import DraftsOutlined from '@material-ui/icons/DraftsOutlined';
import Desktop from '@material-ui/icons/DesktopMac';
import { Flex } from 'rebass';
import File from '@material-ui/icons/FileCopy';
import IconWithText from '../../_common/components/IconWithText';

const StatsCounters = ({
  emailsOpened,
  emailsSent,
  // mobileAppStarted,
  webAppOpened,
  articleRead,
  uniqueEmailsOpen,
  isLoading,
}) => (
  <Flex justifyContent="space-between" flexWrap="wrap" mt={60} my={20}>
    <IconWithText
      icon={Mail}
      mainText={emailsSent}
      isLoading={isLoading}
      text="emails sent"
    />
    <IconWithText
      icon={DraftsOutlined}
      mainText={emailsOpened}
      isLoading={isLoading}
      text="emails opened"
    />
    <IconWithText
      icon={DraftsOutlined}
      mainText={`${uniqueEmailsOpen} (${
        emailsSent !== 0
          ? `${((uniqueEmailsOpen / emailsSent) * 100).toFixed(2)}%`
          : 'N/A'
      })`}
      isLoading={isLoading}
      text="unique emails opened"
    />
    {/*
    <IconWithText
      icon={Mobile}
      mainText={mobileAppStarted}
      isLoading={isLoading}
      text="times mobile app started"
    />
    */}
    <IconWithText
      icon={Desktop}
      mainText={webAppOpened}
      isLoading={isLoading}
      text="web app opened"
    />
    <IconWithText
      icon={File}
      mainText={articleRead}
      isLoading={isLoading}
      text="articles read"
    />
  </Flex>
);

StatsCounters.propTypes = {
  emailsOpened: PropTypes.number,
  uniqueEmailsOpen: PropTypes.number,
  emailsSent: PropTypes.number,
  // mobileAppStarted: PropTypes.number,
  webAppOpened: PropTypes.number,
  articleRead: PropTypes.number,
  isLoading: PropTypes.bool,
};

StatsCounters.defaultProps = {
  emailsOpened: 0,
  emailsSent: 0,
  // mobileAppStarted: 0,
  webAppOpened: 0,
  articleRead: 0,
  uniqueEmailsOpen: 0,
};

export default StatsCounters;
