import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormSelect from '../../../_common/components/forms/FormSelect';
import TextField from '../../../_common/components/forms/TextField';
import TextFieldSearch from '../../../_common/components/forms/TextFieldSearch';
import { ORDER_LABELS } from '../../../_app/constants';
import AutoSave from '../../../_common/components/forms/AutoSave';
import { validateDate } from '../../../utils/formValidators';
// import { fetchEventPromptsEmail } from '../../../_app/utils';

const useStyles = makeStyles(() => ({
  actionButton: {
    margin: '0 10px',
  },
}));

const ArchivedArticlesForm = ({ onSubmit, initialValues }) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={(values) => onSubmit({ ...values, page: 1 })}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, values, mutators, form }) => (
        <form id="EventsForm" onSubmit={handleSubmit}>
          <AutoSave debounce={1000} save={handleSubmit} values={values} />
          <Flex mx={-15} flexWrap="wrap">
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="Author (contains)"
                name="author"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="Title (contains)"
                name="title"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="Summary (contains)"
                name="summary"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="URL (contains)"
                name="url"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="Orig guid (contains)"
                name="orig_guid"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="Article source ID"
                name="article_source_id"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                label="Content (contains)"
                name="content"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                name="nla_link (contains)"
                label="Nla link"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <FormSelect options={ORDER_LABELS} name="order" label="Order" />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={15}>
              <TextField
                label="Publication date from"
                type="date"
                name="pubdate_from"
                validate={(value, allValues) =>
                  validateDate(value, allValues, {
                    from: 'pubdate_from',
                    to: 'pubdate_to',
                  })
                }
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={15}>
              <TextField
                label="Publication date to"
                type="date"
                name="pubdate_to"
                validate={(value, allValues) =>
                  validateDate(value, allValues, {
                    from: 'pubdate_from',
                    to: 'pubdate_to',
                  })
                }
              />
            </Box>
          </Flex>
          <Flex width={1} justifyContent="flex-end">
            <Button
              className={classes.actionButton}
              size="large"
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => form.reset()}
            >
              Clear
            </Button>
          </Flex>
        </form>
      )}
    />
  );
};

ArchivedArticlesForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export default ArchivedArticlesForm;
