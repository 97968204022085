import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from 'rebass';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import { APP_ROUTES } from '../../_app/routes';
import StyledLink from '../../_common/components/StyledLink';
import Loader from '../../_common/components/Loader';

const RecentEvents = ({ data, isLoading }) => (
  <Paper>
    <Text fontSize={25} ml={28} pt={20}>
      Events
    </Text>
    <Box px={25} pb={25}>
      {isLoading && <Loader size={40} />}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>User email</TableCell>
            <TableCell>Medium</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <TableRow key={`table-row-${idx}`}>
              <TableCell>
                {moment(row.timestamp).format('MMMM Do YYYY, h:mm:ss A')}
              </TableCell>
              <TableCell>{row.user_email}</TableCell>
              <TableCell>{row.medium}</TableCell>
              <TableCell>{row.action}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box mt={15}>
        <StyledLink to={APP_ROUTES.USERS}>See more details</StyledLink>
      </Box>
    </Box>
  </Paper>
);

RecentEvents.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default RecentEvents;
