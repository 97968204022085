import { useMemo } from 'react';
import forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';
import { DATE_FORMAT, DATE_FORMAT_HOURLY } from '../components/ExportToExcel';

export default function useMultipleCompanyExcelData(chartData, dateFormat) {
  const excelData = useMemo(() => {
    function prepareColumnNames() {
      try {
        return compact(
          map(_keys(chartData[0]), (key) => {
            if (key.includes('company')) {
              return {
                label: chartData[0][key]?.companyName,
                value: chartData[0][key]?.companyName,
              };
            }
          }),
        );
      } catch {
        return [];
      }
    }

    function prepareExcelData() {
      try {
        const grouppedByTimeFromCollection = groupBy(chartData, 'timeFrom');
        const decoratedData = map(
          _keys(grouppedByTimeFromCollection),
          (timeFrom) => {
            const keys = _keys(grouppedByTimeFromCollection[timeFrom][0]);
            const excelRow = { date: new Date(timeFrom) };
            forEach(keys, (key) => {
              if (key.includes('company')) {
                excelRow[
                  grouppedByTimeFromCollection[timeFrom][0][key].companyName
                ] =
                  grouppedByTimeFromCollection[timeFrom][0][key].numberOfAlerts;
              }
            });
            return excelRow;
          },
        );
        return sortBy(decoratedData, 'date');
      } catch {
        return [];
      }
    }

    return {
      columns: [
        {
          label: 'Date',
          value: 'date',
          format: dateFormat === 'hourly' ? DATE_FORMAT_HOURLY : DATE_FORMAT,
        },
        ...prepareColumnNames(),
      ],
      data: prepareExcelData(),
    };
  }, [chartData]);

  return excelData;
}
