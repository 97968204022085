import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Flex } from 'rebass';
import Button from '@material-ui/core/Button';
import TextField from '../../../_common/components/forms/TextField';
import {
  required,
  composeValidators,
  mustBeValidEmail,
} from '../../../utils/formValidators';
import { Error } from '../../../_common/components/forms/FormError';

const SignInForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitError, submitting }) => (
      <form onSubmit={handleSubmit}>
        <Flex width={1} mt={20} alignItems="center" flexDirection="column">
          <TextField
            label="Email"
            name="email"
            validate={composeValidators(required, mustBeValidEmail)}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            validate={required}
          />
          {submitError && <Error>{submitError}</Error>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Sign In
          </Button>
        </Flex>
      </form>
    )}
  />
);

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignInForm;
