import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import Loader from '../../_common/components/Loader';
import ReportLabel from '../../_common/components/ReportLabel';
import ReportPreview from '../../_common/components/ReportPreview';
import { APP_ROUTES } from '../../_app/routes';
import { COLUMNS } from  '../constants';

function ContentPublicationPreview({
  contentPublicationsStore: {
    preview,
    fetchContentPublication,
    isLoading,
  }
}) {
  const { id } = useParams();
  const entryId = parseInt(id || -1, 10);

  useEffect(() => {
    (async () => {
      await fetchContentPublication({ id });
    })();
  }, [id]);

  return (
    <>
      <ReportLabel
        entryId={entryId}
        parentLabel="Content publications"
        parentLink={APP_ROUTES.CONTENT_PUBLICATIONS}
      />

      {
        isLoading || Object.keys(preview).length === 0 ?
          <Loader size={60} />
        :
          <ReportPreview
            entryId={entryId}
            cells={COLUMNS.map(column => column.get(preview[column.id]))}
          />
      }
    </>
  );
}

ContentPublicationPreview.propTypes = {
  contentPublicationsStore: PropTypes.shape({
    fetchContentPublication: PropTypes.func,
    preview: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
};

export default inject('contentPublicationsStore')(observer(ContentPublicationPreview));