import React, { useEffect, useMemo } from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Flex } from 'rebass';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import FormSelect from '../../_common/components/forms/FormSelect';
import Autocomplete from '../../_common/components/forms/Autocomplete';
import AutoSave from '../../_common/components/forms/AutoSave';
import CustomLineChart from '../components/CustomLineChart';

import { HeaderWrapper, Filters } from './style';
import DailyBarChart from '../components/DailyBarChart';
import ExcelDownloadButton from '../components/ExportToExcel';
import useMultipleCompanyChartKeys from './useMultipleCompanyChartKeys';
import useMultipleCompanyExcelData from './useMultipleCompanyExcelData';

const CompanyTab = ({
  alertsStatisticsStore: {
    barChartData,
    lineChartData,
    companies,
    prepareCompanyStatistics,
    filtersChange,
    selectedCompanyIds,
    selectedCompanyNames,
    selectedTimeframe,
    timeframeOptions,
  },
}) => {
  const { search } = useLocation();

  useEffect(() => {
    prepareCompanyStatistics(search);
  }, [search]);

  const initialValues = useMemo(
    () => ({
      companies: selectedCompanyIds,
      timeframe: selectedTimeframe,
    }),
    [selectedCompanyIds, selectedTimeframe],
  );

  const companyOptions = useMemo(
    () => map(companies, ({ id, name }) => ({ label: name, value: id })),
    [companies],
  );

  const onSubmit = ({ companies: companyIds, ...values }) => {
    const query = { ...values };
    const companiesByComma = (companyIds || []).join(',');
    if (companiesByComma) {
      query.companies = companiesByComma;
    }
    filtersChange(query);
  };

  const barDataKeys = useMultipleCompanyChartKeys(barChartData);
  const lineDataKeys = useMultipleCompanyChartKeys(lineChartData);

  const excelDailyData = useMultipleCompanyExcelData(barChartData, 'daily');
  const excelHourlyData = useMultipleCompanyExcelData(lineChartData, 'hourly');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, values, form }) => (
        <form id="company-filters-form" onSubmit={handleSubmit}>
          <AutoSave debounce={100} save={handleSubmit} values={values} />
          <HeaderWrapper mt={4}>
            <Typography variant="h4" gutterBottom>
              {selectedCompanyNames}
            </Typography>
            <Filters>
              {companies.length > 0 ? (
                <Autocomplete
                  options={companyOptions}
                  name="companies"
                  isMulti
                  label="Companies"
                />
              ) : null}
              {timeframeOptions.length > 0 ? (
                <FormSelect
                  containerProps={{ maxWidth: '180px' }}
                  options={timeframeOptions}
                  name="timeframe"
                  label="Timeframe"
                />
              ) : null}
            </Filters>
          </HeaderWrapper>

          <Box mb={1}>
            <Paper>
              <Box p={4}>
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Typography variant="h6">Daily</Typography>
                  {!isEmpty(excelDailyData.data) && (
                    <ExcelDownloadButton
                      preparedContent={excelDailyData.data}
                      preparedColumns={excelDailyData.columns}
                      name={`${selectedCompanyNames}_daily-alerts-statistics`}
                      title={selectedCompanyNames}
                    />
                  )}
                </Flex>

                <DailyBarChart keys={barDataKeys} data={barChartData} />
              </Box>
            </Paper>
          </Box>
          <Box>
            <Paper>
              <Box p={4}>
                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Typography variant="h6">Hourly</Typography>
                    {!isEmpty(excelHourlyData.data) && (
                      <ExcelDownloadButton
                        preparedContent={excelHourlyData.data}
                        preparedColumns={excelHourlyData.columns}
                        name={`${selectedCompanyNames}_hourly-alerts-statistics`}
                        title={selectedCompanyNames}
                      />
                    )}
                  </Flex>
                </Box>
                <CustomLineChart
                  data={lineChartData}
                  keys={lineDataKeys}
                  xAxisKey="timeFrom"
                />
              </Box>
            </Paper>
          </Box>
        </form>
      )}
    />
  );
};

CompanyTab.propTypes = {
  alertsStatisticsStore: PropTypes.shape({
    barChartData: PropTypes.arrayOf(
      PropTypes.shape({
        numberOfAlerts: PropTypes.number,
        sectorId: PropTypes.number,
        timeFrom: PropTypes.string,
      }),
    ).isRequired,
    lineChartData: PropTypes.arrayOf(
      PropTypes.shape({
        numberOfAlerts: PropTypes.number,
        sectorId: PropTypes.number,
        timeFrom: PropTypes.string,
        timeTo: PropTypes.string,
      }),
    ).isRequired,
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
    prepareCompanyStatistics: PropTypes.func.isRequired,
    resetCompanyStatistics: PropTypes.func.isRequired,
    filtersChange: PropTypes.func.isRequired,
    selectedCompanyIds: PropTypes.arrayOf(PropTypes.number),
    selectedCompanyNames: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    timeframeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
};

export default inject('alertsStatisticsStore')(observer(CompanyTab));
