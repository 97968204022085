import React from 'react';
import { PropTypes } from 'mobx-react';
import { Tabs, withStyles, Tab } from '@material-ui/core';

const TabPanel = ({ children, value, index, ...other }) => (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#8e7cc3',
  },
})((props) => <Tabs {...props} />);

export { StyledTabs, TabPanel, Tab };

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};
