import React from 'react';
import ReactPropTypes from 'prop-types';
import { Link, generatePath } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import ReactTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { APP_ROUTES } from '../../_app/routes';
import { truncate, datePrettier } from '../../utils/text';

const columns = [
  {
    id: 'created_at',
    label: 'Created at',
    align: 'left',
    type: 'date',
  },
  {
    id: 'published_at',
    label: 'Published at',
    align: 'left',
    type: 'date',
  },
  {
    id: 'analyst',
    label: 'Analyst',
    align: 'left',
  },
  {
    id: 'source',
    label: 'Source',
    align: 'left',
  },
  {
    id: 'article_url',
    type: 'link',
    label: 'Article URL',
    align: 'left',
  },
  {
    id: 'view_button',
    type: 'button',
  },
];

function Table({
  contentReportsStore: { tableData, totalRowsNumber },
  page,
  setPage,
  pageSize,
  setPageSize,
}) {
  const handleChangePage = async (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const columnRenderer = (column, row) => {
    if (column.type === 'button') {
      return (
        <Button
          variant="outlined"
          color="primary"
          type="button"
          href={generatePath(APP_ROUTES.CONTENT_REPORT_PREVIEW, {
            id: row.id,
          })}
        >
          View
        </Button>
      );
    }
    if (column.type === 'date') {
      return datePrettier(row[column.id]);
    }
    if (column.type === 'link') {
      return (
        <Link to={{ pathname: row[column.id] }} target="_blank">
          {truncate(row[column.id], 30)}
        </Link>
      );
    }
    return column.format ? column.format(row[column.id]) : row[column.id];
  };

  return (
    <Paper style={{ width: '100%', marginTop: '16px' }}>
      <TableContainer style={{ height: '100%' }}>
        <ReactTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((row) => (
                <TableRow
                  key={`row-${row.id}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align}>
                      {columnRenderer(column, row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </ReactTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRowsNumber}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

Table.propTypes = {
  contentReportsStore: ReactPropTypes.shape({
    tableData: MobXPropTypes.array,
    totalRowsNumber: MobXPropTypes.array,
    fetchTable: MobXPropTypes.func,
    isLoadingTable: MobXPropTypes.bool,
  }),
  page: ReactPropTypes.number,
  pageSize: ReactPropTypes.number,
  setPage: ReactPropTypes.func,
  setPageSize: ReactPropTypes.func,
};

export default inject('contentReportsStore')(observer(Table));
