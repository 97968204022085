import mapValues from 'lodash/mapValues';

export const DEFAULT_DATE_FORMAT = 'D/M/YYYY h:mm:ss A';

const SYMBOL_EMPTY = '\u2013';

const isEmpty = (value) => !value || value === '[empty]' || value === '[nonexistent]';

export const formatEmpty = (proc) => ((value) => isEmpty(value) ? SYMBOL_EMPTY : (proc === undefined ? value : proc(value)));


class ColumnDescription {
  id = null;
  makeFunction = (obj) => (obj instanceof Function ? obj : (() => obj));
  label = () => this.id[0].toUpperCase() + this.id.substring(1).replaceAll('_', ' ');
  align = (value) => isEmpty(value) ? 'center' : 'left';
  text = formatEmpty();
  meta = {};

  constructor({ id, label, align, text, ...args }) {
    this.id = id;
    if (label !== undefined) {
      this.label = this.makeFunction(label);
    }
    if (align !== undefined) {
      this.align = this.makeFunction(align);
    }
    if (text !== undefined) {
      this.text = text;
    }
    this.params = { ...args };
  }

  useArg(object, ...args) {
    return mapValues(this, value => (value instanceof Function ? value(...args) : value));
  }

  get(...args) {
    return { ...this.useArg(this, ...args), params: this.useArg(this.params, ...args) };
  }
};

export default ColumnDescription;