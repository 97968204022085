import styled from 'styled-components';

export const ChartContainer = styled.div`
  margin: 30px 0 0 0;
`;

export const CountersContainer = styled.div`
  margin: 30px 0 0 30px;
`;

export const DatePickersContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ExportContainer = styled.div`
  display: flex;
  padding: 20px;
`;

export const SelectContainer = styled.div`
  margin: ${(props) => (props.margin ? `${props.margin}` : '20px 0px 0px 30px')}
  width: ${(props) => `${props.width}`}
`;
