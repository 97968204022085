import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { inject, observer } from 'mobx-react';
import Pagination from 'material-ui-flat-pagination';
import { Box, Flex, Text } from 'rebass';
import moment from 'moment';
import ArchivedArticlesForm from './components/forms/ArchivedArticlesForm';
import { APP_ROUTES } from '../_app/routes';
import ArchivedArticlesList from './components/ArchivedArticlesList';
import MyChart from '../_common/components/BarChart';
import Loader from '../_common/components/Loader';
import styles from '../theme';

const DEFAULT_LIMIT = 20;

const DEFAULT_QUERY_PARAMS = {
  limit: DEFAULT_LIMIT,
  page: 1,
  order: 'desc',
  pubdate_from: moment().subtract({ month: 2 }).format('YYYY-MM-DD'),
  pubdate_to: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
};

@inject('archivedArticlesStore')
@observer
class ArchivedArticles extends Component {
  componentDidMount() {
    const {
      location: { search },
      archivedArticlesStore: {
        fetchArticles,
        // fetchArticlesCountsPerDay,
      },
    } = this.props;
    if (isEmpty(search)) {
      this.onSubmit(this.initialValues());
    }
    if (search === `?${queryString.stringify(this.initialValues())}`) {
      fetchArticles(search);
      // fetchArticlesCountsPerDay(search);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (isEmpty(search)) {
      return this.onSubmit(this.initialValues());
    }
    if (prevProps.location.search !== search) {
      this.props.archivedArticlesStore.fetchArticles(search);
      // this.props.archivedArticlesStore.fetchArticlesCountsPerDay(search);
    }
  }

  onSubmit = (values) =>
    this.props.history.push({
      pathname: APP_ROUTES.ARCHIVED_ARTICLES,
      search: queryString.stringify(values),
    });

  initialValues = () =>
    merge(DEFAULT_QUERY_PARAMS, queryString.parse(this.props.location.search));

  render() {
    const {
      isLoading,
      archivedArticles,
      pagesTotal,
      itemsTotal,
      barChartData,
      formData,
    } = this.props.archivedArticlesStore;
    const {
      page,
      limit = DEFAULT_LIMIT,
      ...restQuery
    } = queryString.parse(this.props.location.search);

    return (
      <>
        {barChartData.length ? <MyChart data={barChartData} /> : ''}
        <ArchivedArticlesForm
          onSubmit={this.onSubmit}
          initialValues={this.initialValues()}
          formData={formData}
        />
        {isLoading && <Loader size={60} />}
        <ArchivedArticlesList archivedArticles={archivedArticles} />
        <Flex width={1} pt={25} justifyContent="center">
          <Box>
            <Text color={styles.colors.mainPurple} fontSize={15}>
              Items {limit * (page - 1) + 1}
              {'-'}
              {archivedArticles.length + limit * (page - 1)} of {itemsTotal}
            </Text>
          </Box>
        </Flex>
        <Flex width={1} pt={10} pb={15} justifyContent="center">
          <Pagination
            limit={limit}
            offset={limit * (page - 1)}
            total={(pagesTotal || page) * limit}
            onClick={(e, offset, pageNum) =>
              this.onSubmit({ ...restQuery, page: pageNum, limit })
            }
          />
        </Flex>
      </>
    );
  }
}

ArchivedArticles.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  archivedArticlesStore: PropTypes.shape({
    fetchArticles: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingCounters: PropTypes.bool,
    archivedArticles: PropTypes.array,
    pagesTotal: PropTypes.number,
    itemsTotal: PropTypes.number,
    barChartData: PropTypes.array,
    fetchArticlesCountsPerDay: PropTypes.func,
    formData: PropTypes.object,
  }),
};

export default ArchivedArticles;
