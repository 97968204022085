import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { Box } from 'rebass';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

function renderInput(inputProps) {
  const { InputProps, classes, ref, value, onChangeTextField, ...other } =
    inputProps;
  return (
    <TextField
      value={value}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
      type="outline"
      variant="outlined"
      onChange={(e) => {
        onChangeTextField(e, value);
      }}
    />
  );
}

function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
    >
      {suggestion}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

let suggestions = [];

const TextFieldSearch = ({
  name,
  label,
  setValue,
  myOnChange,
  ignoreSuggestions,
}) => {
  const classes = useStyles();
  return (
    <Field name={name}>
      {({ input }) => (
        <Box mb={16} width={1}>
          <div className={classes.root}>
            <Downshift
              id="downshift-simple"
              onChange={(selection) => {
                input.value = selection;
                setValue(name, selection);
              }}
            >
              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                highlightedIndex,
                isOpen,
              }) => {
                const { onBlur, onFocus, onChange, ...inputProps } =
                  getInputProps(input);
                const onChangeTextField = async (e) => {
                  onChange(e);
                  suggestions = [];
                  suggestions = await myOnChange(e.target.value);
                };

                return (
                  <div className={classes.container}>
                    {renderInput({
                      fullWidth: true,
                      classes,
                      label,
                      InputLabelProps: getLabelProps({ shrink: true }),
                      InputProps: { onBlur, onFocus },
                      inputProps,
                      value: input.value,
                      onChangeTextField,
                    })}

                    <div {...getMenuProps()}>
                      {!ignoreSuggestions && isOpen ? (
                        <Paper
                          style={{ zIndex: 1000 }}
                          className={classes.paper}
                          square
                        >
                          {suggestions.map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({ item: suggestion }),
                              highlightedIndex,
                            }),
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  </div>
                );
              }}
            </Downshift>
          </div>
        </Box>
      )}
    </Field>
  );
};

TextFieldSearch.propTypes = {
  label: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  myOnChange: PropTypes.func,
  ignoreSuggestions: PropTypes.bool,
};

export default TextFieldSearch;
