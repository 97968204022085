import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  AreaChart as Chart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export default class AreaChart extends PureComponent {
  render() {
    const { data, onChartClick } = this.props;
    return (
      <ResponsiveContainer width="100%" height={300}>
        <Chart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 20,
          }}
          onClick={onChartClick}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="total"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </Chart>
      </ResponsiveContainer>
    );
  }
}

AreaChart.propTypes = {
  data: PropTypes.array,
  onChartClick: PropTypes.func,
};
