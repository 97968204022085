import { action, observable } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class ArticleAndClicksStore {
  @observable isLoading = false;
  @observable isLoadingCounters = false;
  @observable isLoadingArticleSources = false;

  @observable articleSources = [];

  @observable userEvents = {
    30: [],
    180: [],
    excel: [],
  };
  @observable userEventsCount = {
    30: 0,
    180: 0,
  };

  @observable chartData = [];
  @observable counters = {};

  @observable articleViews = {
    30: 0,
    180: 0,
  };
  @observable articlePublications = {
    30: 0,
    180: 0,
  };

  @observable excelData = [];
  @observable isLoadingExcelData = false;

  @action fetchTotals = async (startDate, articleSourceId) => {
    this.isLoading = true;
    this.isLoadingCounters = true;
    this.chartData = [];
    this.articleViews = {
      30: 0,
      180: 0,
    };
    this.articlePublications = {
      30: 0,
      180: 0,
    };

    const query = {
      from: startDate.toISOString(),
    };

    if (articleSourceId) {
      query.articleSourceId = articleSourceId;
    }

    try {
      const {
        data: { data: statrafEvents },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS.DATE_RANGE_ARTICLES_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );
      const {
        data: {
          data: { data: countOfPublicationsWithArticles },
        },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS
            .CONTENT_PUBLICATIONS_WITH_ARTICLES_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );

      const { data, articleViews, articlePublications } = this.mergeData(
        statrafEvents,
        countOfPublicationsWithArticles,
        startDate,
      );

      this.chartData = data;
      this.articleViews = articleViews;
      this.articlePublications = articlePublications;
      this.isLoading = false;
      this.isLoadingCounters = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchExcelTotals = async (startDate, endDate, articleSourceId) => {
    this.isLoadingExcelData = true;
    this.excelData = [];

    const query = {
      from: startDate,
      to: endDate,
    };

    if (articleSourceId) {
      query.articleSourceId = articleSourceId;
    }

    try {
      const {
        data: { data: statrafEvents },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS.DATE_RANGE_ARTICLES_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );
      const {
        data: {
          data: { data: countOfPublicationsWithArticles },
        },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS
            .CONTENT_PUBLICATIONS_WITH_ARTICLES_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );

      const { data } = this.mergeData(
        statrafEvents,
        countOfPublicationsWithArticles,
        startDate,
        moment(endDate),
      );

      this.excelData = data;
      this.isLoadingExcelData = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchUniqueCounts = async (startDate, distinctKey) => {
    this.isLoading = true;
    this.isLoadingCounters = true;
    this.chartData = [];
    this.articleViews = {
      30: 0,
      180: 0,
    };
    this.articlePublications = {
      30: 0,
      180: 0,
    };

    const query = {
      from: startDate.toISOString(),
      distinctKey,
    };
    try {
      const {
        data: { data: statrafEvents },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS
            .DATE_RANGE_UNIQUE_ARTICLES_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );
      const {
        data: {
          data: { data: countOfPublicationsWithArticles },
        },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS
            .UNIQUE_ARTICLES_IN_PUBLICATIONS_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );

      const { data, articleViews, articlePublications } = this.mergeData(
        statrafEvents,
        countOfPublicationsWithArticles,
        startDate,
      );

      this.chartData = data;
      this.articleViews = articleViews;
      this.articlePublications = articlePublications;
      this.isLoading = false;
      this.isLoadingCounters = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchExcelUniqueCounts = async (startDate, endDate, distinctKey) => {
    this.isLoadingExcelData = true;
    this.excelData = [];

    const query = {
      from: startDate,
      to: endDate,
      distinctKey,
    };

    try {
      const {
        data: { data: statrafEvents },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS
            .DATE_RANGE_UNIQUE_ARTICLES_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );
      const {
        data: {
          data: { data: countOfPublicationsWithArticles },
        },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS
            .UNIQUE_ARTICLES_IN_PUBLICATIONS_COUNT_PER_DAY
        }?${queryString.stringify(query)}`,
      );

      const { data } = this.mergeData(
        statrafEvents,
        countOfPublicationsWithArticles,
        startDate,
        moment(endDate),
      );

      this.excelData = data;
      this.isLoadingExcelData = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchUserEvents = async (skip, limit, collection, from, to) => {
    if (collection === 'excel') {
      this.isLoadingExcelData = true;
    } else {
      this.isLoading = true;
    }
    this.userEvents[collection] = [];
    this.userEventsCount[collection] = 0;

    const query = {
      from: from || moment().subtract(collection, 'days').toISOString(),
      to: to || moment().toISOString(),
      skip: skip || 0,
      limit: limit || 10,
    };

    try {
      const {
        data: { data: response },
      } = await API.get(
        `${
          API_ROUTES.ARTICLES_AND_CLICKS.DATE_RANGE_USER_EVENTS_COUNT
        }?${queryString.stringify(query)}`,
      );

      this.userEvents[collection] = response[0].paginatedResults.filter(
        ({ _id: id }) => id !== null,
      );
      this.userEventsCount[collection] = response[0].totalCount[0].count;

      this.isLoading = false;
      this.isLoadingExcelData = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchArticleSources = async () => {
    this.isLoadingArticleSources = true;

    try {
      const response = await API.get(API_ROUTES.ARTICLE_SOURCES(''));

      this.articleSources = response.data.article_sources.map(
        ({ id, name }) => ({ label: name, value: id }),
      );
      this.isLoadingArticleSources = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  mergeData = (
    statrafEvents,
    countOfPublicationsWithArticles,
    startDate,
    endDate = moment(),
  ) => {
    const statrafEventsObj = {};
    statrafEvents.forEach(({ date, count }) => {
      statrafEventsObj[moment(date).format('YYYY-MM-DD').toString()] = count;
    });

    const data = [];
    const articleViews = {
      30: 0,
      180: 0,
    };

    const articlePublications = {
      30: 0,
      180: 0,
    };

    let count = endDate.diff(startDate, 'days');

    while (count >= 0) {
      const date = moment(endDate)
        .subtract(count, 'days')
        .format('YYYY-MM-DD')
        .toString();
      const eventsCount = statrafEventsObj[date] || 0;
      const publicationsCount = countOfPublicationsWithArticles[date] || 0;
      const percentage =
        publicationsCount > 0
          ? ((100 * eventsCount) / publicationsCount).toFixed(0)
          : 0;
      data.push({
        date,
        eventsCount,
        publicationsCount,
        percentage,
      });

      if (count <= 180) {
        articleViews['180'] += eventsCount;
        articlePublications['180'] += publicationsCount;
      }

      if (count <= 30) {
        articleViews['30'] += eventsCount;
        articlePublications['30'] += publicationsCount;
      }

      count -= 1;
    }
    return { data, articleViews, articlePublications };
  };
}

export default new ArticleAndClicksStore();
