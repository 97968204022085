import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

const COLORS = {
  android: '#0088FE',
  web: '#00C49F',
  ios: '#FFBB28',
  email: '#8884d8',
};

export default class MediumPieChart extends PureComponent {
  render() {
    const { data, onPieClick } = this.props;
    return (
      <ResponsiveContainer width="40%" height={250}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
            dataKey="total"
            onClick={onPieClick}
          >
            {data.map((entry, index) => (
              <Cell key={`pie-chart-cell-${index}`} fill={COLORS[entry.name]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

MediumPieChart.propTypes = {
  data: PropTypes.array,
  onPieClick: PropTypes.func,
};
MediumPieChart.defaultProps = {
  data: [],
};
