import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  background-color: ${(props) => props.theme.colors.mainPurple};
  color: #fff
  padding: 27px;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1200;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: white;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledMailto = styled.a`
  text-decoration: none;
  color: white;
  display: inline-block;
  float: right;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Footer = () => (
  <footer>
    <Wrapper>
      &copy; Copyright
      <StyledLink href="https://www.briefcase.news">
        {' '}
        Briefcase.news{' '}
      </StyledLink>
      {new Date().getFullYear()}
      <StyledMailto href="mailto:support@briefcase.news">
        {' '}
        Feedback{' '}
      </StyledMailto>
    </Wrapper>
  </footer>
);

export default Footer;
