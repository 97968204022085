import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { inject, observer } from 'mobx-react';
import Pagination from 'material-ui-flat-pagination';
import { Flex, Text, Box } from 'rebass';
import moment from 'moment';
import SentimentsForm from './components/forms/SentimentsForm';
import { APP_ROUTES } from '../_app/routes';
import SentimentsList from './components/SentimentsList';
import styles from '../theme';

const DEFAULT_LIMIT = 20;

const DEFAULT_QUERY_PARAMS = {
  limit: DEFAULT_LIMIT,
  page: 1,
  from: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  order: 'desc',
  content_type: 1,
};

@inject('sentimentsStore')
@observer
class Sentiments extends Component {
  componentDidMount() {
    const {
      location: { search },
      sentimentsStore: { fetchSentiments },
    } = this.props;
    if (isEmpty(search)) {
      this.onSubmit(this.initialValues());
    }
    if (search === `?${queryString.stringify(this.initialValues())}`) {
      fetchSentiments(search);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (isEmpty(search)) {
      return this.onSubmit(this.initialValues());
    }
    if (prevProps.location.search !== search) {
      this.props.sentimentsStore.fetchSentiments(search);
    }
  }

  onSubmit = (values) =>
    this.props.history.push({
      pathname: APP_ROUTES.SENTIMENTS,
      search: queryString.stringify(values),
    });

  initialValues = () =>
    merge(DEFAULT_QUERY_PARAMS, queryString.parse(this.props.location.search));

  render() {
    const { itemsTotal, pagesTotal, sentiments } = this.props.sentimentsStore;
    const {
      page,
      limit = DEFAULT_LIMIT,
      ...restQuery
    } = queryString.parse(this.props.location.search);

    return (
      <>
        <SentimentsForm
          onSubmit={this.onSubmit}
          initialValues={this.initialValues()}
        />
        <SentimentsList sentiments={sentiments} />
        <Flex width={1} pt={25} justifyContent="center">
          <Box>
            <Text color={styles.colors.mainPurple} fontSize={15}>
              Items {limit * (page - 1) + 1}
              {'-'}
              {sentiments.length + limit * (page - 1)} of {itemsTotal}
            </Text>
          </Box>
        </Flex>
        <Flex width={1} pt={10} pb={15} justifyContent="center">
          <Box>
            <Pagination
              limit={limit}
              offset={limit * (page - 1)}
              total={(pagesTotal || page) * limit}
              onClick={(e, offset, pageNum) =>
                this.onSubmit({ ...restQuery, page: pageNum, limit })
              }
            />
          </Box>
        </Flex>
      </>
    );
  }
}

Sentiments.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  sentimentsStore: PropTypes.shape({
    fetchSentiments: PropTypes.func,
    sentiments: PropTypes.array,
    pagesTotal: PropTypes.number,
    itemsTotal: PropTypes.number,
  }),
};

export default Sentiments;
