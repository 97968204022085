import React, { memo } from 'react';
import {
  BarChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import CustomXAxisTick from './CustomXAxisTick';
import CustomTooltip from './CustomTooltip';

const DailyBarChart = ({ data, keys }) => (
  <ResponsiveContainer width="100%" height={250}>
    <BarChart data={data} margin={{ left: -30 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="timeFrom" tick={<CustomXAxisTick />} />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      {isEmpty(keys) ? (
        <Bar dataKey="numberOfAlerts" fill="#8884d8" />
      ) : (
        map(keys, ({ key, name, color }) => (
          <Bar
            key={`bar-key-${key}`}
            dataKey={key}
            name={name || key}
            fill={color}
          />
        ))
      )}
    </BarChart>
  </ResponsiveContainer>
);

DailyBarChart.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.shape),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfAlerts: PropTypes.number,
      sectorId: PropTypes.number,
      timeFrom: PropTypes.string,
    }),
  ),
};

export default memo(DailyBarChart);
