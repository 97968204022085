import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Loader from '../../_common/components/Loader';

const columns = (collection) => [
  {
    id: '_id',
    label: 'User email',
    minWidth: 300,
    align: 'left',
  },
  {
    id: 'count',
    label: `last ${collection} days`,
    minWidth: 200,
    align: 'left',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    height: '100%',
  },
});

function UsersTable({ articlesAndClicksStore, collection }) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { fetchUserEvents, userEvents, userEventsCount, isLoading } =
    articlesAndClicksStore;

  useEffect(() => {
    fetchUserEvents(page * rowsPerPage, rowsPerPage, collection);
  }, [page, rowsPerPage]);

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await fetchUserEvents(newPage * rowsPerPage, rowsPerPage, collection);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchUserEvents(0, event.target.value, collection);
  };

  return (
    <div>
      {isLoading && <Loader size={60} />}
      {!isLoading && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns(collection).map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: '#8e7cc3',
                        color: 'white',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {userEvents[collection] &&
                  userEvents[collection].map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`row-${index}`}
                    >
                      {columns(collection).map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={userEventsCount[collection]}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}
export default inject('articlesAndClicksStore')(observer(UsersTable));

UsersTable.propTypes = {
  articlesAndClicksStore: PropTypes.shape({
    fetchUserEvents: PropTypes.func,
    isLoading: PropTypes.bool,
    userEvents: PropTypes.any,
    userEventsCount: PropTypes.any,
  }),
  collection: PropTypes.string,
};
