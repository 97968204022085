import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Backdrop from '@material-ui/core/Backdrop';
import moment from 'moment';
import Loader from '../../_common/components/Loader';


const ClientCoveragesList = ({
  onSubmit,
  list,
  totalRowsNumber,
  hiddenColumns,
  toggleColumn,
  isLoading,
  limit,
  page,
  columns,
  articleSources,
}) => {
  const [hiddenColumnsChanged, setHiddenColumnsChanged] = useState(new Date());

  const handleChangePage = async (_event, newPage) => {
    await onSubmit({ page: newPage });
  };

  const handleChangeRowsPerPage = async (event) => {
    await onSubmit({ limit: event.target.value, page: 0 });
  };

  const handleColumnClick = (e) => {
    const key = e.target.id.split('-')[1];
    toggleColumn(key);
    setHiddenColumnsChanged(new Date());
  };

  const theColumns = columns();
  const theList = list();
  const theHiddenColumns = hiddenColumns();
  const theArticleSources = articleSources() || {};

  return (
    <Paper style={{ width: '100%', marginTop: '16px' }}>
      {isLoading() &&
        <Backdrop open="true" style={{ zIndex: 1000, opacity: 0.5, backgroundColor: 'white' }}>
          <Loader size={60} />
        </Backdrop>
      }
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalRowsNumber()}
        rowsPerPage={limit()}
        page={page()}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        style={{ display: 'flex' }}
      />
      <TableContainer style={{ height: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {theColumns.map((column) => (
                <TableCell
                  id={`newspapers_column-${column[0]}`}
                  key={column[0]}
                  title={column[1]}
                  onClick={handleColumnClick}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  { theHiddenColumns.has(column[0]) ? '\uA58C' : column[1] }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody colstate={ hiddenColumnsChanged } >
            {
              theList &&
              theList.map((row) => (
                  <TableRow key={`client-coverage-${row.id}`}>
                      {
                        theColumns.map((column) => {
                          let value = '';
                          if (!theHiddenColumns.has(column[0])) {
                            value = row[column[0]] || '';
                            if (value) {
                              if (column[0] === 'article_sources') {
                                value = value.map((x) => theArticleSources[x] || x).join(',\n');
                              } else if (column[0].endsWith('_at')) {
                                value = moment(value).format('D/M/YYYY h:mm:ss A');
                              }
                            }
                          }
                          const title = value.length > 21 ? value : null;
                          return (
                            <TableCell key={`client-coverage-${row.id}-${column[0]}`} title={title} style={{ whiteSpace: 'nowrap', maxWidth: '22em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              { value }
                            </TableCell>
                          );
                        })
                      }
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

ClientCoveragesList.propTypes = {
  onSubmit: PropTypes.func,
  list: PropTypes.func,
  totalRowsNumber: PropTypes.func,
  hiddenColumns: PropTypes.func,
  toggleColumn: PropTypes.func,
  isLoading: PropTypes.func,
  limit: PropTypes.func,
  page: PropTypes.func,
  columns: PropTypes.func,
  articleSources: PropTypes.func,
};

export default ClientCoveragesList;