import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { Field } from 'react-final-form';
import get from 'lodash/get';
import { TextField as InputField } from '@material-ui/core';
import moment from 'moment';

const TextField = ({
  name,
  label,
  placeholder,
  type,
  validate,
  value,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <Box mb={16} width={1}>
        <InputField
          {...input}
          {...props}
          value={
            type === 'date'
              ? moment(input.value).format('YYYY-MM-DD')
              : input.value
          }
          label={label}
          type={type}
          variant="outlined"
          fullWidth
          error={
            !!(
              (meta.touched || meta.submitFailed) &&
              (meta.error || meta.submitError)
            )
          }
          helperText={
            ((meta.touched || meta.submitFailed) && meta.error) ||
            get(meta, 'submitError[0]', '')
          }
        />
      </Box>
    )}
  </Field>
);

TextField.defaultProps = {
  type: 'text',
};

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validate: PropTypes.func,
  value: PropTypes.string,
};

export default TextField;
