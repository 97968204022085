import moment from 'moment';
import ColumnDescritpion, { formatEmpty, DEFAULT_DATE_FORMAT } from '../_common/ColumnDescription';

export const APP_TYPE_OPTIONS = [
  { value: 'all', label: 'FTSE and Corporate' },
  { value: 'ftse', label: 'FTSE only' },
  { value: 'corporate', label: 'Corporate only' },
];

export const COLUMNS = [
  new ColumnDescritpion({ id: 'id',  align: 'center', }),
  new ColumnDescritpion({ id: 'media_name', }),
  new ColumnDescritpion({ id: 'media_code', align: 'center', }),
  new ColumnDescritpion({ id: 'copy_type', align: 'center', }),
  new ColumnDescritpion({ id: 'organization_name', }),
  new ColumnDescritpion({ id: 'customer_id', align: 'center', }),
  new ColumnDescritpion({ id: 'permitted_users', align: 'center', }),
  new ColumnDescritpion({ id: 'no_of_cuttings', label: 'Cuttings amount', align: 'center', }),
  new ColumnDescritpion({ id: 'client_id', align: 'center', }),
  new ColumnDescritpion({
    id: 'created_at',
    label: 'Creation date',
    text: formatEmpty(value => moment(value).format(DEFAULT_DATE_FORMAT)),
  }),
  new ColumnDescritpion({
    id: 'updated_at',
    label: 'Update date',
    text: formatEmpty(value => moment(value).format(DEFAULT_DATE_FORMAT)),
  }),
  new ColumnDescritpion({ id: 'sections', }),
  new ColumnDescritpion({ id: 'content_container_id', label: 'Content container', align: 'center', }),
  new ColumnDescritpion({
    id: 'ftse',
    label: 'App type',
    align: 'center',
    text: (value) => (
      APP_TYPE_OPTIONS.find((x) => x.value === value).label
    ),
  }),
  new ColumnDescritpion({ id: 'action_buttons', label: '', }),
];
