import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const CustomizedAxisTick = ({ x, y, payload, dateFormat }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
      {moment(payload.value).format(dateFormat)}
    </text>
  </g>
);

CustomizedAxisTick.defaultProps = {
  dateFormat: 'DD/MM/YYYY',
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  dateFormat: PropTypes.string,
};

export default CustomizedAxisTick;
