export const FONT_FAMILY = {};

export default {
  colors: {
    mainPurple: '#8e7cc3',
    secondaryPurple: '#D8D2E9',
    error: '#a94442',
    gray: '#a5a5a5',
  },
  fonts: {},
};
