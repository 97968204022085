import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ContentPublicationsList from './components/list';
import ContentPublicationsForm from './components/forms/filters';

@inject('contentPublicationsStore')
@observer
class ContentPublications extends Component {

  async componentDidMount() {
    await this.updateStore();
  }

  async componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (prevProps.location.search !== search) {
      await this.updateStore();
    }
  }

  async onSubmit(newParams) {
    this.props.history.push(await this.props.contentPublicationsStore.mergeParams({ newParams }));
  }

  async updateStore() {
    await this.props.contentPublicationsStore.updateSearch({ search: this.props.location.search });
  }

  async chooseContainer(id) {
    this.onSubmit({ container: id.toString() });
  }

  render() {
    const {
      formParams,
      DEFAULT_QUERY_PARAMS,
      report,
      totalRowsNumber,
      orders,
      statuses,
      fetchLists,
      listsFetched,
      containers,
      isLoading,
      formVisible,
      toggleFilters,
    } = this.props.contentPublicationsStore;
    return (
      <> 
        <ContentPublicationsForm
          initialValues={DEFAULT_QUERY_PARAMS}
          onSubmit={async (...args) => this.onSubmit(...args)}
          orders={orders}
          statuses={statuses}
          fetchLists={fetchLists}
          listsFetched={listsFetched}
          containers={containers}
          chooseContainer={async (...args) => this.chooseContainer(...args)}
          formVisible={formVisible}
          toggleFilters={toggleFilters}
        />
        <ContentPublicationsList
          onSubmit={async (...args) => this.onSubmit(...args)}
          report={report}
          totalRowsNumber={totalRowsNumber}
          isLoading={isLoading}
          limit={parseInt(formParams.limit, 10)}
          page={parseInt(formParams.page, 10)}
        />
      </>
    );
  }
};

ContentPublications.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  contentPublicationsStore: PropTypes.shape({
    formParams: PropTypes.object,
    updateSearch: PropTypes.func,
    mergeParams: PropTypes.func,
    DEFAULT_QUERY_PARAMS: PropTypes.object,
    report: PropTypes.array,
    totalRowsNumber: PropTypes.any,
    orders: PropTypes.array,
    statuses: PropTypes.array,
    fetchLists: PropTypes.func,
    listsFetched: PropTypes.bool,
    containers: PropTypes.array,
    isLoading: PropTypes.bool,
    hiddenColumns: PropTypes.object,
    toggleColumn: PropTypes.func,
    formVisible: PropTypes.bool,
    toggleFilters: PropTypes.func,
  }),
};

export default ContentPublications;