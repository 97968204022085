import React from 'react';
import PropTypes from 'prop-types';
import Mail from '@material-ui/icons/MailOutline';
import DraftsOutlined from '@material-ui/icons/DraftsOutlined';
import { Flex } from 'rebass';
import IconWithText from '../../_common/components/IconWithText';

const StatsCounters = ({
  emailsOpened,
  emailsSent,
  uniqueEmailsOpen,
  isLoading,
}) => (
  <Flex justifyContent="space-between" flexWrap="wrap" mt={60} my={20}>
    <IconWithText
      icon={Mail}
      mainText={emailsSent}
      isLoading={isLoading}
      text="emails sent"
    />
    <IconWithText
      icon={DraftsOutlined}
      mainText={emailsOpened}
      isLoading={isLoading}
      text="emails opened"
    />
    <IconWithText
      icon={DraftsOutlined}
      mainText={`${uniqueEmailsOpen} (${
        emailsSent !== 0
          ? `${((uniqueEmailsOpen / emailsSent) * 100).toFixed(2)}%`
          : 'N/A'
      })`}
      isLoading={isLoading}
      text="unique emails opened"
    />
  </Flex>
);

StatsCounters.propTypes = {
  emailsOpened: PropTypes.number,
  uniqueEmailsOpen: PropTypes.number,
  emailsSent: PropTypes.number,
  isLoading: PropTypes.bool,
};

StatsCounters.defaultProps = {
  emailsOpened: 0,
  emailsSent: 0,
  uniqueEmailsOpen: 0,
};

export default StatsCounters;
