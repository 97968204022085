import React, { useMemo } from 'react';
import { Button } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import xlsx from 'json-as-xlsx';

const Btn = styled(Button)`
  display: flex;
  align-items: center;
  img {
    display: block;
    margin-left: 10px;
    width: 26px;
  }
`;

export const DATE_FORMAT = 'dd-mm-yyyy';
export const DATE_FORMAT_HOURLY = 'dd-mm-yyyy hh:mm';

const getColumns = (dataKey, dateFormat) => {
  const columns = {
    company: [
      {
        label: 'Company',
        value: 'name',
      },
      {
        label: 'Date',
        value: 'date',
        format: dateFormat,
      },
      {
        label: 'Number of alerts',
        value: 'numberOfAlerts',
      },
    ],
    sector: [
      {
        label: 'Sector',
        value: 'name',
      },
      {
        label: 'Date',
        value: 'date',
        format: dateFormat,
      },
      {
        label: 'Number of alerts',
        value: 'numberOfAlerts',
      },
    ],
  };
  return columns[dataKey];
};

const ExcelDownload = ({
  preparedColumns,
  preparedContent,
  data,
  name,
  title,
  type,
  dataKey,
}) => {
  const settings = useMemo(
    () => ({
      fileName: name,
      extraLength: 3,
      writeOptions: {},
    }),
    [name],
  );
  const dateFormat = type === 'hourly' ? DATE_FORMAT_HOURLY : DATE_FORMAT;
  const columns = getColumns(dataKey, dateFormat);

  const content = useMemo(
    () =>
      data?.map((obj) =>
        obj?.timeFrom
          ? {
              ...obj,
              name: obj.name || title,
              date: new Date(obj.timeFrom),
            }
          : obj,
      ),
    [data, type],
  );

  const handleExcelDownload = () => {
    if (!isEmpty(preparedColumns) && !isEmpty(preparedContent)) {
      xlsx(
        [
          {
            sheet: title.substring(0, 31),
            columns: preparedColumns,
            content: preparedContent,
          },
        ],
        settings,
      );
    } else {
      const excelData = [
        {
          sheet: title.substring(0, 31),
          columns,
          content,
        },
      ];
      xlsx(excelData, settings);
    }
  };

  return (
    <Btn
      onClick={handleExcelDownload}
      variant="outlined"
      size="large"
      color="primary"
      type="button"
    >
      Export to Excel
      <img src="/images/excel.svg" alt="export to excel icon" />
    </Btn>
  );
};

ExcelDownload.propTypes = {
  preparedColumns: PropTypes.array,
  preparedContent: PropTypes.array,
  data: PropTypes.array,
  name: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['daily', 'hourly']),
  dataKey: PropTypes.string,
};

export default ExcelDownload;
