import { action, observable } from 'mobx';
import { FORM_ERROR } from 'final-form';
import Cookie from 'js-cookie';
import API, { setApiHeaders } from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';
import { routerStore, userStore } from './index';

export class AuthStore {
  @observable isLoading = false;

  suffix = process.env.REACT_APP_COOKIE_SUFFIX;

  constructor() {
    const email = Cookie.get(`analyst_email${this.suffix}`);
    const token = Cookie.get(`analyst_token${this.suffix}`);

    if (email && token) {
      setApiHeaders(token, email);
    }
  }

  @action isAuthenticated = () => {
    const email = Cookie.get(`analyst_email${this.suffix}`);
    const token = Cookie.get(`analyst_token${this.suffix}`);
    if (token && email) {
      setApiHeaders(token, email);
    }

    return email && token;
  };

  @action
  signIn = async ({ email, password }) => {
    this.isLoading = true;
    try {
      const {
        data: { token },
      } = await API.post(API_ROUTES.SIGN_IN, { email, password });
      setApiHeaders(token, email);
      await userStore.fetchProfile();

      // this.isAuthenticated = true;
      routerStore.push(APP_ROUTES.DASHBOARD);
    } catch (e) {
      return { [FORM_ERROR]: 'Invalid email or password!' };
    } finally {
      this.isLoading = false;
    }
  };

  @action logout = async () => {
    userStore.reset();
    Cookie.remove(`analyst_email${this.suffix}`, {
      domain: '.briefcase.news',
    });

    Cookie.remove(`analyst_token${this.suffix}`, {
      domain: '.briefcase.news',
    });

    Cookie.remove(`analyst_id${this.suffix}`, {
      domain: '.briefcase.news',
    });

    if (process.env.REACT_APP_ENABLE_SSO === 'true') {
      window.location.href = `${process.env.REACT_APP_SSO_URL}/?signout=true&origin=${window.location.origin}/sign-in`;
    } else {
      routerStore.push(APP_ROUTES.SIGN_IN);
    }
  };
}

export default new AuthStore();
