import React, { useEffect, useMemo } from 'react';
import { Paper, Typography, Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import { Flex } from 'rebass';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import FormSelect from '../../_common/components/forms/FormSelect';
import AutoSave from '../../_common/components/forms/AutoSave';
import CustomLineChart from '../components/CustomLineChart';

import { HeaderWrapper, Filters } from './style';
import DailyBarChart from '../components/DailyBarChart';
import ExcelDownloadButton from '../components/ExportToExcel';

const keys = [
  {
    key: 'numberOfAlerts',
    label: 'Number of alerts',
    color: '#6441c9',
    show: true,
  },
];

const CompanyTab = ({
  alertsStatisticsStore: {
    barChartData,
    lineChartData,
    prepareSectorStatistics,
    filtersChange,
    selectedSectorId,
    selectedSectorName,
    selectedTimeframe,
    timeframeOptions,
    sectors,
  },
}) => {
  const initialValues = useMemo(
    () => ({
      sector: selectedSectorId,
      timeframe: selectedTimeframe,
    }),
    [selectedSectorId, selectedTimeframe],
  );

  const { search } = useLocation();

  useEffect(() => {
    prepareSectorStatistics(search);
  }, [search]);

  const onSubmit = (values) => {
    filtersChange(values);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, values, form }) => (
        <form id="sector-filters-form" onSubmit={handleSubmit}>
          <AutoSave debounce={100} save={handleSubmit} values={values} />

          <HeaderWrapper mt={4}>
            <Typography variant="h4" gutterBottom>
              {selectedSectorName}
            </Typography>
            <Filters>
              {sectors.length > 0 ? (
                <FormSelect
                  options={sectors}
                  name="sector"
                  label="Sector"
                  valueKey="id"
                  labelKey="name"
                />
              ) : null}
              {timeframeOptions.length > 0 ? (
                <FormSelect
                  options={timeframeOptions}
                  name="timeframe"
                  label="Timeframe"
                />
              ) : null}
            </Filters>
          </HeaderWrapper>

          <Box mb={1}>
            <Paper>
              <Box p={4}>
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                  <Typography variant="h6">Daily</Typography>
                  <ExcelDownloadButton
                    data={barChartData}
                    name={`${selectedSectorName}_daily-alerts-statistics`}
                    title={selectedSectorName}
                    type="daily"
                    dataKey="sector"
                  />
                </Flex>
                <DailyBarChart data={barChartData} />
              </Box>
            </Paper>
          </Box>
          <Box>
            <Paper>
              <Box p={4}>
                <Box mb={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb={4}
                  >
                    <Typography variant="h6">Hourly</Typography>
                    <ExcelDownloadButton
                      data={lineChartData}
                      name={`${selectedSectorName}_hourly-alerts-statistics`}
                      title={selectedSectorName}
                      type="hourly"
                      dataKey="sector"
                    />
                  </Flex>
                </Box>
                <CustomLineChart
                  data={lineChartData}
                  keys={keys}
                  xAxisKey="timeFrom"
                />
              </Box>
            </Paper>
          </Box>
        </form>
      )}
    />
  );
};

CompanyTab.propTypes = {
  alertsStatisticsStore: PropTypes.shape({
    barChartData: PropTypes.arrayOf(
      PropTypes.shape({
        numberOfAlerts: PropTypes.number,
        sectorId: PropTypes.number,
        timeFrom: PropTypes.string,
      }),
    ).isRequired,
    lineChartData: PropTypes.arrayOf(
      PropTypes.shape({
        numberOfAlerts: PropTypes.number,
        sectorId: PropTypes.number,
        timeFrom: PropTypes.string,
        timeTo: PropTypes.string,
      }),
    ).isRequired,
    sectors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ).isRequired,
    prepareSectorStatistics: PropTypes.func.isRequired,
    resetSectorStatistics: PropTypes.func.isRequired,
    filtersChange: PropTypes.func.isRequired,
    selectedSectorId: PropTypes.number,
    selectedSectorName: PropTypes.string,
    selectedTimeframe: PropTypes.string,
    timeframeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
};

export default inject('alertsStatisticsStore')(observer(CompanyTab));
