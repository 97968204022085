import { Box, Flex, Text } from 'rebass';
import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import File from '@material-ui/icons/FileCopy';
import Loader from '../../_common/components/Loader';

const style = {
  width: '60px',
  height: '60px',
};
const Counter = ({ icon: Icon, isLoading, mainText, text }) => (
  <Flex alignItems="flex-end">
    <Icon style={style} />
    <Box mb={10} ml={10}>
      <Text fontSize={25} fontWeight="bold" lineHeight={1}>
        {isLoading ? (
          <Loader
            size={20}
            py={0}
            mb="5px"
            ml={10}
            justifyContent="flex-start"
          />
        ) : (
          mainText
        )}
      </Text>
      <Text lineHeight={1}>{text}</Text>
    </Box>
  </Flex>
);

const Counters = ({ isLoading, articleViews, articlePublications }) => (
  <Flex justifyContent="space-between">
    <Paper style={{ width: '49%' }}>
      <Box
        mb={10}
        ml={10}
        style={{ marginRight: 10, textAlign: 'center', marginTop: 20 }}
      >
        <Text fontSize={28} fontWeight="bold">
          Article views
        </Text>
      </Box>
      <Flex
        justifyContent="space-between"
        style={{ margin: 15, padding: '0 20px 10px 20px' }}
        alignItems="flex-end"
      >
        <Counter
          icon={VisibilityIcon}
          isLoading={isLoading}
          mainText={articleViews['30']}
          text="for last 30 days"
        />
        <Counter
          icon={VisibilityIcon}
          isLoading={isLoading}
          mainText={articleViews['180']}
          text="for last 180 days"
        />
      </Flex>
    </Paper>
    <Paper style={{ width: '49%' }}>
      <Box
        mb={10}
        ml={10}
        style={{ marginRight: 10, textAlign: 'center', marginTop: 20 }}
      >
        <Text fontSize={28} fontWeight="bold">
          Article publications
        </Text>
      </Box>
      <Flex
        justifyContent="space-between"
        style={{ margin: 15, padding: '0 20px 10px 20px' }}
        alignItems="flex-end"
      >
        <Counter
          icon={File}
          isLoading={isLoading}
          mainText={articlePublications['30']}
          text="for last 30 days"
        />
        <Counter
          icon={File}
          isLoading={isLoading}
          mainText={articlePublications['180']}
          text="for last 180 days"
        />
      </Flex>
    </Paper>
  </Flex>
);

export default Counters;

Counters.propTypes = {
  isLoading: PropTypes.bool,
  articleViews: PropTypes.any,
  articlePublications: PropTypes.any,
};

Counter.propTypes = {
  isLoading: PropTypes.bool,
  mainText: PropTypes.number,
  text: PropTypes.string,
  icon: PropTypes.any,
};
