import React from 'react';
import ReactPropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Box, Flex } from 'rebass';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { APP_ROUTES } from '../../_app/routes';
import TextField from '../../_common/components/forms/TextField';
import FormSelect from '../../_common/components/forms/FormSelect';
import AutoSave from '../../_common/components/forms/AutoSave';
import { APP_TYPE_OPTIONS } from '../constants';

export const MEDIA_NAME_KEY = 'mediaName';
export const MEDIA_CODE_KEY = 'mediaCode';
export const ORGANIZATION_NAME_KEY = 'organizationName';
export const CUSTOMER_KEY = 'customerId';
export const APP_TYPE_KEY = 'appType';
export const CREATION_FROM_KEY = 'creationFrom';
export const CREATION_TO_KEY = 'creationTo';

function Filters({ getInitialValues, isOpen, setOpen, onSubmit }) {
  const history = useHistory();

  const handleClear = (form) => {
    form.reset();
    history.push({
      pathname: APP_ROUTES.NLA,
    });
  };

  return (
    <Form
      onSubmit={(values) => onSubmit({ ...values })}
      initialValues={{
        ...getInitialValues(),
      }}
      render={({ handleSubmit, form, values }) => (
        <Paper style={{ padding: '15px 15px 1px 15px', maxWidth: '80vw' }}>
          <form id="NlaForm" onSubmit={handleSubmit}>
            <AutoSave debounce={1000} save={handleSubmit} values={values} />
            <Flex mb={20} mx={-15} flexWrap="wrap">
              <Box px={15} width={[1, 1 / 2, 1 / 5]}>
                <Button
                  onClick={() => setOpen(!isOpen)}
                  fullWidth
                  variant={isOpen ? 'outlined' : 'contained'}
                  color="primary"
                >
                  {isOpen ? 'Hide filters' : 'Show filters'}
                </Button>
              </Box>
              {isOpen && (
                <Box px={15} width={[1, 1 / 2, 1 / 5]}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => handleClear(form)}
                  >
                    Clear filters
                  </Button>
                </Box>
              )}
            </Flex>
            {isOpen && (
              <>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1 / 2, 1 / 4]} px={15}>
                    <TextField
                      type="text"
                      name={MEDIA_NAME_KEY}
                      label="Media name"
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 4]} px={15}>
                    <TextField
                      type="text"
                      name={MEDIA_CODE_KEY}
                      label="Media code"
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 4]} px={15}>
                    <TextField
                      type="text"
                      name={CUSTOMER_KEY}
                      label="Customer"
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 4]} px={15}>
                    <TextField
                      type="text"
                      name={ORGANIZATION_NAME_KEY}
                      label="Organization name"
                    />
                  </Box>
                </Flex>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1 / 2, 1 / 3]} px={15}>
                    <TextField
                      label="Creation date from"
                      type="date"
                      name={CREATION_FROM_KEY}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 3]} px={15}>
                    <TextField
                      label="Creation date to"
                      type="date"
                      name={CREATION_TO_KEY}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 3]} px={15}>
                    <FormSelect
                      options={APP_TYPE_OPTIONS}
                      name={APP_TYPE_KEY}
                      label="App type"
                    />
                  </Box>
                </Flex>
              </>
            )}
          </form>
        </Paper>
      )}
    />
  );
}

Filters.propTypes = {
  contentReportsStore: ReactPropTypes.shape({
    fetchAnalystsOptions: MobXPropTypes.func,
    analystsOptions: MobXPropTypes.array,
    isLoadingAnalysts: MobXPropTypes.bool,
  }),
  getInitialValues: ReactPropTypes.func,
  setOpen: ReactPropTypes.func,
  isOpen: ReactPropTypes.bool,
  onSubmit: ReactPropTypes.func,
};

export default inject('contentReportsStore')(observer(Filters));
