import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Loader from '../../_common/components/Loader';

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    width: '100%',
  },
  container: {
    height: '100%',
  },
});

function SubscriptionDetailsList({ reportsStore, columns }) {
  const classes = useStyles();
  const { subscriptionDetails, isLoadingSubscriptionDetails } = reportsStore;

  return (
    <Paper className={classes.root}>
      {isLoadingSubscriptionDetails && <Loader />}
      {!isLoadingSubscriptionDetails && (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionDetails.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`row${row.id}`}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
export default inject('reportsStore')(observer(SubscriptionDetailsList));

SubscriptionDetailsList.propTypes = {
  reportsStore: PropTypes.shape({
    subscriptionDetails: PropTypes.array,
    isLoadingSubscriptionDetails: PropTypes.bool,
  }),
  columns: PropTypes.array,
};
