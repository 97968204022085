import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Text } from 'rebass';

const styles = (theme) => ({
  root: {
    width: '1000px',
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function HtmlDialog({ content }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removeCollect = (emailContent) =>
    emailContent
      .replace('https://statraf-preprod-k8s.briefcase.news', '')
      .replace('https://www.google-analytics.com/', '')
      .replace('https://statraf.briefcase.news', '');

  return (
    <div>
      <Text
        sx={{
          ':hover': {
            color: '#8e7cc3',
          },
        }}
        fontSize={16}
        onClick={handleClickOpen}
      >
        Show article content
      </Text>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Article content
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: removeCollect(content) }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
HtmlDialog.propTypes = {
  content: PropTypes.string,
};
