import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';
import DrawerLayout from './layouts/DrawerLayout';
import { APP_ROUTES } from '../_app/routes';
import Footer from './components/Footer';

@inject('authStore')
@observer
class AppRoute extends React.Component {
  render() {
    const {
      component: Component,
      isPrivate,
      layout: Layout,
      ...routeProps
    } = this.props;
    const { isAuthenticated } = this.props.authStore;
    const authenticated = isAuthenticated();
    return (
      <Route
        {...routeProps}
        render={(props) => {
          if (!authenticated && isPrivate) {
            return <Redirect to={APP_ROUTES.SIGN_IN} />;
          }

          return (
            <Layout>
              <Component
                {...routeProps}
                {...props}
                isAuthenticated={authenticated}
              />
              <Footer />
            </Layout>
          );
        }}
      />
    );
  }
}

AppRoute.wrappedComponent.propTypes = {
  component: PropTypes.any,
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.func,
  }).isRequired,
  isPrivate: PropTypes.bool,
  layout: PropTypes.any,
};

AppRoute.wrappedComponent.defaultProps = {
  isPrivate: false,
  layout: DrawerLayout,
};

export default AppRoute;
