import { action, extendObservable } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const initialState = {
  isLoading: false,
  profile: {},
  error: null,
};

export class UserStore {
  constructor() {
    extendObservable(this, initialState);
  }
  @action reset = () => {
    Object.keys(initialState).forEach((key) => {
      this[key] = initialState[key];
    });
  };

  @action fetchProfile = async () => {
    if (!isEmpty(this.profile) || this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const {
        data: { data: profile },
      } = await API.get(API_ROUTES.PROFILE);
      this.profile = {
        ...profile,
        displayName: this._getDisplayName(profile),
      };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  _getDisplayName = (profile) =>
    profile.first_name
      ? `${profile.first_name || ''} ${profile.last_name || ''}`
      : profile.email;
}

export default new UserStore();
