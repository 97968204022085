import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import Paper from '@material-ui/core/Paper';
import HtmlDialog from './HtmlDialog';

const EVENT_TYPES = [
  { label: 'Action:', key: 'action' },
  { label: 'Category:', key: 'category' },
  { label: 'Medium:', key: 'medium' },
  { label: 'Type:', key: 'type' },
  { label: 'User email:', key: 'user_email' },
  { label: 'Object name:', key: 'object_name' },
  { label: 'Path:', key: 'path' },
  { label: 'Title:', key: 'title' },
  { label: 'Timestamp:', key: 'timestamp' },
  { label: 'Version:', key: 'version' },
  { label: 'Content:', key: 'content' },
];

const style = { whiteSpace: 'nowrap' };

const EmailsList = ({ emails }) => (
  <>
    {emails.map((event, idx) => (
      <Box py={10} key={`event-type-${idx}`} width={1}>
        <Paper>
          <Box p={20} width={1}>
            {EVENT_TYPES.filter(({ key }) => event[key]).map(
              ({ label, key }, i) => (
                <Flex width={1} key={`email-type-key-${i}`}>
                  <Box pr={10} width={150}>
                    <Text
                      style={style}
                      color="gray"
                      textAlign="right"
                      fontSize={16}
                    >
                      {label}
                    </Text>
                  </Box>
                  <Box width={1}>
                    <Text fontSize={16}>
                      {key === 'content' && event[key] ? (
                        <HtmlDialog content={event[key]} />
                      ) : (
                        event[key] || '-'
                      )}
                    </Text>
                  </Box>
                </Flex>
              ),
            )}
          </Box>
        </Paper>
      </Box>
    ))}
  </>
);

EmailsList.propTypes = {
  emails: PropTypes.array,
};

export default EmailsList;
