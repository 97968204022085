import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const useStyles = makeStyles(() => ({
  actionButton: {
    margin: '0 10px',
    width: 'max-content',
  },
}));

const ExcelDownload = ({
  fileName,
  columns,
  data,
  sheetName,
  isLoading,
  disableButton,
  buttonHeight = 56,
  margin,
  buttonWidth,
}) => {
  const classes = useStyles();

  const columnsWithWidth = columns.map(({ title, width }) => ({
    title,
    width: { wpx: width },
  }));

  const cellData = data.map((item) => {
    const row = [];
    columns.forEach((column) => {
      row.push({
        value: column.format ? column.format(item[column.id]) : item[column.id],
      });
    });
    return row;
  });

  const cellDataSet = [
    {
      columns: columnsWithWidth,
      data: cellData,
    },
  ];

  if (isLoading || disableButton) {
    return (
      <Button
        style={{ height: buttonHeight, margin, width: buttonWidth }}
        className={classes.actionButton}
        variant="outlined"
        size="large"
        color="primary"
        type="button"
        disabled={isLoading || disableButton}
      >
        Export to Excel
      </Button>
    );
  }

  return (
    <ExcelFile
      filename={fileName}
      element={
        <Button
          style={{ height: buttonHeight, margin }}
          className={classes.actionButton}
          variant="outlined"
          size="large"
          color="primary"
          type="button"
          disabled={isLoading || disableButton}
        >
          Export to Excel
        </Button>
      }
    >
      <ExcelSheet
        dataSet={cellDataSet}
        name={sheetName.replace('&', '&amp;')}
      />
    </ExcelFile>
  );
};

export default ExcelDownload;

ExcelDownload.propTypes = {
  fileName: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  sheetName: PropTypes.string,
  isLoading: PropTypes.bool,
  disableButton: PropTypes.bool,
  buttonHeight: PropTypes.number,
  margin: PropTypes.string,
  buttonWidth: PropTypes.string,
};
