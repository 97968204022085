import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.mainPurple};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-size: ${(props) => props.fontSize}px;
`;

StyledLink.defaultProps = {
  fontSize: 16,
};

export default StyledLink;
