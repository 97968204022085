import { action, observable } from 'mobx';
import * as queryString from 'query-string';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class ReportsStore {
  @observable subscriptions = [];

  @observable isLoadingSubscriptionDetails = false;
  @observable subscriptionDetails = [];

  @action fetchSubscriptionDetails = async (searchQuery) => {
    const { subscription, ...search } = queryString.parse(searchQuery);
    this.subscriptionDetails = [];

    if (this.subscriptions.length === 0) {
      await this.fetchSubscriptions(subscription);
    }

    const subscriptionsFiltered = this.subscriptions.filter(
      (s) => s.name === subscription,
    );

    if (subscriptionsFiltered.length === 1) {
      this.isLoadingSubscriptionDetails = true;
      try {
        const { data } = await API.get(
          `${API_ROUTES.GET_SUBSCRIPTION(
            subscriptionsFiltered[0].id,
          )}?${queryString.stringify(search)}`,
        );
        this.subscriptionDetails = data.users;
      } catch (e) {
        this.error = e;
      } finally {
        this.isLoadingSubscriptionDetails = false;
      }
    }
  };

  @action fetchSubscriptions = async (subscription) => {
    this.subscriptions = [];
    if (subscription !== '') {
      const query = {
        subscription,
      };
      try {
        const {
          data: { contracts },
        } = await API.get(
          `${API_ROUTES.GET_SUBSCRIPTIONS_ALL}?${queryString.stringify(query)}`,
        );
        this.subscriptions = [{ id: 'all_contracts', name: 'All subscriptions' }].concat(contracts);
      } catch (e) {
        this.error = e;
      }
    }
    return this.subscriptions.map((s) => s.name);
  };
}

export default new ReportsStore();
