import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Flex } from 'rebass';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../_common/components/Loader';
import ReactSelect from '../_common/components/ReactSelect';
import SubtractHeatMap from './components/SubstractHeatMap';
import HeatMap from './components/HeatMap';

const ArticleSourcesMatrix = ({ articleSourcesMatrixStore }) => {
  const [articleSourceId, setArticleSourceId] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const {
    fetchAvgArticleSourcesMatrix,
    fetchArticleSourcesMatrix,
    fetchArticleSources,
    isLoadingSourcesMatrix,
    avgArticleSourcesMatrixData,
    articleSourcesMatrixData,
    articleSources,
    isLoadingArticleSources,
  } = articleSourcesMatrixStore;

  useEffect(() => {
    if (articleSources.length === 0) {
      fetchArticleSources();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const paramsArticleSourceId = urlParams.get('articleSourceId');
    if (paramsArticleSourceId) {
      setArticleSourceId(parseInt(paramsArticleSourceId, 10));
    }
  }, []);

  useEffect(() => {
    if (articleSourceId) {
      fetchArticleSourcesMatrix(articleSourceId);
      fetchAvgArticleSourcesMatrix(articleSourceId);
    }
  }, [articleSourceId]);

  const handleArticleSourceChange = (newValue) => {
    const params = new URLSearchParams({ articleSourceId: newValue });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setArticleSourceId(newValue);
  };

  return (
    <div>
      {!isLoadingArticleSources && (
        <ReactSelect
          value={articleSourceId}
          onChange={handleArticleSourceChange}
          options={articleSources}
        />
      )}
      {isLoadingSourcesMatrix && <Loader size={60} />}
      {!isLoadingSourcesMatrix && !!articleSourceId && (
        <Flex justifyContent="flex-start" alignItems="flx-start">
          <HeatMap
            data={articleSourcesMatrixData}
            label="Current Week"
            isLegend
          />
          <HeatMap
            data={avgArticleSourcesMatrixData}
            label="Average from previous 4 weeks"
          />
          {!!articleSourcesMatrixData && !!articleSourcesMatrixData && (
            <SubtractHeatMap
              data={articleSourcesMatrixData}
              avgData={avgArticleSourcesMatrixData}
              label="Alarming Discrepancies"
            />
          )}
        </Flex>
      )}
    </div>
  );
};
export default inject('articleSourcesMatrixStore')(
  observer(ArticleSourcesMatrix),
);

ArticleSourcesMatrix.propTypes = {
  articleSourcesMatrixStore: PropTypes.shape({
    fetchAvgArticleSourcesMatrix: PropTypes.func,
    fetchArticleSourcesMatrix: PropTypes.func,
    fetchArticleSources: PropTypes.func,
    isLoadingSourcesMatrix: PropTypes.bool,
    isLoadingArticleSources: PropTypes.bool,
    avgArticleSourcesMatrixData: PropTypes.array,
    articleSourcesMatrixData: PropTypes.array,
    articleSources: PropTypes.array,
  }),
};
