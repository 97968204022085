import ReactPropTypes from 'prop-types';
import * as queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Table from './table';
import Filters, {
  MEDIA_CODE_KEY,
  MEDIA_NAME_KEY,
  ORGANIZATION_NAME_KEY,
  CUSTOMER_KEY,
  APP_TYPE_KEY,
  CREATION_FROM_KEY,
  CREATION_TO_KEY,
} from './filters';
import { APP_ROUTES } from '../../_app/routes';

function Listing({ nlaStore: { fetchTable } }) {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();

  const getValues = () => {
    const { search } = history.location;
    const params = new URLSearchParams(search);
    return {
      mediaName: params.get(MEDIA_NAME_KEY) || undefined,
      mediaCode: params.get(MEDIA_CODE_KEY) || undefined,
      organizationName: params.get(ORGANIZATION_NAME_KEY) || undefined,
      customerId: params.get(CUSTOMER_KEY) || undefined,
      appType: params.get(APP_TYPE_KEY) || undefined,
      creationFrom: params.get(CREATION_FROM_KEY) || undefined,
      creationTo: params.get(CREATION_TO_KEY) || undefined,
    };
  };

  const handleFilterSubmit = (values) => {
    setPage(0);
    history.push({
      pathname: APP_ROUTES.NLA,
      search: queryString.stringify(values),
    });
  };

  const fetchData = useCallback(() => {
    fetchTable({
      pageSize,
      page,
      ...getValues(),
    });
  }, [pageSize, page, history.location]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Filters
        getInitialValues={getValues}
        onSubmit={handleFilterSubmit}
        isOpen={isFilterOpen}
        setOpen={setFilterOpen}
      />
      <Table
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        fetchData={fetchData}
      />
    </>
  );
}

Listing.propTypes = {
  nlaStore: ReactPropTypes.shape({
    fetchTable: MobXPropTypes.func,
    isLoadingTable: MobXPropTypes.bool,
  }),
};

export default inject('nlaStore')(observer(Listing));
