import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  ChartContainer,
  CountersContainer,
} from '../../_common/components/Containers';
import CustomLineChart from '../components/CustomLineChart';
import Counters from '../components/Counters';
import CustomRangeExport from '../components/CustomRangeExport';
import ReactSelect from '../../_common/components/ReactSelect';
import Loader from '../../_common/components/Loader';

const excelColumns = [
  {
    id: 'date',
    title: 'Date',
    width: 100,
  },
  {
    id: 'eventsCount',
    title: 'Events count',
    width: 100,
  },
  {
    id: 'publicationsCount',
    title: 'Publications Count',
    width: 120,
  },
];

const TotalsPerSourceTab = ({ articlesAndClicksStore }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD').toString(),
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString(),
  );
  const [articleSource, setArticleSource] = useState(null);

  const isDateDiffLessThen180 =
    moment(endDate).diff(moment(startDate), 'days') <= 180;
  const isDateDiffPositive =
    moment(endDate).diff(moment(startDate), 'days') > 0;

  const isMounted = useRef(false);

  const {
    fetchTotals,
    chartData,
    isLoading,
    articleViews,
    articlePublications,
    articleSources,
    fetchArticleSources,
    isLoadingArticleSources,
    fetchExcelTotals,
    isLoadingExcelData,
    excelData,
  } = articlesAndClicksStore;

  useEffect(() => {
    if (isMounted.current) {
      fetchTotals(moment().subtract(180, 'days'), articleSource);
      fetchExcelTotals(startDate, endDate, articleSource);
    }
  }, [articleSource]);

  useEffect(() => {
    if (isMounted.current && isDateDiffLessThen180 && isDateDiffPositive) {
      fetchExcelTotals(startDate, endDate, articleSource);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    isMounted.current = true;
    fetchTotals(moment().subtract(180, 'days'));
    fetchArticleSources();
    fetchExcelTotals(startDate, endDate, articleSource);
  }, []);

  const keys = [
    {
      key: 'eventsCount',
      label: 'Number of article views',
      color: '#000000',
      show: true,
    },
    {
      key: 'publicationsCount',
      label: 'Number of publications with articles',
      color: '#A14515',
      show: true,
    },
    { key: 'percentage', color: '#123456', show: false },
  ];

  return (
    <div>
      {(isLoading || isLoadingArticleSources) && <Loader size={60} />}
      {!isLoadingArticleSources && (
        <ReactSelect
          value={articleSource}
          onChange={(newValue) => setArticleSource(newValue)}
          options={articleSources}
        />
      )}
      {!isLoading && (
        <div>
          <ChartContainer>
            <CustomLineChart data={chartData} keys={keys} xAxisKey="date" />
          </ChartContainer>
          <CountersContainer>
            <Counters
              isLoading={false}
              articleViews={articleViews}
              articlePublications={articlePublications}
            />
          </CountersContainer>
          <CustomRangeExport
            columns={excelColumns}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            disableButton={!isDateDiffLessThen180 || !isDateDiffPositive}
            isLoading={isLoadingExcelData}
            data={excelData}
            fileName={`Totals for source - ${articleSource || 'No source'}`}
            sheetName={
              (articleSource && articleSource.toString()) || 'No source'
            }
          />
        </div>
      )}
    </div>
  );
};

TotalsPerSourceTab.propTypes = {
  articlesAndClicksStore: PropTypes.shape({
    fetchTotals: PropTypes.func,
    fetchArticleSources: PropTypes.func,
    articleSources: PropTypes.array,
    chartData: PropTypes.array,
    isLoading: PropTypes.bool,
    isLoadingArticleSources: PropTypes.bool,
    articleViews: PropTypes.any,
    articlePublications: PropTypes.any,
    excelData: PropTypes.array,
    fetchExcelTotals: PropTypes.func,
    isLoadingExcelData: PropTypes.bool,
  }),
};

export default inject('articlesAndClicksStore')(observer(TotalsPerSourceTab));
