import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const ReportPreview = ({
  entryId,
  cells,
}) => (
  <Paper style={{ width: '100%', marginTop: '16px' }}>
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {
              cells.map((cell) => (
                <TableRow key={`report-preview-${entryId}-${cell.id}`}>
                  <TableCell key={`report-preview-${entryId}-${cell.id}-title`}>
                    { cell.label }
                  </TableCell>
                  <TableCell key={`report-preview-${entryId}-${cell.id}-value`}>
                    { cell.text }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

ReportPreview.propTypes = {
  entryId: PropTypes.number,
  cells: PropTypes.array,
};

export default ReportPreview;