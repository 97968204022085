import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormSelect from '../../../_common/components/forms/FormSelect';
import TextField from '../../../_common/components/forms/TextField';
import TextFieldSearch from '../../../_common/components/forms/TextFieldSearch';
import { ORDER_LABELS } from '../../../_app/constants';
import AutoSave from '../../../_common/components/forms/AutoSave';
import { fetchEventPromptsEmail } from '../../../_app/utils';
import { validateDate } from '../../../utils/formValidators';

const useStyles = makeStyles(() => ({
  actionButton: {
    margin: '0 10px',
  },
}));

const EmailsForm = ({ onSubmit, initialValues }) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={(values) => onSubmit({ ...values, page: 1 })}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, values, mutators, form }) => (
        <form id="EventsForm" onSubmit={handleSubmit}>
          <AutoSave debounce={1000} save={handleSubmit} values={values} />
          <Flex mx={-15} flexWrap="wrap">
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextField
                label="From"
                type="date"
                name="from"
                validate={(value, allValues) =>
                  validateDate(value, allValues, { from: 'from', to: 'to' })
                }
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextField
                label="To"
                type="date"
                name="to"
                validate={(value, allValues) =>
                  validateDate(value, allValues, { from: 'from', to: 'to' })
                }
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <FormSelect options={ORDER_LABELS} name="order" label="Order" />
            </Box>
            <Box width={[1, 1 / 2, 1 / 4]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                name="user_email"
                label="User email"
                myOnChange={fetchEventPromptsEmail}
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 4]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                name="title"
                label="Title"
                myOnChange={() => {}}
                ignoreSuggestions
              />
            </Box>
          </Flex>
          <Flex width={1} justifyContent="flex-end">
            <Button
              className={classes.actionButton}
              size="large"
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => form.reset()}
            >
              Clear
            </Button>
          </Flex>
        </form>
      )}
    />
  );
};

EmailsForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

export default EmailsForm;
