/* eslint-disable no-unused-vars */
import { stringify } from 'query-string';
import { action, observable } from 'mobx';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class ContentReportsStore {
  @observable isLoadingContentReport = false;

  @observable isLoadingTable = false;

  @observable isLoadingAnalysts = false;

  @observable analystsOptions = [];

  @observable tableData = [];

  @observable totalRowsNumber = 0;

  @observable contentReport;

  @action fetchAnalystsOptions = async () => {
    this.isLoadingAnalysts = true;
    try {
      const response = await API.get(API_ROUTES.ANALYSTS);

      this.analystsOptions = response.data.analysts.map(
        ({ id, email, first_name: firstName, last_name: lastName }) => ({
          label: firstName && lastName ? `${firstName} ${lastName}` : email,
          value: id,
        }),
      );
      this.isLoadingAnalysts = false;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingAnalysts = false;
    }
  };

  @action fetchTable = async ({
    analystId,
    creationFrom,
    creationTo,
    publicationFrom,
    publicationTo,
    page,
    pageSize,
  }) => {
    this.isLoadingTable = true;
    try {
      const query = {
        analyst_id: analystId,
        created_at_from: creationFrom,
        created_at_to: creationTo,
        published_at_from: publicationFrom,
        published_at_to: publicationTo,
        limit: parseInt(pageSize.toString(), 10),
        skip: parseInt((page * pageSize).toString(), 10),
      };

      const response = await API.get(
        `${API_ROUTES.CONTENT_REPORTS.ALL}?${stringify(query)}`,
      );
      this.tableData = response.data.contents;
      this.totalRowsNumber = response.data.contents_count;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingTable = false;
    }
  };

  @action fetchContentReport = async ({ id }) => {
    this.isLoadingContentReport = true;
    try {
      const response = await API.get(
        `${API_ROUTES.CONTENT_REPORTS.BASE}?id=${id}`,
      );
      this.contentReport = response.data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingContentReport = false;
    }
  };
}

export default new ContentReportsStore();
