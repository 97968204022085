import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { createMatrix } from '../utils/matrix';
import BaseHeatMap from './BaseHeatMap';
import { legendValue } from './HeatMapLegend';

const HeatMap = ({ data = [], label, isLegend }) => {
  const matrix = useMemo(() => createMatrix(data), [data]);

  const colorsForCells = (x, y) => {
    const articlePerHour = matrix[x][y];
    const { color } = legendValue.find(({ val }) => articlePerHour < val);

    return color;
  };

  return (
    <BaseHeatMap
      data={matrix}
      label={label}
      colorsForCells={colorsForCells}
      isLegend={isLegend}
    />
  );
};

export default HeatMap;

HeatMap.propTypes = {
  isLegend: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfArticles: PropTypes.number,

      time: PropTypes.shape({
        hour: PropTypes.number,
        day: PropTypes.number,
      }),
    }),
  ),
};
