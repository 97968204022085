export const options = {
  nodes: {
    shape: 'dot',
    size: 16,
  },
  height: '100%',
  physics: {
    maxVelocity: 50,
    timestep: 0.5,
  },
  edges: {
    width: 0.15,
    color: { inherit: 'from' },
    smooth: {
      type: 'continuous',
    },
    chosen: {
      edge: true,
    },
  },
  interaction: {
    tooltipDelay: 200,
  },
};
