import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 20,
    marginTop: 20,
  },
  legend: {
    flex: '0 1 auto',
    position: 'absolute',
  },
  button: {
    display: 'flex',
    flex: '0 1 auto',
    marginLeft: 'auto',
  },
};

export const CustomLegend = (props) => (
  <div style={styles.container}>
    <div style={styles.legend}>
      <ul
        style={{
          padding: 0,
          margin: 0,
          textAlign: 'center',
          listStyle: 'none',
          display: 'inline-block',
        }}
      >
        {props.payload.map((data, index) => (
          <div
            key={`custom-legend-list-item_${index}`}
            style={{
              display: 'inline-block',
              marginRight: 10,
              marginBottom: 13,
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={() => {
              if (props.series.indexOf(data.value) >= 0) {
                props.setSeries(
                  props.series.filter((key) => key !== data.value),
                );
              } else {
                props.setSeries([data.value.trim(), ...props.series]);
              }
            }}
          >
            <li
              style={{
                display: 'inline-block',
                minWidth: 'max-content',
                fontSize: 15,
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 32 32"
                version="1.1"
                style={{
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  marginRight: 4,
                }}
              >
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  stroke="none"
                  fill={data.color}
                />
              </svg>
              <span>{props.labels[data.value.trim()]}</span>
            </li>
          </div>
        ))}
      </ul>
    </div>
  </div>
);

CustomLegend.propTypes = {
  labels: PropTypes.any,
  payload: PropTypes.array,
  series: PropTypes.array,
  setSeries: PropTypes.func,
};
