import { action, observable } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class AnalystsStatisticsStore {
  @observable isLoading = false;
  @observable isLoadingAnalysts = false;

  @observable analysts = [];

  @observable chartData = [];
  @observable excelData = [];
  @observable isLoadingExcelData = false;

  @action getAnalystName = (analystId) => {
    const analyst = this.analysts.find(({ value }) => value === analystId);

    if (analyst) {
      return analyst.label;
    }
    return analystId.toString();
  };

  @action fetchPublicationsCount = async (startDate, endDate, analystId) => {
    this.isLoading = true;
    this.chartData = [];

    const query = {
      from: moment(startDate).toISOString(),
      to: moment(endDate).toISOString(),
      analyst_id: analystId,
    };

    try {
      const {
        data: {
          data: { publications },
        },
      } = await API.get(
        `${API_ROUTES.ANALYSTS_PUBLICATIONS}?${queryString.stringify(query)}`,
      );

      const data = this.prepareData(publications, startDate, endDate);

      this.chartData = data;
      this.isLoading = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchAnalysts = async () => {
    this.isLoadingAnalysts = true;

    try {
      const response = await API.get(API_ROUTES.ANALYSTS);

      this.analysts = response.data.analysts.map(
        ({ id, email, first_name: firstName, last_name: lastName }) => ({
          label: firstName && lastName ? `${firstName} ${lastName}` : email,
          value: id,
        }),
      );
      this.isLoadingAnalysts = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  prepareData = (countOfAnalystPublications, startDate, endDate) => {
    const data = [];

    let count = moment(endDate).diff(moment(startDate), 'days');

    while (count >= 0) {
      const date = moment(endDate)
        .subtract(count, 'days')
        .format('YYYY-MM-DD')
        .toString();
      const publicationsCount = countOfAnalystPublications[date] || 0;
      data.push({
        date,
        publicationsCount,
      });

      count -= 1;
    }
    return data;
  };
}

export default new AnalystsStatisticsStore();
