import React, { useState } from 'react';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import TotalsTab from './tabs/TotalsTab';
import TotalsPerSourceTab from './tabs/TotalsPerSourceTab';
import UniqueArticlesTab from './tabs/UniqueArticlesTab';
import UniqueUsersTab from './tabs/UniqueUsersTab';
import UsersTableTab from './tabs/UsersTableTab';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#8e7cc3',
  },
})((props) => <Tabs {...props} />);

const tabs = [
  { label: 'Totals', page: <TotalsTab /> },
  { label: 'Totals per source', page: <TotalsPerSourceTab /> },
  { label: 'Unique articles', page: <UniqueArticlesTab /> },
  { label: 'Unique users', page: <UniqueUsersTab /> },
  { label: 'Users table', page: <UsersTableTab /> },
];

const ArticlesAndClicks = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <StyledTabs variant="fullWidth" value={value} onChange={handleChange}>
        {tabs.map(({ label }) => (
          <Tab key={`tab-${label}`} label={label} />
        ))}
      </StyledTabs>
      {tabs.map(({ page }, index) => (
        <TabPanel key={`tab-panel-${index}`} value={value} index={index}>
          {page}
        </TabPanel>
      ))}
    </div>
  );
};

export default ArticlesAndClicks;
