// eslint-disable-next-line max-classes-per-file
import { action, observable } from 'mobx';
import moment from 'moment';
import merge from 'lodash/mergeWith';
import queryString from 'query-string';
import API from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';

export class ContentPublicationsStore {
  DEFAULT_QUERY_PARAMS = {
    creation_date_from: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
    creation_date_to: moment().format('YYYY-MM-DD'),
    publication_date_from: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
    publication_date_to: moment().format('YYYY-MM-DD'),
    order: 'creation_date_desc',
    status: '',
    container: -1,
    limit: 10,
    page: 0,
  };

  @observable formParams = {};
  @observable isLoading = false;
  @observable report = [];
  @observable totalRowsNumber = 0;
  @observable preview = {};
  @observable listsFetched = false;
  @observable containers = [];

  @observable formVisible = false;

  SEARCH_CACHE_TIMEOUT = 2 * 60 * 1000;
  searchCache = {};
  previewCache = {};

  @action fetchContentPublications = async ({ search }) => {
    if (search.length > 0 && search[0] === '?') {
      search = search.substring(1);
    }
    if (this.searchCache[search] !== undefined) {
      const { count, report, time, inProgress } = this.searchCache[search];
      if (inProgress === true ) {
        return;
      }
      const delta = new Date() - time;
      if (delta <= this.SEARCH_CACHE_TIMEOUT) {
        this.totalRowsNumber = count;
        this.report = report;
        return;
      }
    }
    this.searchCache[search] = { inProgress: true };
    this.isLoading = true;
    try {
      const {
        data: { count, report }
      } = await API.get(
        `${API_ROUTES.CONTENT_PUBLICATIONS.ALL}?${search}`
      );
      this.totalRowsNumber = count;
      this.report = report;
      this.searchCache[search] = { count, report, time: new Date(), inProgress: false };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchContentPublication = async ({ id }) => {
    if (id === undefined) {
      return;
    }
    if (this.previewCache[id] !== undefined) {
      const { report, time, inProgress } = this.previewCache[id];
      if (inProgress === true ) {
        return;
      }
      const delta = new Date() - time;
      if (delta <= this.SEARCH_CACHE_TIMEOUT) {
        [this.preview] = report;
        return;
      }
    }
    this.previewCache[id] = { inProgress: true };
    this.isLoading = true;
    try {
      const {
        data: { report }
      } = await API.get(
        API_ROUTES.CONTENT_PUBLICATIONS.PREVIEW(id)
      );
      [this.preview] = report;
      this.previewCache[id] = { report, time: new Date(), inProgress: false };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchLists = async () => {
    try {
      const {
        data: { containers }
      } = await API.get(
        API_ROUTES.CONTENT_PUBLICATIONS.LISTS
      );
      this.containers = containers;
      this.listsFetched = true;
    } catch (e) {
      this.error = e;
    }
  };

  @action toggleFilters = async () => {
    this.formVisible = !this.formVisible;
  };

  @action updateSearch = async ({ search }) => {
    this.formParams = {...this.DEFAULT_QUERY_PARAMS};
    this.mergeParams({ newParams: queryString.parse(search) });
  };

  @action mergeParams = async ({ newParams }) => {
    this.formParams = merge(this.DEFAULT_QUERY_PARAMS, this.formParams, newParams);
    const search = queryString.stringify(this.formParams);
    await this.fetchContentPublications({ search });
    return { pathname: APP_ROUTES.CONTENT_PUBLICATIONS, search };
  };
}

export default new ContentPublicationsStore();
