import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, Flex } from 'rebass';
import moment from 'moment-timezone';
import { Paper, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AreaChart from '../../_common/components/AreaChart';
import MediumPieChart from '../../_common/components/MediumPieChart';
import {
  dateTimeFormat,
  simpleDateTimeFormat,
} from '../../stores/dashboardStore';

@inject('dashboardStore')
@observer
class DailyTrafficChart extends Component {
  onChartClick = ({ activeLabel }) => {
    const newDateFrom = moment(activeLabel, simpleDateTimeFormat)
      .subtract(2, 'hour')
      .format(dateTimeFormat);
    const newDateTo = moment(activeLabel, simpleDateTimeFormat)
      .add(3, 'hour')
      .format(dateTimeFormat);
    this.props.onDateChange({ dateFrom: newDateFrom, dateTo: newDateTo });
  };

  onPieClick = ({ name }) => {
    this.props.onDateChange({ medium: name });
  };

  resetFilters = () => {
    this.props.onDateReset({
      dateFrom: moment()
        .tz('Europe/London')
        .startOf('day')
        .subtract(1, 'week')
        .format(dateTimeFormat),
      dateTo: moment()
        .tz('Europe/London')
        .startOf('day')
        .add(1, 'day')
        .format(dateTimeFormat),
      medium: '',
    });
  };

  render() {
    const { traffic, devices, onDateChange, dashboardStore } = this.props;
    return (
      <Paper>
        <Flex width={1} justifyContent="space-between">
          <Text fontSize={30} ml={28} py={20}>
            Daily traffic
          </Text>
          <Flex width={580} mr={28} alignItems="center">
            <Button
              size="large"
              variant="outlined"
              color="primary"
              type="button"
              onClick={this.resetFilters}
            >
              Clear
            </Button>
            <TextField
              onChange={onDateChange}
              error={dashboardStore.dateFromHasErrors}
              helperText={
                dashboardStore.dateFromHasErrors
                  ? 'Date from should be before date to'
                  : ''
              }
              id="dateFrom"
              label="From date"
              type="date"
              variant="outlined"
              style={{ margin: '0 10px' }}
              value={moment(dashboardStore.formData.date_from).format(
                'YYYY-MM-DD',
              )}
              fullWidth
            />
            <TextField
              onChange={onDateChange}
              error={dashboardStore.dateFromHasErrors}
              helperText={
                dashboardStore.dateFromHasErrors
                  ? 'Date from should be before date to'
                  : ''
              }
              id="dateTo"
              label="To date"
              type="date"
              variant="outlined"
              style={{ margin: '0 10px' }}
              value={moment(dashboardStore.formData.date_to).format(
                'YYYY-MM-DD',
              )}
              fullWidth
            />
          </Flex>
        </Flex>
        <Flex>
          <AreaChart data={traffic} onChartClick={this.onChartClick} />
          <MediumPieChart data={devices} onPieClick={this.onPieClick} />
        </Flex>
      </Paper>
    );
  }
}

DailyTrafficChart.propTypes = {
  traffic: PropTypes.array,
  devices: PropTypes.array,
  onDateChange: PropTypes.func,
  onDateReset: PropTypes.func,
  dashboardStore: PropTypes.shape({
    fetchTraffic: PropTypes.func,
    trafficData: PropTypes.object,
    fetchRecentEvents: PropTypes.func,
    recentEvents: PropTypes.array,
    isLoading: PropTypes.bool,
    formData: PropTypes.object,
    dateFromHasErrors: PropTypes.bool,
  }),
};

DailyTrafficChart.defaultProps = {
  traffic: [],
  devices: [],
};

export default DailyTrafficChart;
