import moment from 'moment';
import ColumnDescription, { formatEmpty, DEFAULT_DATE_FORMAT } from '../_common/ColumnDescription';

export const COLUMNS = [
  new ColumnDescription({ id: 'id',  align: 'center', }),
  new ColumnDescription({
    id: 'creation_date',
    text: formatEmpty(value => moment(value).format(DEFAULT_DATE_FORMAT)),
  }),
  new ColumnDescription({
    id: 'content_publication_date',
    text: (value) => value ? moment(value).format(DEFAULT_DATE_FORMAT) : '\u2013',
  }),
  new ColumnDescription({ id: 'company_name', }),
  new ColumnDescription({
    id: 'company_disabled',
    text: (value) => value ? 'Yes' : 'No',
    align: 'center',
  }),
  new ColumnDescription({ id: 'company_type',  align: 'center', }),
  new ColumnDescription({ id: 'sector_name', }),
  new ColumnDescription({ id: 'section_type', align: 'center', }),
  new ColumnDescription({ id: 'newspaper', }),
  new ColumnDescription({ id: 'source_raw_title', }),
  new ColumnDescription({ id: 'source', }),
  new ColumnDescription({ id: 'analyst', }),
  new ColumnDescription({ id: 'editor', }),
  new ColumnDescription({ id: 'published_by', }),
  new ColumnDescription({ id: 'content_title', }),
  new ColumnDescription({ id: 'content_body', forAnalysts: true, }),
  new ColumnDescription({ id: 'article_url', label: 'URL', }),
  new ColumnDescription({ id: 'article_sentiment_score', align: 'center', }),
  new ColumnDescription({ id: 'analyst_sentiment', align: 'center', }),
  new ColumnDescription({
    id: 'spokesperson_mention',
    text: (value) => value ? 'Yes' : 'No',
    align: 'center',
  }),
  new ColumnDescription({
    id: 'headline_mention',
    text: (value) => value ? 'Yes' : 'No',
    align: 'center',
  }),
  new ColumnDescription({
    id: 'article_type',
    align: 'center',
    text: value => value === 0 ? 'straight' : value === 1 ? 'opinion' : formatEmpty()(value),
  }),
  new ColumnDescription({ id: 'article_published_by_source', forAnalysts: true, }),
  new ColumnDescription({ id: 'article_received_from_source', forAnalysts: true, }),
  new ColumnDescription({ id: 'source_delay', forAnalysts: true, align: 'center', }),
  new ColumnDescription({ id: 'estimate_alert_arrived', forAnalysts: true, align: 'center', }),
  new ColumnDescription({ id: 'estimate_email_arrived', forAnalysts: true, align: 'center', }),
  new ColumnDescription({ id: 'alert_delay', forAnalysts: true, align: 'center', }),
  new ColumnDescription({ id: 'reaction_time', forAnalysts: true, align: 'center', }),
  new ColumnDescription({ id: 'time_to_publish', forAnalysts: true, align: 'center', }),
  new ColumnDescription({ id: 'article_alert_generated', align: 'center', }),
  new ColumnDescription({ id: 'journalist', }),
  new ColumnDescription({ id: 'author', }),
];

const SYMBOL_ASC = '\u2193';
const SYMBOL_DESC = '\u2191';

export const ORDERS = [
  {label: `${SYMBOL_DESC} Creation`, value: 'creation_date_desc'},
  {label: `${SYMBOL_ASC} Creation`, value: 'creation_date_asc'},
  {label: `${SYMBOL_DESC} Publication`, value: 'publication_date_desc'},
  {label: `${SYMBOL_ASC} Publication`, value: 'publication_date_asc'},
];