import { action, observable } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class ArticleAggregatesStore {
  @observable isLoading = false;
  @observable isLoadingKeys = false;

  @observable keys = [];
  @observable chartData = [];
  @observable chartKeys = [];

  @action fetchDistinct = async (startDate, endDate, type) => {
    this.isLoading = true;
    this.keys = [];

    const query = {
      fromDate: moment(startDate).toISOString(),
      toDate: moment(endDate).toISOString(),
      type,
    };

    try {
      const {
        data: { data },
      } = await API.get(
        `${API_ROUTES.ARTICLE_AGGREGATES_DISTINCT}?${queryString.stringify(
          query,
        )}`,
      );

      this.keys = data.map((key) => ({ label: key, value: key }));
      this.isLoading = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchAggregates = async (startDate, endDate, type, keys) => {
    this.isLoading = true;
    this.distinctKey = [];

    const query = {
      fromDate: moment(startDate).toISOString(),
      toDate: moment(endDate).toISOString(),
      type,
      keys: keys.join(','),
    };

    try {
      const {
        data: { data: aggregate },
      } = await API.get(
        `${API_ROUTES.ARTICLE_AGGREGATES}?${queryString.stringify(query)}`,
      );

      this.chartData = this.prepareData(aggregate, keys, startDate, endDate);
      this.isLoading = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  prepareData = (data, keys, startDate, endDate) => {
    const result = [];

    let count = moment(endDate).diff(moment(startDate), 'months');
    const emptyKeysObj = {};
    keys.forEach((key) => {
      emptyKeysObj[key] = 0;
    });

    while (count >= 0) {
      const date = moment(endDate)
        .subtract(count, 'month')
        .format('YYYY-MM')
        .toString();
      if (data[date]) {
        result.push({ ...emptyKeysObj, ...data[date] });
      } else {
        result.push({
          date: date.toString(),
          ...emptyKeysObj,
        });
      }
      count -= 1;
    }

    return result;
  };
}

export default new ArticleAggregatesStore();
