import moment from 'moment';
import ColumnDescription, { formatEmpty, DEFAULT_DATE_FORMAT } from '../_common/ColumnDescription';

export const COLUMNS = [
  new ColumnDescription({ id: 'id', align: 'center', }),
  new ColumnDescription({
    id: 'creation_date',
    text: formatEmpty(value => moment(value).format(DEFAULT_DATE_FORMAT)),
  }),
  new ColumnDescription({
    id: 'publication_date',
    text: formatEmpty(value => moment(value).format(DEFAULT_DATE_FORMAT)),
  }),
  new ColumnDescription({ id: 'title', }),
  new ColumnDescription({ id: 'content_container_name', label: 'Content container', }),
  new ColumnDescription({ id: 'status', align: 'center', }),
  new ColumnDescription({ id: 'publication_type', align: 'center', }),
  new ColumnDescription({ id: 'author', }),
  new ColumnDescription({ id: 'body', }),
];

const SYMBOL_ASC = '\u2193';
const SYMBOL_DESC = '\u2191';

export const ORDERS = [
  {label: `${SYMBOL_DESC} Creation`, value: 'creation_date_desc'},
  {label: `${SYMBOL_ASC} Creation`, value: 'creation_date_asc'},
  {label: `${SYMBOL_DESC} Publication`, value: 'publication_date_desc'},
  {label: `${SYMBOL_ASC} Publication`, value: 'publication_date_asc'},
];

export const STATUSES = [
  {label: 'Any', value: ''},
  {label: 'Published', value: 'published'},
  {label: 'Unpublished', value: 'unpublished'},
  {label: 'Rejected', value: 'rejected'},
  {label: 'Deleted', value: 'deleted'},
];