import ReactPropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { APP_ROUTES } from '../../_app/routes';
import ReportLabel from '../../_common/components/ReportLabel';
import ReportPreview from '../../_common/components/ReportPreview';
import Loader from '../../_common/components/Loader';
import { COLUMNS } from  '../constants';


function NlaPreview({ nlaStore: { isLoadingNla, nla, fetchNla } }) {
  const { id } = useParams();
  const entryId = parseInt(id || -1, 10);

  useEffect(() => {
    fetchNla({ id });
  }, []);

  const columns = COLUMNS.filter((column) => column.id !== 'action_buttons')

  return (
    <>
      <ReportLabel
        entryId={entryId}
        parentLabel="Nla Usage Aggregates"
        parentLink={APP_ROUTES.NLA}
      />

      {
        isLoadingNla || nla === undefined ?
          <Loader size={60} />
        :
          <ReportPreview
            entryId={entryId}
            cells={columns.map(column => column.get(nla[column.id]))}
          />
      }
    </>
  );
}

NlaPreview.propTypes = {
  nlaStore: ReactPropTypes.shape({
    nla: MobXPropTypes.any,
    isLoadingNla: MobXPropTypes.bool,
    fetchNla: MobXPropTypes.func,
  }),
};

export default inject('nlaStore')(observer(NlaPreview));
