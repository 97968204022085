import React from 'react';
import ReactExport from 'react-data-export';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const ExcelDownload = ({ fileName, columns, data, sheetName }) => {
  const columnsWithWidth = columns.map((column) => ({
    title: column.label,
    width: { wpx: column.excelWidth },
  }));

  const cellData = data.map((item) => {
    const row = [];
    columns.forEach((column) => {
      row.push({
        value: column.formatExcel
          ? column.formatExcel(item[column.id])
          : item[column.id],
      });
    });
    return row;
  });

  const cellDataSet = [
    {
      columns: columnsWithWidth,
      data: cellData,
    },
  ];

  return (
    <ExcelFile
      filename={fileName}
      element={
        <Button variant="outlined" size="large" color="primary" type="button">
          Export to Excel
        </Button>
      }
    >
      <ExcelSheet
        dataSet={cellDataSet}
        name={sheetName.replace('&', '&amp;')}
      />
    </ExcelFile>
  );
};

export default ExcelDownload;

ExcelDownload.propTypes = {
  fileName: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  sheetName: PropTypes.string,
};
