import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CustomRangeExport from '../components/CustomRangeExport';
import UsersTable from '../components/UsersTable';

const UsersTableTab = ({ articlesAndClicksStore }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD').toString(),
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString(),
  );

  const isDateDiffLessThen180 =
    moment(endDate).diff(moment(startDate), 'days') <= 180;
  const isDateDiffPositive =
    moment(endDate).diff(moment(startDate), 'days') > 0;

  const columns = [
    {
      id: '_id',
      title: 'User email',
      width: 300,
    },
    {
      id: 'count',
      title: `Count from ${startDate} to ${endDate}`,
      width: 250,
    },
  ];

  const { fetchUserEvents, userEvents, isLoadingExcelData } =
    articlesAndClicksStore;

  useEffect(() => {
    if (isDateDiffLessThen180 && isDateDiffPositive) {
      fetchUserEvents(0, 65000, 'excel', startDate, endDate);
    }
  }, [startDate, endDate]);

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <UsersTable collection={30} />
      </div>
      <UsersTable collection={180} />
      <CustomRangeExport
        marginLeft={0}
        columns={columns}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        disableButton={!isDateDiffLessThen180 || !isDateDiffPositive}
        isLoading={isLoadingExcelData}
        data={userEvents.excel}
        fileName="Count per user"
        sheetName="Count per user"
      />
    </div>
  );
};

export default inject('articlesAndClicksStore')(observer(UsersTableTab));

UsersTableTab.propTypes = {
  articlesAndClicksStore: PropTypes.shape({
    isLoadingExcelData: PropTypes.bool,
    fetchUserEvents: PropTypes.func,
    userEvents: PropTypes.array,
  }),
};
