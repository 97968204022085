import { action, observable } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export class ArticleSourcesMatrixStore {
  @observable isLoadingSourcesMatrix = false;
  @observable isLoadingArticleSources = false;

  @observable avgArticleSourcesMatrixData = [];
  @observable articleSourcesMatrixData = [];
  @observable articleSources = [];

  @action fetchArticleSourcesMatrix = async (articleSourceId) => {
    this.isLoadingSourcesMatrix = true;
    const query = {
      articleSourceId,
      timeFrom: moment().startOf('isoWeek'),
      timeTo: moment().endOf('isoWeek').startOf('hour'),
    };

    try {
      const response = await API.get(
        `${API_ROUTES.ARTICLE_SOURCES_MATRIX.STATS}?${queryString.stringify(
          query,
        )}`,
      );

      this.articleSourcesMatrixData = response.data.data.map((art) => ({
        numberOfArticles: art.numberOfArticles,
        time: {
          hour: moment(art.timeFrom, dateTimeFormat).hours(),
          // -1 to fit array
          day: moment(art.timeFrom, dateTimeFormat).isoWeekday() - 1,
        },
      }));
      this.isLoadingSourcesMatrix = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchArticleSources = async () => {
    this.isLoadingArticleSources = true;
    try {
      const response = await API.get(
        API_ROUTES.ARTICLE_SOURCES_MATRIX.ARTICLE_SOURCES,
      );

      this.articleSources = response.data.data.map(({ id, title }) => ({
        label: `${title} (${id})`,
        value: id,
      }));
      this.isLoadingArticleSources = false;
    } catch (e) {
      console.log(e.message);
    }
  };

  @action fetchAvgArticleSourcesMatrix = async (articleSourceId) => {
    this.isLoadingSourcesMatrix = true;
    const query = {
      articleSourceId,
      timeFrom: moment().startOf('isoWeek').subtract(4, 'weeks'),
      timeTo: moment().endOf('isoWeek').subtract(1, 'week'),
    };
    try {
      const response = await API.get(
        `${API_ROUTES.ARTICLE_SOURCES_MATRIX.AVERAGE}?${queryString.stringify(
          query,
        )}`,
      );

      this.avgArticleSourcesMatrixData = response.data.data.map((avg) => ({
        numberOfArticles: avg.avg,
        time: {
          hour: avg.hour,
          // -1 to fit array
          day: avg.dayOfWeek - 1,
        },
      }));
      this.isLoadingSourcesMatrix = false;
    } catch (e) {
      console.log(e.message);
    }
  };
}

export default new ArticleSourcesMatrixStore();
