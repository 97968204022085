import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/mergeWith';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Button from '@material-ui/core/Button';
import TextField from '../../../_common/components/forms/TextField';
import TextFieldSearch from '../../../_common/components/forms/TextFieldSearch';
import FormSelect from '../../../_common/components/forms/FormSelect';
import AutoSave from '../../../_common/components/forms/AutoSave';
import Loader from '../../../_common/components/Loader';
import { FiltersPaper } from '../../style';
import { ORDERS } from  '../../constants';


const ClientCoveragesForm = ({
  initialValues,
  onSubmit,
  fetchLists,
  listsFetched,
  analysts,
  editors,
  publishers,
  forAnalysts,
  toggleForAnalysts,
  formVisible,
  toggleFilters,
}) => {

  useEffect(() => {
    fetchLists();
  }, []);

  if (!listsFetched) {
    return (
      <Loader size={60} />
    );
  }

  const clearable = {company_name: '', source: '', newspaper: ''};

  return (
    <Form
      onSubmit={(values) => onSubmit(merge(clearable, values) )}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      style={{ flexDirection: 'column' }}
      render={({ handleSubmit, values, mutators, form }) => (
        <FiltersPaper>
          <form id="ClientCoveragesForm" onSubmit={handleSubmit}>
            <AutoSave debounce={1000} save={handleSubmit} values={values} />
            <Flex mb={20} mx={-15} flexWrap="wrap">
              <Box px={15} width={[1, 1/2, 1/5]}>
                <Button
                  size="large"
                  variant={forAnalysts ? 'contained' : 'outlined'}
                  color="primary"
                  type="button"
                  onClick={toggleForAnalysts}
                  fullWidth
                >
                  {forAnalysts ? 'Analyst view' : 'Standard view'}
                </Button>
              </Box>
              <Box px={15} width={[1, 1/2, 1/5]}>
                <Button
                  size="large"
                  variant={formVisible ? 'outlined' : 'contained'}
                  color="primary"
                  type="button"
                  onClick={toggleFilters}
                  fullWidth
                >
                  {formVisible ? 'Hide filters' : 'Open filters' }
                </Button>
              </Box>
              { formVisible && (
                <Box px={15} width={[1, 1/2, 1/5]}>
                  <Button
                    size="large"
                    color="secondary"
                    onClick={() => setTimeout(form.reset())}
                    fullWidth
                  >
                    Clear filters
                  </Button>
                </Box>
              )}
            </Flex>
            { formVisible && (
              <>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Creation date from"
                      type="date"
                      name="creation_date_from"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Creation date to"
                      type="date"
                      name="creation_date_to"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Publication date from"
                      type="date"
                      name="publication_date_from"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Publication date to"
                      type="date"
                      name="publication_date_to"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <FormSelect options={ORDERS} name="order" label="Order" />
                  </Box>
                </Flex>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <TextFieldSearch
                      setValue={mutators.setValue}
                      name="company_name"
                      label="Company name*"
                      myOnChange={() => []}
                      suggestions={[]}
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <TextFieldSearch
                      setValue={mutators.setValue}
                      name="source"
                      label="Source*"
                      myOnChange={() => []}
                      suggestions={[]}
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <TextFieldSearch
                      setValue={mutators.setValue}
                      name="newspaper"
                      label="Newspaper*"
                      myOnChange={() => []}
                      suggestions={[]}
                    />
                  </Box>
                </Flex>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <FormSelect options={analysts} name="analyst" label="Analyst" />
                  </Box>
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <FormSelect options={editors} name="editor" label="Editor" />
                  </Box>
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <FormSelect options={publishers} name="published_by" label="Published by" />
                  </Box>
                </Flex>
              </>
            )}
          </form>
        </FiltersPaper>
      )}
      />
  );
};

ClientCoveragesForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  fetchLists: PropTypes.func,
  listsFetched: PropTypes.bool,
  analysts: PropTypes.array,
  editors: PropTypes.array,
  publishers: PropTypes.array,
  forAnalysts: PropTypes.bool,
  toggleForAnalysts: PropTypes.func,
  formVisible: PropTypes.bool,
  toggleFilters: PropTypes.func,
};

export default ClientCoveragesForm;