import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import NewspapersList from './components/NewspapersList';
import NewspapersForm from './components/forms/NewspapersForm';


@inject('newspapersStore')
@observer
class Newspapers extends Component {

  async componentDidMount() {
    await this.updateStore();
  }

  async componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (prevProps.location.search !== search) {
      await this.updateStore();
    }
  }

  async onSubmit(newParams) {
    this.props.history.push(await this.props.newspapersStore.mergeParams({ newParams }));
  }

  async updateStore() {
    await this.props.newspapersStore.updateSearch({ search: this.props.location.search });
  }

  async toggleForAnalysts() {
    this.onSubmit({ for_analyst: this.props.newspapersStore.formParams.for_analyst?.toString() !== 'true' });
  }

  render() {
    const {
      formParams,
      DEFAULT_QUERY_PARAMS,
      list,
      totalRowsNumber,
      orders,
      isLoading,
      hiddenColumns,
      toggleColumn,
      formVisible,
      toggleFilters,
      COLUMNS,
      fetchSources,
      sourcesFetched,
      articleSources,
    } = this.props.newspapersStore;
    return (
      <>
        <NewspapersForm
          initialValues={() => DEFAULT_QUERY_PARAMS}
          onSubmit={async (values) => this.onSubmit(values)}
          orders={() => orders}
          toggleForAnalysts={() => this.toggleForAnalysts()}
          formVisible={() => formVisible}
          toggleFilters={toggleFilters}
          articleSources={() => articleSources}
          fetchSources={fetchSources}
          sourcesFetched={() => sourcesFetched}
        />
        <NewspapersList
          onSubmit={async (values) => this.onSubmit(values)}
          list={() => list}
          totalRowsNumber={() => totalRowsNumber}
          hiddenColumns={() => hiddenColumns}
          toggleColumn={toggleColumn}
          isLoading={() => isLoading}
          limit={() => parseInt(formParams.limit, 10)}
          page={() => parseInt(formParams.page, 10)}
          columns={() => COLUMNS}
          articleSources={() => articleSources}
        />
      </>
    );
  }
};

Newspapers.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  newspapersStore: PropTypes.shape({
    formParams: PropTypes.object,
    updateSearch: PropTypes.func,
    mergeParams: PropTypes.func,
    DEFAULT_QUERY_PARAMS: PropTypes.object,
    list: PropTypes.array,
    totalRowsNumber: PropTypes.any,
    orders: PropTypes.array,
    isLoading: PropTypes.bool,
    hiddenColumns: PropTypes.object,
    toggleColumn: PropTypes.func,
    formVisible: PropTypes.bool,
    toggleFilters: PropTypes.func,
    COLUMNS: PropTypes.array,
    articleSources: PropTypes.object,
    fetchSources: PropTypes.func,
    sourcesFetched: PropTypes.bool,
  }),
};

export default Newspapers;