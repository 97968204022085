import React, { useEffect } from 'react';
import ReactPropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import ReactTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { APP_ROUTES } from '../../_app/routes';
import Loader from '../../_common/components/Loader';
import { datePrettier } from '../../utils/text';

const LABELS_MAP = {
  id: 'Id',
  created_at: 'Created at',
  published_at: 'Published at',
  body: 'Body',
  analyst: 'Analyst',
  source: 'Source',
  title: 'Title',
  report_url: 'Report url',
  article_url: 'Article url',
  image: 'Image',
  attachment: 'Attachment',
  secure_image: 'Secure image',
  secure_attachment: 'Secure Attachment',
  author: 'Author',
  rich_text_in_body: 'Rich text in body',
  content_type: 'Content type',
  acast_url: 'Acast url',
  spotify_url: 'Spotifty url',
  apple_music_url: 'Apple url',
  url: 'Url',
  article_id: 'Article id',
  analyst_sentiment: 'Analyst sentiment',
  updated_at: 'Updated at',
};
const BLOCKED_FIELDS = ['status'];

function PreviewContent({
  contentReportsStore: {
    isLoadingContentReport,
    contentReport,
    fetchContentReport,
  },
}) {
  const { id } = useParams();

  const valueRenderer = (key, value) => {
    if (key === 'rich_text_in_body') {
      return (
        <Box
          sx={{
            backgroundColor: 'grey',
            display: 'flex',
            padding: '5px',
            color: 'white',
            width: 'min-content',
            fontWeight: 600,
          }}
        >
          {value ? 'YES' : 'NO'}
        </Box>
      );
    }
    if (['created_at', 'updated_at'].includes(key)) {
      return datePrettier(value);
    }

    return value;
  };

  useEffect(() => {
    fetchContentReport({ id });
  }, []);

  if (isLoadingContentReport) return <Loader size={60} />;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to={APP_ROUTES.CONTENT_REPORTS}
          style={{ textDecoration: 'none', color: 'grey' }}
        >
          Content reports
        </Link>
        <Typography
          color="inherit"
          style={{ textDecoration: 'none', color: 'grey' }}
        >
          {contentReport?.title || 'Loading...'}
        </Typography>
      </Breadcrumbs>

      <Paper style={{ marginTop: '40px' }}>
        <ReactTable>
          <TableBody>
            {contentReport &&
              Object.entries(contentReport)
                .filter((x) => !BLOCKED_FIELDS.includes(x[0]))
                .map(([key, value]) => (
                  <TableRow key={`row-${key}`}>
                    <TableCell style={{ width: '200px' }}>
                      {[LABELS_MAP[key]]}
                    </TableCell>
                    <TableCell>{valueRenderer(key, value)}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </ReactTable>
      </Paper>
    </>
  );
}

PreviewContent.propTypes = {
  contentReportsStore: ReactPropTypes.shape({
    contentReport: MobXPropTypes.any,
    isLoadingContentReport: MobXPropTypes.bool,
    fetchContentReport: MobXPropTypes.func,
  }),
};

export default inject('contentReportsStore')(observer(PreviewContent));
