import validator from 'validator';
import moment from 'moment';

export const composeValidators =
  (...validators) =>
  (value, values) =>
    validators.reduce(
      (error, _validator) => error || _validator(value, values),
      undefined,
    );

export const required = (value) =>
  value ? undefined : 'This field is required';

export const mustBeValidEmail = (value) =>
  validator.isEmail(value) ? undefined : 'Invalid email adress';

export const passwordsMustMatch = (value, allValues) =>
  value === allValues.password ? undefined : 'Password confirmation must match';

export const passwordLength =
  (length) =>
  (value = '') =>
    value === '' || value.length >= length
      ? undefined
      : `Password needs to be ${length} characters or longer`;

export const validateDate = (value, allValues, key) => {
  const from = moment(allValues[key.from]);
  const to = moment(allValues[key.to]);
  return from.isSameOrBefore(to)
    ? undefined
    : 'Date from should be before date to';
};
