import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Flex } from 'rebass';
import Loader from './Loader';

const style = {
  width: '60px',
  height: '60px',
};

const IconWithText = ({ icon: Icon, mainText, text, isLoading }) => (
  <Flex alignItems="flex-end">
    <Icon style={style} />
    <Box mb={10} ml={10}>
      <Text fontSize={24} fontWeight="bold" lineHeight={1}>
        {isLoading ? (
          <Loader
            size={20}
            py={0}
            mb="5px"
            ml={10}
            justifyContent="flex-start"
          />
        ) : (
          mainText
        )}
      </Text>
      <Text lineHeight={1}>{text}</Text>
    </Box>
  </Flex>
);

IconWithText.propTypes = {
  icon: PropTypes.object,
  mainText: PropTypes.number,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default IconWithText;
