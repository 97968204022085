import { APP_ROUTES } from './routes';

export const TOKEN_KEY = 'tulchan-popcorn-token';

export const ORDER_LABELS = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

export const CONTENT_TYPE_LABELS = [
  {
    label: 'Straight',
    value: '0',
  },
  {
    label: 'Opinion',
    value: '1',
  },
];

export const PAGE_LABELS = {
  [APP_ROUTES.DASHBOARD]: 'Dashboard',
  [APP_ROUTES.USERS]: 'Users',
  [APP_ROUTES.SENTIMENTS]: 'Sentiments',
  [APP_ROUTES.EMAILS]: 'Emails',
  [APP_ROUTES.ARCHIVED_ARTICLES]: 'Archived Articles',
  [APP_ROUTES.REPORTS]: 'Reports',
  [APP_ROUTES.ALERTS_STATISTICS]: 'Alerts statistics',
  [APP_ROUTES.ARTICLES_AND_CLICKS]: 'Articles & Clicks',
  [APP_ROUTES.ANALYSTS_STATISTICS]: 'Statistics for analysts',
  [APP_ROUTES.ARTICLE_AGGREGATES]: 'Article aggregates',
  [APP_ROUTES.ARTICLE_SOURCES_MATRIX]: 'Article sources matrix',
  [APP_ROUTES.NETWORK_GRAPH]: 'Companies and peers',
  [APP_ROUTES.CONTENT_REPORTS]: 'Content reports',
  [APP_ROUTES.SUMMARY_REPORTS]: 'Summary reports',
  [APP_ROUTES.CLIENT_COVERAGES]: 'Client coverages',
  [APP_ROUTES.NEWSPAPERS]: 'Newspapers',
  [APP_ROUTES.NLA]: 'Nla Usage Aggregates',
};

export const TIMEFRAME_OPTIONS = [
  { value: 'week', label: 'this week' },
  { value: 'last_week', label: 'last week' },
  { value: 'month', label: 'last 4 weeks' },
];
