import React, { Component, Fragment } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';
import { APP_ROUTES } from './_app/routes';
import ScrollToTop from './ScrollToTop';
import AppRoute from './_common/AppRoute';
import Dashboard from './dashboard';
import Users from './users';
import Sentiments from './sentiments';
import Emails from './emails';
import MagicLink from './signInMagicLink';
import SignIn from './signIn';
import ArchivedArticles from './archivedArticles';
import { history } from './_app/history';
import Reports from './reports';
import ArticlesAndClicks from './articlesAndClicks';
import AnalystStatistics from './analystsStatistics';
import AlertsStatistics from './alertsStatistics';
import ArticleAggregates from './articleAggregates';
import ArticleSourcesMatrix from './articleSourcesMatrix';
import CompaniesRelations from './companiesRelations';
import ContentReportPreview from './contentReports/Preview';
import ContentReportsListing from './contentReports/Listing';
import SummaryReportPreview from './summaryReports/Preview';
import SummaryReportsListing from './summaryReports/Listing';
import ClientCoverages from './clientCoverages';
import ClientCoveragePreview from './clientCoverages/Preview';
import Newspapers from './newspapers';
import NlaListing from './nla/Listing';
import NlaPreview from './nla/Preview';
import ContentPublications from './contentPublications';
import ContentPublicationPreview from './contentPublications/Preview';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <AppRoute
              exact
              path={APP_ROUTES.DASHBOARD}
              component={Dashboard}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.USERS}
              component={Users}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.SENTIMENTS}
              component={Sentiments}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.EMAILS}
              component={Emails}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.ARCHIVED_ARTICLES}
              component={ArchivedArticles}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.REPORTS}
              component={Reports}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.NETWORK_GRAPH}
              component={CompaniesRelations}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.ARTICLES_AND_CLICKS}
              component={ArticlesAndClicks}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.ALERTS_STATISTICS}
              component={AlertsStatistics}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.ANALYSTS_STATISTICS}
              component={AnalystStatistics}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.ARTICLE_AGGREGATES}
              component={ArticleAggregates}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.ARTICLE_SOURCES_MATRIX}
              component={ArticleSourcesMatrix}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.CLIENT_COVERAGES}
              component={ClientCoverages}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.CLIENT_COVERAGE_PREVIEW}
              component={ClientCoveragePreview}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.MAGIC_LINK}
              component={MagicLink}
              layout={Fragment}
            />
            <AppRoute
              exact
              path={APP_ROUTES.SIGN_IN}
              component={SignIn}
              layout={Fragment}
            />
            <AppRoute
              exact
              path={APP_ROUTES.CONTENT_REPORTS}
              component={ContentReportsListing}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.CONTENT_REPORT_PREVIEW}
              component={ContentReportPreview}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.SUMMARY_REPORTS}
              component={SummaryReportsListing}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.SUMMARY_REPORT_PREVIEW}
              component={SummaryReportPreview}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.NEWSPAPERS}
              component={Newspapers}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.NLA_PREVIEW}
              component={NlaPreview}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.CONTENT_PUBLICATIONS}
              component={ContentPublications}
              isPrivate
            />
            <AppRoute
              exact
              path={APP_ROUTES.CONTENT_PUBLICATION_PREVIEW}
              component={ContentPublicationPreview}
              isPrivate
            />
            <AppRoute path={APP_ROUTES.NLA} component={NlaListing} isPrivate />
            <Route
              path="*"
              render={() => <Redirect to={APP_ROUTES.DASHBOARD} />}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
