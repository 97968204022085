import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/mergeWith';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '../../../_common/components/forms/TextField';
import TextFieldSearch from '../../../_common/components/forms/TextFieldSearch';
import FormSelect from '../../../_common/components/forms/FormSelect';
import AutoSave from '../../../_common/components/forms/AutoSave';
import Loader from '../../../_common/components/Loader';


const NewspapersForm = ({
  initialValues,
  onSubmit,
  orders,
  formVisible,
  toggleFilters,
  fetchSources,
  sourcesFetched,
  articleSources,
}) => {
  useEffect(() => {
    fetchSources();
  }, []);

  if (! sourcesFetched()) {
    return (
      <Loader size={60} />
    );
  }

  const clearable = {title: '', contact_email: ''};
  const showForm = formVisible();
  const articleOptions = Object.entries(articleSources()).map(([k, v]) => ({label: v, value: k}));
  articleOptions.unshift({label: 'Any', value: '-1'});

  return (
    <Form
      onSubmit={(values) => onSubmit(merge(clearable, values) )}
      initialValues={initialValues()}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      style={{ flexDirection: 'column' }}
      render={({ handleSubmit, values, mutators, form }) => (
        <Paper style={{ padding: '15px 15px 1px 15px', maxWidth: '80vw' }}>
          <form id="ClientCoveragesForm" onSubmit={handleSubmit}>
            <AutoSave debounce={1000} save={handleSubmit} values={values} />
            <Flex mb={20} mx={-15} flexWrap="wrap">
              <Box px={15} width={[1, 1/2, 1/5]}>
                <Button
                  size="large"
                  variant={showForm ? 'outlined' : 'contained'}
                  color="primary"
                  type="button"
                  onClick={toggleFilters}
                  fullWidth
                >
                  {showForm ? 'Hide filters' : 'Show filters' }
                </Button>
              </Box>
              { showForm && (
                <Box px={15} width={[1, 1/2, 1/5]}>
                  <Button
                    size="large"
                    color="secondary"
                    onClick={() => setTimeout(form.reset())}
                    fullWidth
                  >
                    Clear filters
                  </Button>
                </Box>
              )}
            </Flex>
            { showForm && (
              <>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Creation date from"
                      type="date"
                      name="creation_date_from"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Creation date to"
                      type="date"
                      name="creation_date_to"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Update date from"
                      type="date"
                      name="update_date_from"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <TextField
                      label="Update date to"
                      type="date"
                      name="update_date_to"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/5]} px={15}>
                    <FormSelect
                      options={orders()}
                      name="order"
                      label="Order"
                    />
                  </Box>
                </Flex>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <FormSelect
                      options={articleOptions}
                      name="article_source"
                      label="Article source"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <TextFieldSearch
                      setValue={mutators.setValue}
                      name="title"
                      label="Title"
                      myOnChange={() => []}
                      suggestions={[]}
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/3]} px={15}>
                    <TextFieldSearch
                      setValue={mutators.setValue}
                      name="contact_email"
                      label="Contact email"
                      myOnChange={() => []}
                      suggestions={[]}
                    />
                  </Box>
                </Flex>
              </>
            )}
          </form>
        </Paper>
      )}
      />
  );
};

NewspapersForm.propTypes = {
  initialValues: PropTypes.func,
  onSubmit: PropTypes.func,
  orders: PropTypes.func,
  formVisible: PropTypes.func,
  toggleFilters: PropTypes.func,
  articleSources: PropTypes.func,
  fetchSources: PropTypes.func,
  sourcesFetched: PropTypes.func,
};

export default NewspapersForm;