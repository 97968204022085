import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import Paper from '@material-ui/core/Paper';
import HtmlDialog from './HtmlDialog';

const EVENT_TYPES = [
  { label: 'ID:', key: 'postgres_id' },
  { label: 'URL:', key: 'url' },
  { label: 'Title:', key: 'title' },
  { label: 'Summary:', key: 'summary' },
  { label: 'Author:', key: 'author' },
  { label: 'Publication date:', key: 'pubdate' },
  { label: 'Orig guid:', key: 'orig_guid' },
  { label: 'News Source:', key: 'news_source' },
  { label: 'Created at:', key: 'created_at' },
  { label: 'Updated at:', key: 'updated_at' },
  { label: 'Article source ID:', key: 'article_source_id' },
  { label: 'Rig ur:', key: 'rig_ur' },
  { label: 'Content:', key: 'content' },
  { label: 'Nla link:', key: 'nla_link' },
  { label: 'Reuters instrument codes:', key: 'reuters_instrument_codes' },
  { label: 'Sentiment score:', key: 'sentiment_score' },
  { label: 'Processed at:', key: 'processed_at' },
  { label: 'Version:', key: 'version' },
  { label: 'Article source logo url:', key: 'article_source_logo_url' },
  { label: 'Embargoed:', key: 'embargoed' },
  { label: 'Embargoed until:', key: 'embargoed_until' },
  { label: 'Language:', key: 'lang' },
  { label: 'Orginal content:', key: 'original_content' },
  { label: 'Orginal title:', key: 'original_title' },
  { label: 'Orginal summary:', key: 'original_summary' },
];

const style = { whiteSpace: 'nowrap' };

const ArchivedArticlesList = ({ archivedArticles }) => (
  <>
    {archivedArticles.map((event, idx) => (
      <Box py={10} key={`event-type-${idx}`} width={1}>
        <Paper>
          <Box p={20} width={1}>
            {EVENT_TYPES.filter(({ key }) => event[key]).map(
              ({ label, key }, i) => (
                <Flex width={1} key={`article-type-key-${i}`}>
                  <Box pr={10} width={150}>
                    <Text
                      style={style}
                      color="gray"
                      textAlign="right"
                      fontSize={16}
                    >
                      {label}
                    </Text>
                  </Box>
                  <Box width={1}>
                    <Text fontSize={16}>
                      {key === 'content' && event[key] ? (
                        <HtmlDialog content={event[key]} />
                      ) : (
                        event[key] || '-'
                      )}
                    </Text>
                  </Box>
                </Flex>
              ),
            )}
          </Box>
        </Paper>
      </Box>
    ))}
  </>
);

ArchivedArticlesList.propTypes = {
  archivedArticles: PropTypes.array,
};

export default ArchivedArticlesList;
