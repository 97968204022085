import { useMemo } from 'react';
import forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import uniqBy from 'lodash/uniqBy';

function prepareChartKeys(data, prefix = 'company') {
  try {
    const dataKeys = [];
    forEach(data, (el) => {
      forEach(_keys(el), (key) => {
        if (key.toLowerCase().includes(prefix)) {
          dataKeys.push({
            key: `${key}.numberOfAlerts`,
            name: key,
            color: el[key].color,
            show: true,
          });
        }
      });
    });
    return uniqBy(dataKeys, 'name');
  } catch {
    return [];
  }
}

export default function useMultipleCompanyChartKeys(chartData) {
  return useMemo(() => prepareChartKeys(chartData), [chartData]);
}
