export const APP_ROUTES = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  USERS: '/users',
  SENTIMENTS: '/sentiments',
  EMAILS: '/emails',
  ARCHIVED_ARTICLES: '/archivedArticles',
  SIGN_IN: '/sign-in',
  MAGIC_LINK: '/magic-link',
  REPORTS: '/reports',
  ARTICLES_AND_CLICKS: '/articlesAndClicks',
  ANALYSTS_STATISTICS: '/analystsStatistics',
  ARTICLE_AGGREGATES: '/articleAggregates',
  ARTICLE_SOURCES_MATRIX: '/articleSourceMatrix',
  ALERTS_STATISTICS: '/alertsStatistics',
  NETWORK_GRAPH: '/networkGraph',
  CONTENT_REPORTS: '/contentReports',
  CONTENT_REPORT_PREVIEW: '/contentReports/:id',
  SUMMARY_REPORTS: '/summaryReports',
  SUMMARY_REPORT_PREVIEW: '/summaryReports/:id',
  CLIENT_COVERAGES: '/clientCoverages',
  CLIENT_COVERAGE_PREVIEW: '/clientCoverages/:id',
  NEWSPAPERS: '/newspapers',
  NLA: '/nla',
  NLA_PREVIEW: '/nla/:id',
  CONTENT_PUBLICATIONS: '/contentPublications',
  CONTENT_PUBLICATION_PREVIEW: '/contentPublications/:id',
};

export const API_ROUTES = {
  SIGN_IN: '/api/v1/auth',
  EVENTS: '/api/v1/events',
  EVENTS_COUNTERS: '/api/v1/events/counters',
  PROFILE: '/api/v1/profile',
  EVENTS_COUNTS_PER_DAY: '/api/v1/events/per_day',
  EVENTS_MEDIUMS: '/api/v1/events/mediums',
  EVENTS_TYPES: '/api/v1/events/types',
  EVENT_EMAILS: (email) => `/api/v1/events/user_emails?email=${email}`,
  EVENT_SUBSCRIPTIONS: (subscription) =>
    `/api/v1/events/subscriptions?subscription=${subscription}`,
  TRAFFIC: '/api/v1/events/traffic',
  COMPANIES: (company) => `/api/v1/companies?company=${company}`,
  SENTIMENTS: '/api/v1/sentiments',
  ARTICLE_SOURCES: (articleSource) =>
    `/api/v1/articleSources?article_source=${articleSource}`,
  EMAILS: '/api/v1/emails',
  EMAILS_COUNTS_PER_DAY: '/api/v1/emails/per_day',
  ARCHIVED_ARTICLES: '/api/v1/archivedArticles',
  ARCHIVED_ARTICLE_COUNTS_PER_DAY: '/api/v1/emails/per_day',
  GET_SUBSCRIPTIONS: '/api/v1/subscriptions',
  GET_SUBSCRIPTIONS_ALL: '/api/v1/subscriptions/all',
  GET_SUBSCRIPTION: (id) => `/api/v1/subscriptions/${id}`,
  GET_ALL_COMPANIES: '/api/v1/companies/allCompanies',
  ALERTS_STATISTICS: {
    COMPANIES: '/api/v1/alertsStats/companies',
    COMPANIES_COLORS: '/api/v1/alertsStats/companiesColors',
    SECTORS: '/api/v1/alertsStats/sectors',
    CHARTS: {
      SECTOR: {
        DAILY: (sectorId, from, to) =>
          `/api/v1/alertsStats/stats/daily?sectorId=${sectorId}&timeFrom=${from}&timeTo=${to}`,
        HOURLY: (sectorId, from, to) =>
          `/api/v1/alertsStats/stats/hourly?sectorId=${sectorId}&timeFrom=${from}&timeTo=${to}`,
      },
      COMPANY: {
        DAILY: (companyId, from, to) =>
          `/api/v1/alertsStats/stats/daily?companyId=${companyId}&timeFrom=${from}&timeTo=${to}`,
        HOURLY: (companyId, from, to) =>
          `/api/v1/alertsStats/stats/hourly?companyId=${companyId}&timeFrom=${from}&timeTo=${to}`,
      },
    },
  },
  ARTICLES_AND_CLICKS: {
    DATE_RANGE_ARTICLES_COUNT_PER_DAY:
      '/api/v1/articlesAndClicks/dateRangeArticlesCountPerDay',
    DATE_RANGE_UNIQUE_ARTICLES_COUNT_PER_DAY:
      '/api/v1/articlesAndClicks/dateRangeUniqueArticlesCountPerDay',
    CONTENT_PUBLICATIONS_WITH_ARTICLES_COUNT_PER_DAY:
      '/api/v1/articlesAndClicks/contentPublicationsWithArticlesCountPerDay',
    UNIQUE_ARTICLES_IN_PUBLICATIONS_COUNT_PER_DAY:
      '/api/v1/articlesAndClicks/uniqueArticlesInPublicationsCountPerDay',
    DATE_RANGE_USER_EVENTS_COUNT:
      '/api/v1/articlesAndClicks/dateRangeUserEventsCount',
  },
  ANALYSTS: '/api/v1/analysts',
  ANALYSTS_PUBLICATIONS: '/api/v1/analysts/publications_per_day',
  ARTICLE_AGGREGATES: '/api/v1/articleAggregates',
  ARTICLE_AGGREGATES_DISTINCT: '/api/v1/articleAggregates/distinct',
  ARTICLE_SOURCES_MATRIX: {
    STATS: '/api/v1/articleSourcesStats/stats',
    AVERAGE: '/api/v1/articleSourcesStats/average',
    ARTICLE_SOURCES: '/api/v1/articleSourcesStats/articleSources',
  },
  GET_PEERS_GRAPH_DATA: '/api/v1/companyRelations/graphData',
  BRIEFINGS: '/api/v1/contentContainers/briefingTypes',
  CONTENT_REPORTS: {
    ALL: '/api/v1/contentsAll',
    BASE: '/api/v1/content',
  },
  SUMMARY_REPORTS: {
    ALL: '/api/v1/summariesAll',
    BASE: '/api/v1/summary',
  },
  CLIENT_COVERAGES: '/api/v1/clientCoverages',
  CLIENT_COVERAGE_PREVIEW: (id) => `/api/v1/clientCoverage?id=${id}`,
  CLIENT_COVERAGES_ANALYSTS_LISTS: '/api/v1/clientCoveragesAnalystsLists',
  NEWSPAPERS: '/api/v1/newspapersAll',
  NEWSPAPER_ARTICLE_SOURCES: '/api/v1/newspapers/sourcesAll',
  NLA: {
    ALL: '/api/v1/nlaUsageAggregates',
    BASE: '/api/v1/nlaUsageAggregate',
    DELETE: '/api/v1/nlaUsageAggregate',
  },
  CONTENT_PUBLICATIONS: {
    ALL: '/api/v1/contentPublicationsAll',
    PREVIEW: (id) => `/api/v1/contentPublication?id=${id}`,
    LISTS: '/api/v1/contentPublicationContainersList',
  },
};
