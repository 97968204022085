import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import UserIcon from '@material-ui/icons/People';
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import ViewList from '@material-ui/icons/ViewList';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import GrainIcon from '@material-ui/icons/Grain';
import FolderIcon from '@material-ui/icons/Folder';
import GridOnIcon from '@material-ui/icons/GridOn';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { APP_ROUTES } from '../../_app/routes';
import SidebarHeader from '../components/navigation/SidebarHeader';
import UserInfoBox from '../components/navigation/UserInfoBox';
import UserActionsMenu from '../components/navigation/UserActionsMenu';
import { PAGE_LABELS } from '../../_app/constants';

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: 'flex',
    paddingBottom: '70px',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: '#fff',
    color: '#000',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  centerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    height: 'calc(100vh - 214px)',
    overflow: 'auto',
  },
});

@withStyles(styles)
@withTheme
@inject('authStore', 'userStore')
@observer
class DrawerLayout extends Component {
  @observable mobileOpen = false;

  componentDidMount() {
    this.props.userStore.fetchProfile();
  }

  renderDrawer = (classes, pathname, profile) => (
    <div>
      <div className={classes.toolbar}>
        <SidebarHeader />
      </div>
      <UserInfoBox profile={profile} />
      <Divider />
      <List classes={{ root: this.props.classes.list }}>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.DASHBOARD}
          selected={APP_ROUTES.DASHBOARD === pathname}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.USERS}
          selected={APP_ROUTES.USERS === pathname}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.SENTIMENTS}
          selected={APP_ROUTES.SENTIMENTS === pathname}
        >
          <ListItemIcon>
            <AssignmentTurnedInIcon />
          </ListItemIcon>
          <ListItemText primary="Sentiments" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.EMAILS}
          selected={APP_ROUTES.EMAILS === pathname}
        >
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="Emails" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.ARCHIVED_ARTICLES}
          selected={APP_ROUTES.ARCHIVED_ARTICLES === pathname}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Archived Articles" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.REPORTS}
          selected={APP_ROUTES.REPORTS === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.ALERTS_STATISTICS}
          selected={APP_ROUTES.ALERTS_STATISTICS === pathname}
        >
          <ListItemIcon>
            <NotificationImportantIcon />
          </ListItemIcon>
          <ListItemText primary="Alerts statistics" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.ARTICLES_AND_CLICKS}
          selected={APP_ROUTES.ARTICLES_AND_CLICKS === pathname}
        >
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          <ListItemText primary="Articles & Clicks" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.ANALYSTS_STATISTICS}
          selected={APP_ROUTES.ANALYSTS_STATISTICS === pathname}
        >
          <ListItemIcon>
            <RecentActorsIcon />
          </ListItemIcon>
          <ListItemText primary="Statistics for analysts" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.ARTICLE_AGGREGATES}
          selected={APP_ROUTES.ARTICLE_AGGREGATES === pathname}
        >
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary="Article aggregates" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.ARTICLE_SOURCES_MATRIX}
          selected={APP_ROUTES.ARTICLE_SOURCES_MATRIX === pathname}
        >
          <ListItemIcon>
            <GridOnIcon />
          </ListItemIcon>
          <ListItemText primary="Article sources matrix" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.NETWORK_GRAPH}
          selected={APP_ROUTES.NETWORK_GRAPH === pathname}
        >
          <ListItemIcon>
            <GrainIcon />
          </ListItemIcon>
          <ListItemText primary="Companies and peers" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.CONTENT_PUBLICATIONS}
          selected={APP_ROUTES.CONTENT_PUBLICATIONS === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Content publications" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.NLA}
          selected={APP_ROUTES.NLA === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Nla Usage Aggregates" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.CLIENT_COVERAGES}
          selected={APP_ROUTES.CLIENT_COVERAGES === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Client coverages" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.CONTENT_REPORTS}
          selected={APP_ROUTES.CONTENT_REPORTS === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Content reports" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.SUMMARY_REPORTS}
          selected={APP_ROUTES.SUMMARY_REPORTS === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Summary reports" />
        </MenuItem>
        <MenuItem
          button
          component={Link}
          to={APP_ROUTES.NEWSPAPERS}
          selected={APP_ROUTES.NEWSPAPERS === pathname}
        >
          <ListItemIcon>
            <ViewList />
          </ListItemIcon>
          <ListItemText primary="Newspapers" />
        </MenuItem>
      </List>
    </div>
  );
  render() {
    const {
      container,
      children,
      location: { pathname },
      classes,
      theme,
      userStore: { profile },
    } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.centerToolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={() => {
                this.mobileOpen = !this.mobileOpen;
              }}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {PAGE_LABELS[pathname]}
            </Typography>
            <UserActionsMenu onLogout={this.props.authStore.logout} />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="Mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.mobileOpen}
              onClose={() => {
                this.mobileOpen = !this.mobileOpen;
              }}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {this.renderDrawer(classes, pathname, profile)}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {this.renderDrawer(classes, pathname, profile)}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    );
  }
}

DrawerLayout.propTypes = {
  container: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object,
  theme: PropTypes.object,
  userStore: PropTypes.shape({
    profile: PropTypes.object,
    fetchProfile: PropTypes.func,
  }),
  authStore: PropTypes.shape({
    logout: PropTypes.func,
  }),
  location: PropTypes.object,
};

export default withRouter(DrawerLayout);
