import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import SignInForm from './components/forms/SignInForm';
import { APP_ROUTES } from '../_app/routes';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

@inject('authStore')
@withStyles(styles)
@observer
class SignIn extends Component {
  render() {
    if (
      process.env.REACT_APP_ENABLE_SSO === 'true' &&
      !this.props.authStore.isAuthenticated()
    ) {
      window.location.href = `${process.env.REACT_APP_SSO_URL}/?origin=${window.location.origin}/sign-in`;
      return null;
    }
    if (this.props.authStore.isAuthenticated()) {
      return <Redirect to={APP_ROUTES.DASHBOARD} />;
    }
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <SignInForm onSubmit={this.props.authStore.signIn} />
        </div>
      </Container>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object,
  authStore: PropTypes.shape({
    signIn: PropTypes.func,
    isAuthenticated: PropTypes.func,
  }),
};

export default SignIn;
