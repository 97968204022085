import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/mergeWith';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab';
import TextField from '../../../_common/components/forms/TextField';
import FormSelect from '../../../_common/components/forms/FormSelect';
import AutoSave from '../../../_common/components/forms/AutoSave';
import Loader from '../../../_common/components/Loader';
import { FiltersPaper } from '../../style';
import { ORDERS, STATUSES } from  '../../constants';


const ContentPublicationsForm = ({
  initialValues,
  onSubmit,
  fetchLists,
  listsFetched,
  containers,
  chooseContainer,
  formVisible,
  toggleFilters,
}) => {

  useEffect(() => {
    fetchLists();
  }, []);

  if (! listsFetched) {
    return (
      <Loader size={60} />
    );
  }

  const clearable = {status: ''};

  const containerOptions = [{label: 'Any', id: -1}].concat(containers.map(container => ({
    label: `${container.name} - ${container.c_name} - ${container.c_disabled ? 'OFF' : 'ON'}`,
    id: container.c_id,
  })));

  return (
    <Form
      onSubmit={(values) => onSubmit(merge(clearable, values) )}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      style={{ flexDirection: 'column' }}
      render={({ handleSubmit, values, form }) => (
        <FiltersPaper>
          <form id="ContentPublicationsForm" onSubmit={handleSubmit}>
            <AutoSave debounce={1000} save={handleSubmit} values={values} />
            <Flex mb={20} mx={-15} flexWrap="wrap">
              <Box px={15} width={[1, 1/2, 1/5]}>
                <Button
                  size="large"
                  variant={formVisible ? 'outlined' : 'contained'}
                  color="primary"
                  type="button"
                  onClick={toggleFilters}
                  fullWidth
                >
                  {formVisible ? 'Hide filters' : 'Open filters' }
                </Button>
              </Box>
              { formVisible && (
                <Box px={15} width={[1, 1/2, 1/5]}>
                  <Button
                    size="large"
                    color="secondary"
                    onClick={() => setTimeout(form.reset(), chooseContainer(-1))}
                    fullWidth
                  >
                    Clear filters
                  </Button>
                </Box>
              )}
            </Flex>
            { formVisible && (
              <>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/4]} px={15}>
                    <TextField
                      label="Creation date from"
                      type="date"
                      name="creation_date_from"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/4]} px={15}>
                    <TextField
                      label="Creation date to"
                      type="date"
                      name="creation_date_to"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/4]} px={15}>
                    <TextField
                      label="Publication date from"
                      type="date"
                      name="publication_date_from"
                    />
                  </Box>
                  <Box width={[1, 1/2, 1/4]} px={15}>
                    <TextField
                      label="Publication date to"
                      type="date"
                      name="publication_date_to"
                    />
                  </Box>
                </Flex>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1/2, 1/4]} px={15}>
                    <FormSelect options={ORDERS} name="order" label="Order" />
                  </Box>
                  <Box width={[1, 1/2, 1/4]} px={15}>
                    <FormSelect options={STATUSES} name="status" label="Status" />
                  </Box>
                  <Box width={[1, 1/2, 1/4]} px={15}>
                  <Autocomplete
                    disablePortal
                    name="container"
                    options={containerOptions}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Content container" name="container_input" />}
                    onChange={(event, newValue) => {
                      chooseContainer(newValue === null ? -1 : newValue.id);
                    }}
                    value="-1"
                  />
                  </Box>
                </Flex>
              </>
            )}
          </form>
        </FiltersPaper>
      )}
      />
  );
};

ContentPublicationsForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  fetchLists: PropTypes.func,
  listsFetched: PropTypes.bool,
  containers: PropTypes.array,
  formVisible: PropTypes.bool,
  toggleFilters: PropTypes.func,
  chooseContainer: PropTypes.func,
};

export default ContentPublicationsForm;