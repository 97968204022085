import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { DatePickersContainer } from './Containers';

const DatePickers = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  size = 'medium',
  dateRangeDays = 180,
  margin,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  return (
    <div style={{ width: '100%', margin }}>
      <DatePickersContainer>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ marginRight: 20, width: '100%' }}>
            <TextField
              style={{ width: '100%' }}
              label="From"
              variant="outlined"
              type="date"
              size={size}
              InputLabelProps={{ shrink: true }}
              defaultValue={startDate}
              onChange={(event) => {
                setShowAlert(
                  moment(endDate).diff(moment(event.target.value), 'days') >
                    dateRangeDays,
                );
                setStartDate(event.target.value);
              }}
            />
          </div>
          <div style={{ marginRight: 20, width: '100%' }}>
            <TextField
              style={{ width: '100%' }}
              label="To"
              variant="outlined"
              type="date"
              size={size}
              InputLabelProps={{ shrink: true }}
              defaultValue={endDate}
              onChange={(event) => {
                setShowAlert(
                  moment(endDate).diff(moment(event.target.value), 'days') >
                    dateRangeDays,
                );
                setEndDate(event.target.value);
              }}
            />
          </div>
        </div>
      </DatePickersContainer>
      {showAlert && (
        <Alert style={{ marginTop: 20, marginRight: 20 }} severity="error">
          Maximum date range is {dateRangeDays} days.
        </Alert>
      )}
    </div>
  );
};

export default DatePickers;

DatePickers.propTypes = {
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  size: PropTypes.string,
  margin: PropTypes.string,
  dateRangeDays: PropTypes.number,
};
