import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { validateDate } from '../../../utils/formValidators';
import TextField from '../../../_common/components/forms/TextField';
import AutoSave from '../../../_common/components/forms/AutoSave';
import ExcelDownload from '../ExcelDownload';
import TextFieldSearch from '../../../_common/components/forms/TextFieldSearch';

const useStyles = makeStyles(() => ({
  actionButton: {
    margin: '0 10px',
  },
}));

const excelSubscriptionsColumn = [{ id: "subscription_names", label: "Subcription names", excelWidth: 100 }]

const ReportsForm = ({
  onSubmit,
  initialValues,
  subscriptionDetails,
  columns,
  isLoadingSubscriptionDetails,
  subscriptionName,
  fetchSubscriptions,
}) => {
  const classes = useStyles();
  return (
    <Form
      onSubmit={(values) => onSubmit(values)}
      initialValues={initialValues}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({ handleSubmit, values, form, mutators }) => (
        <form id="ReportsForm" onSubmit={handleSubmit}>
          <AutoSave debounce={1000} save={handleSubmit} values={values} />
          <Flex mx={-15} flexWrap="wrap">
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextField
                label="From"
                type="date"
                name="dateFrom"
                validate={(value, allValues) =>
                  validateDate(value, allValues, { from: 'from', to: 'to' })
                }
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextField
                label="To"
                type="date"
                name="dateTo"
                validate={(value, allValues) =>
                  validateDate(value, allValues, { from: 'from', to: 'to' })
                }
              />
            </Box>
            <Box width={[1, 1 / 2, 1 / 3]} px={15}>
              <TextFieldSearch
                setValue={mutators.setValue}
                name="subscription"
                label="Subscription"
                myOnChange={fetchSubscriptions}
              />
            </Box>
          </Flex>
          <Flex width={1} justifyContent="flex-end">
            {!isLoadingSubscriptionDetails &&
              subscriptionDetails.length > 0 && (
                <ExcelDownload
                  data={subscriptionDetails}
                  columns={columns.concat(excelSubscriptionsColumn)}
                  fileName={`${subscriptionName} report`}
                  sheetName={subscriptionName}
                />
              )}
            <Button
              className={classes.actionButton}
              size="large"
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => form.reset()}
            >
              Clear
            </Button>
          </Flex>
        </form>
      )}
    />
  );
};

ReportsForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  columns: PropTypes.array,
  subscriptionDetails: PropTypes.array,
  isLoadingSubscriptionDetails: PropTypes.bool,
  subscriptionName: PropTypes.string,
  fetchSubscriptions: PropTypes.func,
};

export default ReportsForm;
