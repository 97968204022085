import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import PropTypes from 'prop-types';
import moment from 'moment';

import CustomXAxisTick from './CustomXAxisTick';
import CustomTooltip from './CustomTooltip';

const CustomLineChart = ({
  data,
  xAxisKey,
  keys,
  dot = false,
  connectNulls = false,
}) => (
  <ResponsiveContainer width="100%" height={400}>
    <LineChart data={data} margin={{ left: -30 }}>
      <CartesianGrid strokeDasharray="4 3" stroke="rgba(0,0,0,.1)" />
      <XAxis
        dataKey={xAxisKey}
        tick={<CustomXAxisTick dateFormat="DD/MM/YYYY hh:mm" />}
      />
      <YAxis labelFormatter={(v) => moment(v).format('DD')} />
      <Tooltip content={<CustomTooltip type="hourly" />} />
      {keys.map(
        ({ key, name, color, show }) =>
          show && (
            <Line
              connectNulls={connectNulls}
              dot={dot}
              key={`line-${key}`}
              dataKey={key}
              name={name || key}
              stroke={color}
            />
          ),
      )}
    </LineChart>
  </ResponsiveContainer>
);

export default CustomLineChart;

CustomLineChart.propTypes = {
  data: PropTypes.array,
  xAxisKey: PropTypes.string,
  keys: PropTypes.array,
  dot: PropTypes.bool,
  connectNulls: PropTypes.bool,
};
