import axios from 'axios';
import Cookie from 'js-cookie';
import { TOKEN_KEY } from './constants';
import { authStore } from '../stores';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const ERROR_CODES = [400, 401];

const AUTH_TOKEN = localStorage.getItem(TOKEN_KEY);
if (AUTH_TOKEN) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (ERROR_CODES.indexOf(error.response.status) > -1) {
      authStore.logout();
    }
    if (error.response.status === 403) {
      if (process.env.REACT_APP_ENABLE_SSO === 'true') {
        window.location.href = `${process.env.REACT_APP_SSO_URL}/forbidden`;
      }
    }
    return Promise.reject(error.response.data);
  },
);

export function setApiHeaders(analystToken, analystEmail) {
  const suffix = process.env.REACT_APP_COOKIE_SUFFIX;

  Cookie.set(`analyst_email${suffix}`, analystEmail, {
    domain: '.briefcase.news',
  });

  Cookie.set(`analyst_token${suffix}`, analystToken, {
    domain: '.briefcase.news',
  });

  if (axiosInstance) {
    axiosInstance.defaults.headers.common = {
      'X-ANALYST-EMAIL': analystEmail,
      'X-ANALYST-TOKEN': analystToken,
    };
  }
}

export default axiosInstance;
