import PropTypes from 'prop-types';
import React from 'react';
import { HeatMapGrid } from 'react-grid-heatmap';
import ReactTooltip from 'react-tooltip';
import { MatrixContainer, MatrixLabel } from './Containers';
import HeatMapLegend from './HeatMapLegend';

export const HOURS = 24;

const BaseHeatMap = ({ data, colorsForCells, label, isLegend }) => {
  const yLabels = new Array(HOURS)
    .fill(0)
    .map((_, idx) => (idx < 10 ? `0${idx}` : `${idx}`));

  const xLabels = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const tooltipLabels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  return (
    <MatrixContainer>
      <ReactTooltip />
      <MatrixLabel>{label}</MatrixLabel>
      <HeatMapGrid
        data={data}
        xLabels={xLabels}
        yLabels={yLabels}
        cellRender={(x, y, value) => (
          <div
            style={{ color: 'transparent' }}
            data-tip={`${tooltipLabels[y]} (${x}): ${value}`}
          >
            O
          </div>
        )}
        cellHeight="1rem"
        square
        xLabelsStyle={() => ({
          margin: '0 0.125rem',
        })}
        yLabelsStyle={() => ({
          margin: '0.125rem 0',
        })}
        cellStyle={(x, y) => ({
          borderStyle: 'solid',
          borderWidth: '0.0625rem',
          borderColor: 'rgb(119, 119, 119, 0.5)',
          background: colorsForCells(x, y),
          margin: '0.125rem',
        })}
      />
      {isLegend && <HeatMapLegend />}
    </MatrixContainer>
  );
};

export default BaseHeatMap;

BaseHeatMap.propTypes = {
  colorsForCells: PropTypes.func,
  isLegend: PropTypes.bool,
  label: PropTypes.string,
  data: PropTypes.any,
};
