import React, { Component } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { APP_ROUTES } from '../_app/routes';

@withRouter
@inject('authStore')
@observer
class MagicLink extends Component {
  componentDidMount() {
    const { isAuthenticated, magicLinkSignIn } = this.props.authStore;
    const {
      history: { push },
      location: { search },
    } = this.props;
    const { token } = queryString.parse(search);
    if (isAuthenticated) {
      return push(APP_ROUTES.DASHBOARD);
    }
    if (token) {
      magicLinkSignIn(token);
      return push(APP_ROUTES.DASHBOARD);
    }
    return push(APP_ROUTES.SIGN_IN);
  }
  render() {
    return null;
  }
}

MagicLink.wrappedComponent.propTypes = {
  authStore: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    magicLinkSignIn: PropTypes.func,
  }),
  history: PropTypes.object,
  location: PropTypes.object,
};

export default MagicLink;
