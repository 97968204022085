import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  ChartContainer,
  CountersContainer,
} from '../../_common/components/Containers';
import CustomLineChart from '../components/CustomLineChart';
import Counters from '../components/Counters';
import CustomRangeExport from '../components/CustomRangeExport';
import Loader from '../../_common/components/Loader';

const excelColumns = [
  {
    id: 'date',
    title: 'Date',
    width: 100,
  },
  {
    id: 'eventsCount',
    title: 'Events count',
    width: 100,
  },
  {
    id: 'publicationsCount',
    title: 'Publications Count',
    width: 120,
  },
];

const UniqueUsersTab = ({ articlesAndClicksStore }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD').toString(),
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString(),
  );

  const isDateDiffLessThen180 =
    moment(endDate).diff(moment(startDate), 'days') <= 180;
  const isDateDiffPositive =
    moment(endDate).diff(moment(startDate), 'days') > 0;

  const isMounted = useRef(false);

  const {
    fetchUniqueCounts,
    chartData,
    isLoading,
    articleViews,
    articlePublications,
    fetchExcelUniqueCounts,
    isLoadingExcelData,
    excelData,
  } = articlesAndClicksStore;

  useEffect(() => {
    if (isMounted.current && isDateDiffLessThen180 && isDateDiffPositive) {
      fetchExcelUniqueCounts(startDate, endDate, 'user_email');
    }
  }, [startDate, endDate]);

  useEffect(() => {
    isMounted.current = true;
    fetchUniqueCounts(moment().subtract(180, 'days'), 'user_email');
    fetchExcelUniqueCounts(startDate, endDate, 'user_email');
  }, []);

  const keys = [
    {
      key: 'eventsCount',
      label: 'Unique users that viewed articles',
      color: '#000000',
      show: true,
    },
    {
      key: 'publicationsCount',
      label: 'Number of unique articles in publications',
      color: '#A14515',
      show: true,
    },
    { key: 'percentage', color: '#123456', show: false },
  ];

  if (isLoading) {
    return <Loader size={60} />;
  }

  return (
    <div>
      <ChartContainer>
        <CustomLineChart data={chartData} keys={keys} xAxisKey="date" />
      </ChartContainer>
      <CountersContainer>
        <Counters
          isLoading={false}
          articleViews={articleViews}
          articlePublications={articlePublications}
        />
      </CountersContainer>
      <CustomRangeExport
        columns={excelColumns}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        disableButton={!isDateDiffLessThen180 || !isDateDiffPositive}
        isLoading={isLoadingExcelData}
        data={excelData}
        fileName="Unique users totals"
        sheetName="Unique users totals"
      />
    </div>
  );
};

UniqueUsersTab.propTypes = {
  articlesAndClicksStore: PropTypes.shape({
    fetchUniqueCounts: PropTypes.func,
    chartData: PropTypes.array,
    isLoading: PropTypes.bool,
    articleViews: PropTypes.any,
    articlePublications: PropTypes.any,
    excelData: PropTypes.array,
    fetchExcelUniqueCounts: PropTypes.func,
    isLoadingExcelData: PropTypes.bool,
  }),
};
export default inject('articlesAndClicksStore')(observer(UniqueUsersTab));
