import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function DeleteModal({ isOpen, setClose, handleDelete }) {
  return (
    <Box>
      <Dialog onClose={setClose} aria-labelledby="deleteModal" open={isOpen}>
        <DialogContent sx={{ width: '500px' }}>
          <Typography>
            Are you sure that you want to delete this element?
          </Typography>
          <Box sx={{ display: 'flex', direction: 'row', mt: '30px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDelete}
              fullWidth
            >
              Yes
            </Button>
            <Button color="secondary" onClick={setClose} fullWidth>
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  setClose: PropTypes.func,
  handleDelete: PropTypes.func,
};
export default DeleteModal;
