import { action, observable } from 'mobx';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class SentimentsStore {
  @observable sentiments = [];
  @observable error = null;
  @observable pagesTotal = 0;
  @observable itemsTotal = 0;

  @action fetchSentiments = async (searchQuery) => {
    this.isLoading = true;
    this.sentiments = [];
    try {
      const {
        data: { data, pagesTotal, itemsTotal },
      } = await API.get(`${API_ROUTES.SENTIMENTS}${searchQuery}`);
      this.sentiments = data;
      this.pagesTotal = pagesTotal;
      this.itemsTotal = itemsTotal;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };
}

export default new SentimentsStore();
