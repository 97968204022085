import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { inject, observer } from 'mobx-react';
import Pagination from 'material-ui-flat-pagination';
import { Box, Flex, Text } from 'rebass';
import moment from 'moment';
import EmailsForm from './components/forms/EmailsForm';
import { APP_ROUTES } from '../_app/routes';
import EmailsList from './components/EmailsList';
import MyChart from '../_common/components/BarChart';
import Loader from '../_common/components/Loader';
import styles from '../theme';
import StatsCounters from './components/StatsCounters';

const DEFAULT_LIMIT = 20;

const DEFAULT_QUERY_PARAMS = {
  limit: DEFAULT_LIMIT,
  page: 1,
  from: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  order: 'desc',
};

@inject('emailsStore')
@observer
class Emails extends Component {
  componentDidMount() {
    const {
      location: { search },
      emailsStore: {
        fetchEmails,
        fetchEmailsCountsPerDay,
        fetchEventsCounters,
      },
    } = this.props;
    if (isEmpty(search)) {
      this.onSubmit(this.initialValues());
    }
    if (search === `?${queryString.stringify(this.initialValues())}`) {
      fetchEmails(search);
      fetchEventsCounters(search);
      fetchEmailsCountsPerDay(search);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (isEmpty(search)) {
      return this.onSubmit(this.initialValues());
    }
    if (prevProps.location.search !== search) {
      this.props.emailsStore.fetchEmails(search);
      this.props.emailsStore.fetchEventsCounters(search);
      this.props.emailsStore.fetchEmailsCountsPerDay(search);
    }
  }

  onSubmit = (values) =>
    this.props.history.push({
      pathname: APP_ROUTES.EMAILS,
      search: queryString.stringify(values),
    });

  initialValues = () =>
    merge(DEFAULT_QUERY_PARAMS, queryString.parse(this.props.location.search));

  render() {
    const {
      isLoading,
      emails,
      pagesTotal,
      itemsTotal,
      barChartData,
      formData,
      eventsCounters,
      isLoadingEventsCounters,
    } = this.props.emailsStore;
    const {
      page,
      limit = DEFAULT_LIMIT,
      ...restQuery
    } = queryString.parse(this.props.location.search);

    return (
      <>
        <MyChart data={barChartData} />
        <EmailsForm
          onSubmit={this.onSubmit}
          initialValues={this.initialValues()}
          formData={formData}
        />
        {isLoading && <Loader size={60} />}
        <StatsCounters
          {...eventsCounters}
          isLoading={isLoadingEventsCounters}
        />
        <EmailsList emails={emails} />
        <Flex width={1} pt={25} justifyContent="center">
          <Box>
            <Text color={styles.colors.mainPurple} fontSize={15}>
              Items {limit * (page - 1) + 1}
              {'-'}
              {emails.length + limit * (page - 1)} of {itemsTotal}
            </Text>
          </Box>
        </Flex>
        <Flex width={1} pt={10} pb={15} justifyContent="center">
          <Pagination
            limit={limit}
            offset={limit * (page - 1)}
            total={(pagesTotal || page) * limit}
            onClick={(e, offset, pageNum) =>
              this.onSubmit({ ...restQuery, page: pageNum, limit })
            }
          />
        </Flex>
      </>
    );
  }
}

Emails.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  emailsStore: PropTypes.shape({
    fetchEmails: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingCounters: PropTypes.bool,
    emails: PropTypes.array,
    pagesTotal: PropTypes.number,
    itemsTotal: PropTypes.number,
    barChartData: PropTypes.array,
    fetchEmailsCountsPerDay: PropTypes.func,
    formData: PropTypes.object,
    eventsCounters: PropTypes.object,
    isLoadingEventsCounters: PropTypes.bool,
    fetchEventsCounters: PropTypes.func,
  }),
};

export default Emails;
