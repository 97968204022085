import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Loader from '../_common/components/Loader';
import ReactSelect from '../_common/components/ReactSelect';
import DatePickers from '../_common/components/DatePickers';
import { ChartContainer } from '../_common/components/Containers';
import CustomLineChart from '../articlesAndClicks/components/CustomLineChart';

const types = [
  { label: 'Authors', value: 'author' },
  { label: 'Article sources', value: 'article_source' },
];

export const chartColors = [
  '#d50000',
  '#5c5c5c',
  '#d3651b',
  '#b413ef',
  '#25545c',
  '#00b9fc',
  '#d1a104',
  '#3c8b09',
  '#470d99',
  '#64e219',
  '#020202',
  '#ef26c0',
  '#131ad8',
  '#6732bc',
  '#f4586a',
  '#358efc',
  '#7d7d7d',
  '#820f19',
  '#07845f',
  '#e3d730',
];

const ArticleAggregates = ({ articleAggregates }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(365, 'days').format('YYYY-MM-DD').toString(),
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString(),
  );
  const [type, setType] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const isDateDiffLessThen180 =
    moment(endDate).diff(moment(startDate), 'days') <= 365;
  const isDateDiffPositive =
    moment(endDate).diff(moment(startDate), 'days') > 0;

  const isMounted = useRef(false);

  const {
    fetchDistinct,
    fetchAggregates,
    isLoading,
    isLoadingKeys,
    chartData,
    keys,
  } = articleAggregates;

  useEffect(() => {
    if (isMounted.current) {
      if (selectedKeys.length > 0 && type && !isLoadingKeys) {
        fetchAggregates(
          startDate,
          endDate,
          type,
          selectedKeys.map((value) => value.label),
        );
      }
    }
  }, [selectedKeys, type]);

  useEffect(() => {
    if (
      isMounted.current &&
      isDateDiffLessThen180 &&
      isDateDiffPositive &&
      type &&
      selectedKeys.length > 0 &&
      !isLoadingKeys
    ) {
      fetchAggregates(
        startDate,
        endDate,
        type,
        selectedKeys.map((value) => value.label),
      );
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      isMounted.current &&
      isDateDiffLessThen180 &&
      isDateDiffPositive &&
      type
    ) {
      setSelectedKeys([]);
      fetchDistinct(startDate, endDate, type);
    }
  }, [type]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const lines = selectedKeys.map((key, index) => ({
    key: key.label,
    label: key.label,
    show: true,
    color: chartColors[index],
  }));

  return (
    <div style={{ marginBottom: 100 }}>
      {(isLoading || isLoadingKeys) && <Loader size={60} />}
      {!isLoadingKeys && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <ReactSelect
              onChange={(newValue) => setType(newValue)}
              value={type}
              options={types}
              width="250px"
            />
            {keys.length > 0 && (
              <ReactSelect
                isMulti
                value={selectedKeys}
                onChange={setSelectedKeys}
                options={keys}
                width="100%"
              />
            )}
          </div>
          <div style={{ display: 'flex', marginLeft: 30 }}>
            <DatePickers
              margin="19px 0 0 0"
              dateRangeDays={365}
              size="small"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>
        </div>
      )}
      {!isLoading && type && selectedKeys.length > 0 && (
        <>
          <h3>
            Percentage of used articles in coverages per month for chosen{' '}
            {type.replace('_', ' ')}s
          </h3>
          <div>
            <ChartContainer>
              <CustomLineChart
                data={chartData}
                keys={lines}
                xAxisKey="date"
                dot
                connectNulls
                formatter={(value) => `${value}%`}
              />
            </ChartContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default inject('articleAggregates')(observer(ArticleAggregates));

ArticleAggregates.propTypes = {
  articleAggregates: PropTypes.shape({
    fetchDistinct: PropTypes.func,
    fetchAggregates: PropTypes.func,
    isLoading: PropTypes.bool,
    isLoadingKeys: PropTypes.bool,
    chartData: PropTypes.array,
    keys: PropTypes.array,
  }),
};
