import moment from 'moment';
import { API_ROUTES } from './routes';
import API from './api';

export const fetchEventPromptsEmail = async (userEmail) => {
  let result = [];
  try {
    const data = await API.get(API_ROUTES.EVENT_EMAILS(userEmail));
    if (data && data.data) {
      result = data.data.slice(0, 5);
    }
  } catch (e) {
    console.log(e);
  }
  return result;
};

export const fetchEventPromptsSubscription = async (subscription) => {
  let result = [];
  try {
    const data = await API.get(API_ROUTES.EVENT_SUBSCRIPTIONS(subscription));
    if (data && data.data) {
      result = data.data.slice(0, 5);
    }
  } catch (e) {
    console.log(e);
  }
  return result;
};

export const fetchCompanies = async (company) => {
  let result = [];
  try {
    const response = await API.get(API_ROUTES.COMPANIES(company));
    if (response && response.data.companies) {
      result = response.data.companies.slice(0, 5);
    }
  } catch (e) {
    console.log(e);
  }
  return result;
};

export const fetchArticleSources = async (articleSource) => {
  let result = [];
  try {
    const response = await API.get(API_ROUTES.ARTICLE_SOURCES(articleSource));
    if (response && response.data.article_sources) {
      result = response.data.article_sources
        .map(({ name }) => name)
        .slice(0, 5);
    }
  } catch (e) {
    console.log(e);
  }
  return result;
};

export const getDatesFromTimeframe = (timeframe) => {
  let startDate;
  let endDate;

  switch (timeframe) {
    case 'week':
      startDate = moment().startOf('isoWeek');
      endDate = moment().endOf('isoWeek');
      break;
    case 'last_week':
      startDate = moment().subtract(1, 'w').startOf('isoWeek');
      endDate = moment().subtract(1, 'w').endOf('isoWeek');
      break;
    case 'month':
      startDate = moment().subtract(4, 'w').startOf('isoWeek');
      endDate = moment().subtract(1, 'w').endOf('isoWeek');
      break;
    default:
      startDate = moment().isoWeekday(-6);
      endDate = startDate.endOf('isoWeek');
      break;
  }

  return {
    startDate,
    endDate,
  };
};

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
