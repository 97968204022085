/* eslint-disable no-unused-vars */
import { stringify } from 'query-string';
import { action, observable } from 'mobx';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class SummaryReportsStore {
  @observable isLoadingSummaryReport = false;

  @observable isLoadingTable = false;

  @observable isLoadingBrefingOptions = false;

  @observable briefingOptions = [];

  @observable tableData = [];

  @observable totalRowsNumber = 0;

  @observable summaryReport;

  @observable pageSize = 10;

  @observable page = 0;

  @action fetchBriefingOptions = async () => {
    this.isLoadingBrefingOptions = true;
    try {
      const response = await API.get(API_ROUTES.BRIEFINGS);

      this.briefingOptions = response.data.data.briefing_types.map(
        ({ id, name }) => ({
          label: name,
          value: id,
        }),
      );
      this.isLoadingBrefingOptions = false;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingBrefingOptions = false;
    }
  };

  @action fetchTable = async ({
    briefingId,
    creationFrom,
    creationTo,
    publicationTo,
    publicationFrom,
    page,
    pageSize,
  }) => {
    this.isLoadingTable = true;
    try {
      const query = {
        briefing_type_id: briefingId,
        created_at_from: creationFrom,
        created_at_to: creationTo,
        published_at_from: publicationFrom,
        published_at_to: publicationTo,
        limit: parseInt(pageSize.toString(), 10),
        skip: parseInt((page * pageSize).toString(), 10),
      };
      const response = await API.get(
        `${API_ROUTES.SUMMARY_REPORTS.ALL}?${stringify(query)}`,
      );

      this.tableData = response.data.content_publications;
      this.totalRowsNumber = response.data.content_publications_count;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingTable = false;
    }
  };

  @action fetchSummaryReport = async ({ id }) => {
    this.isLoadingSummaryReport = true;
    try {
      const response = await API.get(
        `${API_ROUTES.SUMMARY_REPORTS.BASE}?id=${id}`,
      );

      this.summaryReport = response.data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingSummaryReport = false;
    }
  };
}

export default new SummaryReportsStore();
