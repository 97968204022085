import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { inject, observer, PropTypes } from 'mobx-react';
import TablePagination from '@material-ui/core/TablePagination';
import Loader from '../../_common/components/Loader';

const columns = [
  {
    id: 'name',
    label: 'Company',
    align: 'left',
  },
  {
    id: 'sector_name',
    label: 'Sector',
    align: 'left',
  },
  {
    id: 'meta',
    meta: 'company_notes',
    label: 'Notes',
    align: 'left',
  },
];

const TableTab = ({
  companiesRelationsStore: {
    tableData,
    fetchTable,
    isLoadingTable,
    totalRowsNumber,
  },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchTable({ limit: rowsPerPage, page: 0 });
  }, []);

  const handleChangePage = async (_event, newPage) => {
    setPage(newPage);
    await fetchTable({ limit: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    await fetchTable({ limit: event.target.value, page: 0 });
  };

  return (
    <>
      {isLoadingTable && <Loader size={60} />}
      {!isLoadingTable && (
        <Paper style={{ width: '100%', marginTop: '16px' }}>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  tableData.map((row) => (
                    <TableRow
                      key={`row-${row.company_name}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];
                        if (column.id === 'meta') {
                          value = row[column.id][column.meta];
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalRowsNumber}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
};

export default inject('companiesRelationsStore')(observer(TableTab));

TableTab.propTypes = {
  companiesRelationsStore: {
    table: PropTypes.any,
    fetchTable: PropTypes.func,
    isLoadingTable: PropTypes.boolean,
    responseCount: PropTypes.number,
  },
};
