import React, { useEffect } from 'react';
import ReactPropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import ReactTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { APP_ROUTES } from '../../_app/routes';
import { datePrettier } from '../../utils/text';
import Loader from '../../_common/components/Loader';

const LABELS_MAP = {
  id: 'Id',
  content: 'Content',
  content_container: 'Content container',
  publication_type: 'Publication type',
  status: 'Status',
  position: 'Position',
  published_at: 'Published at',
  url: 'Url',
  article: 'Article',
  analyst: 'Analyst',
  url_published: 'Url published',
  publisher: 'Published by',
  editor: 'Edited by',
  article_source: 'Article source',
  created_at: 'Created by',
  updated_at: 'Updated by',
  read_more_url: 'Read more url',
  nla_link: 'NLA link',
  briefing_section: 'Briefing section',
  sentiment: 'Sentiment',
  spokesperson_mention: 'Spokeperson mention',
  headline_mention: 'Headling mention',
};
const BLOCKED_FIELDS = ['status'];

function PreviewSummary({
  summaryReportsStore: {
    isLoadingSummaryReport,
    summaryReport,
    fetchSummaryReport,
  },
}) {
  const { id } = useParams();

  const valueRenderer = (key, value) => {
    if (
      [
        'headline_mention',
        'spokesperson_mention',
        'nla_link',
        'url_published',
      ].includes(key)
    ) {
      return (
        <Box
          sx={{
            backgroundColor: 'grey',
            display: 'flex',
            padding: '5px',
            color: 'white',
            width: 'min-content',
            fontWeight: 600,
          }}
        >
          {value ? 'YES' : 'NO'}
        </Box>
      );
    }
    if (['created_at', 'updated_at', 'published_at'].includes(key)) {
      return datePrettier(value);
    }
    return value;
  };

  useEffect(() => {
    fetchSummaryReport({ id });
  }, []);

  if (isLoadingSummaryReport) return <Loader size={60} />;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          to={APP_ROUTES.SUMMARY_REPORTS}
          style={{ textDecoration: 'none', color: 'grey' }}
        >
          Summary reports
        </Link>
        <Typography
          color="inherit"
          style={{ textDecoration: 'none', color: 'grey' }}
        >
          {summaryReport?.article || 'Loading...'}
        </Typography>
      </Breadcrumbs>

      <Paper style={{ marginTop: '40px' }}>
        <ReactTable>
          <TableBody>
            {summaryReport &&
              Object.entries(summaryReport)
                .filter((x) => !BLOCKED_FIELDS.includes(x[0]))
                .map(([key, value]) => (
                  <TableRow key={`row-${key}`}>
                    <TableCell style={{ width: '200px' }}>
                      {[LABELS_MAP[key]]}
                    </TableCell>
                    <TableCell>{valueRenderer(key, value)}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </ReactTable>
      </Paper>
    </>
  );
}

PreviewSummary.propTypes = {
  summaryReportsStore: ReactPropTypes.shape({
    summaryReport: MobXPropTypes.any,
    isLoadingSummaryReport: MobXPropTypes.bool,
    fetchSummaryReport: MobXPropTypes.func,
  }),
};

export default inject('summaryReportsStore')(observer(PreviewSummary));
