import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

export const ReportTableCell = styled(TableCell)`
  font-size: 12px;
  white-space: nowrap;
  max-width: 22em;
  overflow: hidden;
  text-overflow: ellipsis;

  &.link {
    color: #3f51b5;
    cursor: pointer;
  }

  &.link:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;