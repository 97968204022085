import { action, observable } from 'mobx';
import queryString from 'query-string';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import join from 'lodash/join';
import find from 'lodash/find';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import { routerStore } from './index';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';
import API from '../_app/api';
import { TIMEFRAME_OPTIONS } from '../_app/constants';
import { getDatesFromTimeframe, getRandomColor } from '../_app/utils';

export class AlertsStatisticsStore {
  @observable isLoading = false;

  @observable isLoadingCompanies = false;

  @observable isLoadingSectors = false;

  @observable companies = [];

  @observable sectors = [];

  @observable selectedCompanyIds = null;

  @observable selectedCompanyName = null;

  @observable selectedTimeframe = null;

  @observable selectedSector = null;

  @observable barChartData = [];

  @observable lineChartData = [];

  @observable barChartLoading = false;

  @observable lineChartLoading = false;

  @observable timeframeOptions = TIMEFRAME_OPTIONS;

  @action fetchCompanies = async () => {
    try {
      const [companies, companiesColors] = await Promise.all([
        (async () => {
          const {
            data: { data },
          } = await API.get(`${API_ROUTES.ALERTS_STATISTICS.COMPANIES}`);
          return data;
        })(),
        (async () => {
          const {
            data: { data },
          } = await API.get(`${API_ROUTES.ALERTS_STATISTICS.COMPANIES_COLORS}`);
          return data?.colors || {};
        })(),
      ]);

      this.companies = map(sortBy(companies, 'name'), (c) => ({
        ...c,
        color: companiesColors[c.id] || getRandomColor(),
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  @action fetchSectors = async () => {
    try {
      const {
        data: { data },
      } = await API.get(`${API_ROUTES.ALERTS_STATISTICS.SECTORS}`);

      this.sectors = data;
    } catch (error) {
      console.log({ error });
    }
  };

  @action fetchDailyData = async (target) => {
    this.barChartLoading = true;
    try {
      const { startDate, endDate } = getDatesFromTimeframe(
        this.selectedTimeframe,
      );
      const fetchData = async (companyOrSectorId) => {
        const {
          data: { data },
        } = await API.get(
          `${API_ROUTES.ALERTS_STATISTICS.CHARTS[target].DAILY(
            companyOrSectorId,
            startDate,
            endDate,
          )}`,
        );
        return data;
      };
      if (target === 'COMPANY') {
        const chartData = await Promise.all(
          map(this.selectedCompanyIds, fetchData),
        );
        this.barChartData = this.prepapreCombinedCompaniesChartData(chartData);
      } else {
        this.barChartData = await fetchData(this.selectedSectorId);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      this.barChartLoading = false;
    }
  };

  @action fetchHourlyData = async (target) => {
    this.lineChartLoading = true;
    try {
      const { startDate, endDate } = getDatesFromTimeframe(
        this.selectedTimeframe,
      );

      const fetchData = async (companyOrSectorId) => {
        const {
          data: { data },
        } = await API.get(
          `${API_ROUTES.ALERTS_STATISTICS.CHARTS[target].HOURLY(
            companyOrSectorId,
            startDate,
            endDate,
          )}`,
        );
        return data;
      };

      if (target === 'COMPANY') {
        const chartData = await Promise.all(
          map(this.selectedCompanyIds, fetchData),
        );
        this.lineChartData = this.prepapreCombinedCompaniesChartData(chartData);
      } else {
        this.lineChartData = await fetchData(this.selectedSectorId);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      this.lineChartLoading = false;
    }
  };

  prepapreCombinedCompaniesChartData = (dataByCompanies) => {
    dataByCompanies = reject(dataByCompanies, isEmpty);
    const flattenedData = flatten(dataByCompanies);
    const companies = map(dataByCompanies, (companyData) => {
      const { companyId } = companyData[0] || {};
      const { name: companyName, color } =
        find(this.companies, (c) => c.id === companyId) || {};
      return { companyId, companyName, color };
    });

    const groupedByTimeFrom = groupBy(flattenedData, 'timeFrom');
    const data = map(keys(groupedByTimeFrom), (timeFrom) => {
      const decoratedData = { timeFrom };
      // create chart object for each company
      forEach(companies, ({ companyName, companyId, color }) => {
        const chartData = find(
          groupedByTimeFrom[timeFrom],
          (c) => c.companyId === companyId,
        );
        decoratedData[`company${companyId}`] = {
          companyName,
          numberOfAlerts: chartData?.numberOfAlerts || 0,
          color,
        };
        if (chartData) {
          decoratedData.timeTo = chartData.timeTo;
        }
      });
      return decoratedData;
    });
    return sortBy(data, 'timeFrom');
  };

  @action prepareCompanyStatistics = async (searchString) => {
    this.isLoadingCompanies = true;

    try {
      const { companies, timeframe } = queryString.parse(searchString);

      if (!this.companies.length) {
        await this.fetchCompanies();
      }

      if (!companies) {
        this.resetCompanyStatistics();
        return;
      }

      const selectedCompanies = map(
        (companies || '').toString().split(','),
        Number,
      );

      const selectedTimeframe = timeframe || this.timeframeOptions[0].value;

      if (!companies || !timeframe) {
        this.filtersChange({
          companies: join(selectedCompanies, ','),
          timeframe: selectedTimeframe,
        });
      } else {
        this.selectedCompanyIds = selectedCompanies;
        this.selectedTimeframe = selectedTimeframe;
      }

      const currentCompanies = this.companies.filter((c) =>
        selectedCompanies.includes(c.id),
      );
      this.selectedCompanyNames = currentCompanies
        .map((c) => c.name)
        .join(', ');

      this.fetchDailyData('COMPANY');
      this.fetchHourlyData('COMPANY');
    } catch (e) {
      console.log(e.message);
    } finally {
      this.isLoadingCompanies = false;
    }
  };

  @action prepareSectorStatistics = async (searchString) => {
    this.isLoadingSectors = true;

    try {
      const { sector, timeframe } = queryString.parse(searchString, {
        parseNumbers: true,
      });

      if (!this.sectors.length) {
        await this.fetchSectors();
      }

      const selectedSector = sector || this.sectors[0].id;
      const selectedTimeframe = timeframe || this.timeframeOptions[0].value;

      if (!sector || !timeframe) {
        this.filtersChange({
          sector: selectedSector,
          timeframe: selectedTimeframe,
        });
      } else {
        this.selectedSectorId = selectedSector;
        this.selectedTimeframe = selectedTimeframe;
      }

      const currentSector = this.sectors.find((c) => c.id === sector);
      this.selectedSectorName = currentSector.name;

      this.fetchDailyData('SECTOR');
      this.fetchHourlyData('SECTOR');
    } catch (e) {
      console.log(e.message);
    } finally {
      this.isLoadingCompanies = false;
    }
  };

  @action filtersChange = (filters) => {
    routerStore.replace(
      `${APP_ROUTES.ALERTS_STATISTICS}?${queryString.stringify(filters)}`,
    );

    // fetch new data here

    // routerStore.replace(company.value);
  };

  @action resetCompanyStatistics = () => {
    this.selectedTimeframe = null;
    this.selectedCompanyIds = null;
    this.selectedCompanyNames = '';
    this.barChartData = [];
    this.lineChartData = [];
    routerStore.replace(APP_ROUTES.ALERTS_STATISTICS);
  };

  @action resetSectorStatistics = () => {
    this.selectedTimeframe = null;
    this.selectedSectorId = null;
    this.barChartData = [];
    this.lineChartData = [];
    routerStore.replace(APP_ROUTES.ALERTS_STATISTICS);
  };
}

export default new AlertsStatisticsStore();
