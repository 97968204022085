import { action, observable } from 'mobx';
import { stringify } from 'query-string';
import API from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class CompaniesRelationsStore {
  @observable isLoading = false;

  @observable isLoadingTable = false;

  @observable nodes = [];

  @observable edges = [];

  @observable tableData = [];

  @observable totalRowsNumber = 0;

  @action fetchGraph = async () => {
    this.isLoading = true;
    try {
      const {
        data: {data: { nodes, edges } }
      } = await API.get(API_ROUTES.GET_PEERS_GRAPH_DATA);
      this.nodes = nodes;
      this.edges = edges;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchTable = async ({ limit, page }) => {
    this.isLoadingTable = true;
    try {
      const query = {
        limit: parseInt(limit ? limit.toString() : 10, 10),
        skip: parseInt(page ? (page * limit).toString() : 0, 10),
        company: null,
      };
      const response = await API.get(
        `${API_ROUTES.GET_ALL_COMPANIES}?${stringify(query)}`,
      );
      this.tableData = response.data.companies
      this.totalRowsNumber = response.data.companiesCount;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoadingTable = false;
    }
  };
}

export default new CompaniesRelationsStore();
