import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CustomLineChart from '../components/CustomLineChart';
import Counters from '../components/Counters';
import {
  ChartContainer,
  CountersContainer,
} from '../../_common/components/Containers';
import CustomRangeExport from '../components/CustomRangeExport';
import Loader from '../../_common/components/Loader';

const excelColumns = [
  {
    id: 'date',
    title: 'Date',
    width: 100,
  },
  {
    id: 'eventsCount',
    title: 'Events count',
    width: 100,
  },
  {
    id: 'publicationsCount',
    title: 'Publications Count',
    width: 120,
  },
];

const TotalsTab = ({ articlesAndClicksStore }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').format('YYYY-MM-DD').toString(),
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString(),
  );

  const isDateDiffLessThen180 =
    moment(endDate).diff(moment(startDate), 'days') <= 180;
  const isDateDiffPositive =
    moment(endDate).diff(moment(startDate), 'days') > 0;

  const isMounted = useRef(false);

  const {
    fetchTotals,
    chartData,
    isLoading,
    articleViews,
    articlePublications,
    fetchExcelTotals,
    isLoadingExcelData,
    excelData,
  } = articlesAndClicksStore;

  useEffect(() => {
    if (isMounted.current && isDateDiffLessThen180 && isDateDiffPositive) {
      fetchExcelTotals(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    isMounted.current = true;
    fetchTotals(moment().subtract(180, 'days'));
    fetchExcelTotals(startDate, endDate);
  }, []);

  const keys = [
    {
      key: 'eventsCount',
      label: 'Number of article views',
      color: '#000000',
      show: true,
    },
    {
      key: 'publicationsCount',
      label: 'Number of publications with articles',
      color: '#A14515',
      show: true,
    },
    { key: 'percentage', color: '#123456', show: false },
  ];

  if (isLoading) {
    return <Loader size={60} />;
  }

  return (
    <div>
      <ChartContainer>
        <CustomLineChart data={chartData} keys={keys} xAxisKey="date" />
      </ChartContainer>
      <CountersContainer>
        <Counters
          isLoading={false}
          articleViews={articleViews}
          articlePublications={articlePublications}
        />
      </CountersContainer>
      <CustomRangeExport
        columns={excelColumns}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        disableButton={!isDateDiffLessThen180 || !isDateDiffPositive}
        isLoading={isLoadingExcelData}
        data={excelData}
        fileName="Totals"
        sheetName="Totals"
      />
    </div>
  );
};

TotalsTab.propTypes = {
  articlesAndClicksStore: PropTypes.shape({
    fetchTotals: PropTypes.func,
    fetchExcelTotals: PropTypes.func,
    chartData: PropTypes.array,
    excelData: PropTypes.array,
    isLoading: PropTypes.bool,
    isLoadingExcelData: PropTypes.bool,
    articleViews: PropTypes.any,
    articlePublications: PropTypes.any,
  }),
};

export default inject('articlesAndClicksStore')(observer(TotalsTab));
