import { action, observable } from 'mobx';
import moment from 'moment';
import merge from 'lodash/mergeWith';
import queryString from 'query-string';
import API from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';


export class NewspapersStore {
  DEFAULT_QUERY_PARAMS = {
    creation_date_from: moment().subtract({ year: 10 }).format('YYYY-MM-DD'),
    creation_date_to: moment().format('YYYY-MM-DD'),
    update_date_from: moment().subtract({ year: 10 }).format('YYYY-MM-DD'),
    update_date_to: moment().format('YYYY-MM-DD'),
    order: 'creation_date_desc',
    limit: 25,
    page: 0,
    article_source: '-1',
    title: '',
    contact_email: '',
  };

  COLUMNS = [
    ['id', 'id'],
    ['title', 'Title'],
    ['contact_email', 'Contact email'],
    ['created_at', 'Created at'],
    ['updated_at', 'Updated at'],
    ['article_sources', 'Article sources'],
  ];

  @observable formParams = {};
  @observable isLoading = false;
  @observable list = [];
  @observable totalRowsNumber = 0;
  @observable hiddenColumns = new Set();
  orders = [
    {label: '\u2191 Creation', value: 'creation_date_desc'},
    {label: '\u2193 Creation', value: 'creation_date_asc'},
    {label: '\u2191 Update', value: 'update_date_desc'},
    {label: '\u2193 Update', value: 'update_date_asc'},
  ];

  @observable sourcesFetched = false;
  @observable formVisible = false;

  SEARCH_CACHE_TIMEOUT = 2 * 60 * 1000;
  searchCache = {};

  @action fetchNewspapers = async ({ search }) => {
    if (search.length > 0 && search[0] === '?') {
      search = search.substring(1);
    }
    if (this.searchCache[search] !== undefined) {
      const { count, newspapers, time, inProgress } = this.searchCache[search];
      if (inProgress === true ) {
        return;
      }
      const delta = new Date() - time;
      if (delta <= this.SEARCH_CACHE_TIMEOUT) {
        this.totalRowsNumber = count;
        this.list = newspapers;
        return;
      }
    }
    this.searchCache[search] = { inProgress: true };
    this.isLoading = true;
    try {
      const {
        data: { count, newspapers }
      } = await API.get(
        `${API_ROUTES.NEWSPAPERS}?${search}`
      );
      this.totalRowsNumber = count;
      this.list = newspapers;
      this.searchCache[search] = { count, newspapers, time: new Date(), inProgress: false };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchSources = async () => {
    if (this.sourcesFetched) {
      return;
    }
    try {
      const {
        data: { article_sources: articleSources }
      } = await API.get(
        API_ROUTES.NEWSPAPER_ARTICLE_SOURCES
      );
      this.articleSources = Object.fromEntries(articleSources.map(row => [row.id, row.title]));
      this.sourcesFetched = true;
    } catch (e) {
      this.error = e;
    }
  };

  @action toggleColumn = async (key) => {
    if (this.hiddenColumns.has(key)) {
      this.hiddenColumns.delete(key);
    } else {
      this.hiddenColumns.add(key);
    }
  };

  @action toggleFilters = async () => {
    this.formVisible = !this.formVisible;
  };

  @action updateSearch = async ({ search }) => {
    this.formParams = {...this.DEFAULT_QUERY_PARAMS};
    this.mergeParams({ newParams: queryString.parse(search) });
  };

  @action mergeParams = async ({ newParams }) => {
    this.formParams = merge(this.DEFAULT_QUERY_PARAMS, this.formParams, newParams);
    const search = queryString.stringify(this.formParams);
    await this.fetchNewspapers({ search });
    return { pathname: APP_ROUTES.CLIENT_COVERAGES, search };
  };
}

export default new NewspapersStore();