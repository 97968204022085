import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import Paper from '@material-ui/core/Paper';

const EVENT_TYPES = [
  { label: 'Action:', key: 'action' },
  { label: 'Category:', key: 'category' },
  { label: 'Medium:', key: 'medium' },
  { label: 'Type:', key: 'type' },
  { label: 'User email:', key: 'user_email' },
  { label: 'Source name:', key: 'source_name' },
  { label: 'cid:', key: 'cid' },
  { label: 'Publication ID:', key: 'content_publication_id' },
  { label: 'Object name:', key: 'object_name' },
  { label: 'Path:', key: 'path' },
  { label: 'Title:', key: 'title' },
  { label: 'Template:', key: 'template' },
  { label: 'Timestamp:', key: 'timestamp' },
  { label: 'Subscriptions:', key: 'subscriptions' },
  { label: 'Location:', key: 'location' },
  { label: 'Time:', key: 'time' },
  { label: 'User agent:', key: 'user_agent' },
  { label: 'Version:', key: 'version' },
  { label: 'Screen viewport:', key: 'screen_viewport' },
  { label: 'Screen resolution:', key: 'screen_resolution' },
];

const style = { whiteSpace: 'nowrap' };

const EventsList = ({ events }) => (
  <>
    {events.map((event, idx) => (
      <Box py={10} key={`event-type-${idx}`} width={1}>
        <Paper>
          <Box p={20} width={1}>
            {EVENT_TYPES.filter(({ key }) => event[key]).map(
              ({ label, key }, i) => (
                <Flex width={1} key={`event-type-key-${i}`}>
                  <Box pr={10} width={150}>
                    <Text
                      style={style}
                      color="gray"
                      textAlign="right"
                      fontSize={16}
                    >
                      {label}
                    </Text>
                  </Box>
                  <Box width={1}>
                    <Text fontSize={16}>
                      {key === 'subscriptions' && event[key]
                        ? event[key].join(', ')
                        : event[key] || '-'}
                    </Text>
                  </Box>
                </Flex>
              ),
            )}
          </Box>
        </Paper>
      </Box>
    ))}
  </>
);

EventsList.propTypes = {
  events: PropTypes.array,
};

export default EventsList;
