// eslint-disable-next-line max-classes-per-file
import { action, observable } from 'mobx';
import moment from 'moment';
import merge from 'lodash/mergeWith';
import queryString from 'query-string';
import API from '../_app/api';
import { API_ROUTES, APP_ROUTES } from '../_app/routes';

export class ClientCoveragesStore {
  DEFAULT_QUERY_PARAMS = {
    creation_date_from: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
    creation_date_to: moment().format('YYYY-MM-DD'),
    publication_date_from: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
    publication_date_to: moment().format('YYYY-MM-DD'),
    order: 'creation_date_desc',
    company_name: '',
    source: '',
    newspaper: '',
    analyst: '-1',
    editor: '-1',
    published_by: '-1',
    for_analyst: false,
    limit: 10,
    page: 0,
  };

  @observable formParams = {};
  @observable isLoading = false;
  @observable report = [];
  @observable totalRowsNumber = 0;
  @observable listsFetched = false;
  @observable preview = {};
  @observable analysts = [{label: 'Any', value: '-1'}];
  @observable editors = [{label: 'Any', value: '-1'}];
  @observable publishers = [{label: 'Any', value: '-1'}];

  @observable formVisible = false;

  SEARCH_CACHE_TIMEOUT = 2 * 60 * 1000;
  searchCache = {};
  previewCache = {};

  @action fetchClientCoverages = async ({ search }) => {
    if (search.length > 0 && search[0] === '?') {
      search = search.substring(1);
    }
    if (this.searchCache[search] !== undefined) {
      const { count, report, time, inProgress } = this.searchCache[search];
      if (inProgress === true ) {
        return;
      }
      const delta = new Date() - time;
      if (delta <= this.SEARCH_CACHE_TIMEOUT) {
        this.totalRowsNumber = count;
        this.report = report;
        return;
      }
    }
    this.searchCache[search] = { inProgress: true };
    this.isLoading = true;
    try {
      const {
        data: { data: { count, report } }
      } = await API.get(
        `${API_ROUTES.CLIENT_COVERAGES}?${search}`
      );
      this.totalRowsNumber = count;
      this.report = report;
      this.searchCache[search] = { count, report, time: new Date(), inProgress: false };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchClientCoverage = async ({ id }) => {
    if (id === undefined) {
      return;
    }
    if (this.previewCache[id] !== undefined) {
      const { report, time, inProgress } = this.previewCache[id];
      if (inProgress === true ) {
        return;
      }
      const delta = new Date() - time;
      if (delta <= this.SEARCH_CACHE_TIMEOUT) {
        [this.preview] = report;
        return;
      }
    }
    this.previewCache[id] = { inProgress: true };
    this.isLoading = true;
    try {
      const {
        data: { report }
      } = await API.get(
        API_ROUTES.CLIENT_COVERAGE_PREVIEW(id)
      );
      [this.preview] = report;
      this.previewCache[id] = { report, time: new Date(), inProgress: false };
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchLists = async () => {
    try {
      const {
        data: { data: { analysts, editors, publishers } }
      } = await API.get(
        API_ROUTES.CLIENT_COVERAGES_ANALYSTS_LISTS
      );
      this.analysts = [{label: 'Any', value: '-1'}].concat(analysts.map(item => ({ label: item.email, value: item.id })));
      this.editors = [{label: 'Any', value: '-1'}].concat(editors.map(item => ({ label: item.email, value: item.id })));
      this.publishers = [{label: 'Any', value: '-1'}].concat(publishers.map(item => ({ label: item.email, value: item.id })));
      this.listsFetched = true;
    } catch (e) {
      this.error = e;
    }
  };

  @action toggleFilters = async () => {
    this.formVisible = !this.formVisible;
  };

  @action updateSearch = async ({ search }) => {
    this.formParams = {...this.DEFAULT_QUERY_PARAMS};
    this.mergeParams({ newParams: queryString.parse(search) });
  };

  @action mergeParams = async ({ newParams }) => {
    this.formParams = merge(this.DEFAULT_QUERY_PARAMS, this.formParams, newParams);
    const search = queryString.stringify(this.formParams);
    await this.fetchClientCoverages({ search });
    return { pathname: APP_ROUTES.CLIENT_COVERAGES, search };
  };
}

export default new ClientCoveragesStore();
