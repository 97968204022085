import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
  bigAvatar: {
    marginRight: 20,
    width: 40,
    height: 40,
  },
  userName: {
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const Wrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.secondaryPurple};
`;

const UserInfoBox = ({ profile }) => {
  const classes = useStyles();
  return (
    <Wrapper width={1} py={15} alignItems="center" justifyContent="center">
      <Avatar
        alt="avatar"
        src={profile.avatar_url || '/images/avatar-placeholder.png'}
        className={classes.bigAvatar}
      />

      <Box>
        <Typography variant="subtitle2" className={classes.userName}>
          {profile.displayName}
        </Typography>
        <Typography variant="caption">Admin</Typography>
      </Box>
    </Wrapper>
  );
};

UserInfoBox.propTypes = {
  profile: PropTypes.object,
};

export default UserInfoBox;
