import { Paper } from '@material-ui/core';
import { Text } from 'rebass';
import * as PropTypes from 'prop-types';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import DatePickers from '../../_common/components/DatePickers';
import { ExportContainer } from '../../_common/components/Containers';
import ExcelDownload from './ExcelDownload';

const CustomRangeExport = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  marginLeft = 30,
  columns,
  fileName,
  data,
  sheetName,
  isLoading,
  disableButton,
}) => (
  <Paper style={{ marginLeft, marginTop: 20 }}>
    <ExportContainer>
      <Text
        style={{ marginRight: 20, marginTop: 10, minWidth: 'max-content' }}
        fontSize={23}
        fontWeight="bold"
      >
        Export date range
      </Text>
      <DatePickers
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
      <ExcelDownload
        columns={columns}
        fileName={fileName}
        data={data}
        isLoading={isLoading}
        sheetName={sheetName}
        disableButton={disableButton}
      />
      {isLoading && (
        <div style={{ marginTop: 18 }}>
          <CircularProgress size={20} />
        </div>
      )}
    </ExportContainer>
  </Paper>
);

export default CustomRangeExport;

CustomRangeExport.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  marginLeft: PropTypes.number,
  columns: PropTypes.array,
  fileName: PropTypes.string,
  sheetName: PropTypes.string,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  disableButton: PropTypes.bool,
};
