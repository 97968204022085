import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BaseHeatMap from './BaseHeatMap';
import { subtractMatrixes } from '../utils/matrix';
import { subLegendValue } from './HeatMapLegend';

const SubtractHeatMap = ({ data, avgData, label }) => {
  const subMatrix = useMemo(
    () => subtractMatrixes(data, avgData),
    [data, avgData],
  );
  const colorsForCells = (x, y) => {
    const subArticles = subMatrix[x][y];

    return subArticles < subLegendValue[0].val
      ? subLegendValue[0].color
      : subLegendValue[1].color;
  };

  return (
    <BaseHeatMap
      label={label}
      data={subMatrix}
      colorsForCells={colorsForCells}
    />
  );
};

export default SubtractHeatMap;

SubtractHeatMap.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfArticles: PropTypes.number,
      time: PropTypes.shape({
        hour: PropTypes.number,
        day: PropTypes.number,
      }),
    }),
  ),
  avgData: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfArticles: PropTypes.number,
      time: PropTypes.shape({
        hour: PropTypes.number,
        day: PropTypes.number,
      }),
    }),
  ),
};
