import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import React, { Component } from 'react';
import DailyTrafficChart from './components/DailyTrafficChart';
import RecentEvents from './components/RecentEvents';

@inject('dashboardStore')
@observer
class Dashboard extends Component {
  componentDidMount() {
    this.props.dashboardStore.fetchTraffic({});
    this.props.dashboardStore.fetchRecentEvents();
  }

  onDateChange = (event) => {
    const params = {};
    params[event.target.id] = event.target.value;
    this.props.dashboardStore.fetchTraffic(params);
    this.props.dashboardStore.fetchRecentEvents();
  };

  onDateReset = (params) => {
    this.props.dashboardStore.fetchTraffic(params);
    this.props.dashboardStore.fetchRecentEvents();
  };

  render() {
    const {
      trafficData: { traffic, devices },
      recentEvents,
      isLoading,
    } = this.props.dashboardStore;

    return (
      <>
        <DailyTrafficChart
          traffic={traffic}
          devices={devices}
          onDateChange={this.onDateChange}
          onDateReset={this.onDateReset}
        />
        <Box pt={30} />
        <RecentEvents data={recentEvents} isLoading={isLoading} />
        <Box pt={30} />
      </>
    );
  }
}

Dashboard.wrappedComponent.propTypes = {
  dashboardStore: PropTypes.shape({
    fetchTraffic: PropTypes.func,
    trafficData: PropTypes.object,
    fetchRecentEvents: PropTypes.func,
    recentEvents: PropTypes.array,
    isLoading: PropTypes.bool,
  }),
};

export default Dashboard;
