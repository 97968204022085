import React from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import ReportTable from '../../_common/components/ReportTable';
import Loader from '../../_common/components/Loader';
import { APP_ROUTES } from '../../_app/routes';
import { COLUMNS } from  '../constants';

const ContentPublicationsList = ({
  onSubmit,
  report,
  totalRowsNumber,
  isLoading,
  limit,
  page,
}) => (
  <Paper style={{ width: '100%', marginTop: '16px' }}>
    {isLoading &&
      <Backdrop open style={{ zIndex: 1000, backgroundColor: 'white', opacity: 0.5 }}>
        <Loader size={60} />
      </Backdrop>
    }
    <ReportTable
      report={report}
      columns={COLUMNS}
      defaultHidden={['Body']}
      idToPath={(id) => generatePath(APP_ROUTES.CONTENT_PUBLICATION_PREVIEW, { id })}
      onSubmit={onSubmit}
      totalRowsNumber={totalRowsNumber}
      limit={limit}
      page={page}
    />
  </Paper>
);

ContentPublicationsList.propTypes = {
  onSubmit: PropTypes.func,
  report: PropTypes.array,
  totalRowsNumber: PropTypes.number,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  page: PropTypes.number,
};

export default ContentPublicationsList;