import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { Typography } from '@material-ui/core';

const TitleWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.mainPurple};
  color: #fff;
`;

const SidebarHeader = () => (
  <TitleWrapper width={1} alignItems="center" justifyContent="center">
    <Typography variant="h6" component="h6">
      Statistics dashboard
    </Typography>
  </TitleWrapper>
);

export default SidebarHeader;
