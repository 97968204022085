import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import merge from 'lodash/merge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import ReportsForm from './components/forms/ReportsForm';
import { APP_ROUTES } from '../_app/routes';
import SubscriptionDetailsList from './components/SubscriptionDetailsList';

const columns = [
  {
    id: 'email',
    label: 'Email',
    minWidth: 20,
    align: 'left',
    excelWidth: 300,
  },
  {
    id: 'content_orders',
    label: 'Active content orders',
    minWidth: 20,
    align: 'left',
    excelWidth: 450,
  },
  {
    id: 'created_at',
    label: 'Created at',
    minWidth: 20,
    align: 'left',
    format: (value) => moment(value).format('DD.MM.YYYY HH:mm'),
    formatExcel: (value) => moment(value).format('DD.MM.YYYY'),
    excelWidth: 120,
  },
  {
    id: 'current_sign_in_at',
    label: 'Current sign in at',
    minWidth: 20,
    align: 'left',
    format: (value) =>
      value ? moment(value).format('DD.MM.YYYY HH:mm') : '(no data)',
    formatExcel: (value) =>
      value ? moment(value).format('DD.MM.YYYY') : '(no data)',
    excelWidth: 120,
  },
  {
    id: 'last_email_opened_at',
    label: 'Last email opened at',
    minWidth: 20,
    align: 'left',
    format: (value) =>
      value ? moment(value).format('DD.MM.YYYY HH:mm') : '(no data)',
    formatExcel: (value) =>
      value ? moment(value).format('DD.MM.YYYY') : '(no data)',
    excelWidth: 130,
  },
  {
    id: 'all_email_notifications_enabled',
    label: 'All email notifications enabled',
    minWidth: 20,
    align: 'center',
    format: (value) =>
      value ? <NotificationsIcon /> : <NotificationsOffIcon />,
    formatExcel: (value) => (value ? 'on' : 'off'),
    excelWidth: 180,
  },
  {
    id: 'all_push_notifications_enabled',
    label: 'All push notifications enabled',
    minWidth: 20,
    align: 'center',
    format: (value) =>
      value ? <NotificationsIcon /> : <NotificationsOffIcon />,
    formatExcel: (value) => (value ? 'on' : 'off'),
    excelWidth: 180,
  },
  {
    id: 'emails_sent',
    label: 'Total emails sent',
    minWidth: 20,
    align: 'center',
    excelWidth: 120,
  },
  {
    id: 'emails_opened',
    label: 'Total emails opened',
    minWidth: 20,
    align: 'center',
    excelWidth: 120,
  },
  {
    id: 'unique_emails_opened',
    label: 'Unique emails opened',
    minWidth: 20,
    align: 'center',
    excelWidth: 140,
  },
  {
    id: 'percent_unique_emails',
    label: 'Unique emails opened %',
    minWidth: 20,
    align: 'center',
    excelWidth: 150,
    format: (value) => parseFloat(value.toFixed()),
    formatExcel: (value) => parseFloat(value.toFixed()),
  },
  {
    id: 'web_opened',
    label: 'Web app opened',
    minWidth: 20,
    align: 'center',
    excelWidth: 120,
  },
  {
    id: 'ios_app_opened',
    label: 'iOS app opened',
    minWidth: 20,
    align: 'center',
    excelWidth: 120,
  },
];

const DEFAULT_QUERY_PARAMS = {
  dateFrom: moment().subtract({ month: 1 }).format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD'),
};

@inject('reportsStore')
@observer
class Reports extends Component {
  componentDidMount() {
    const {
      location: { search },
      reportsStore: { fetchSubscriptionDetails },
    } = this.props;

    if (isEmpty(search)) {
      this.onSubmit(this.initialValues());
    }
    if (search === `?${queryString.stringify(this.initialValues())}`) {
      fetchSubscriptionDetails(search);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (isEmpty(search)) {
      return this.onSubmit(this.initialValues());
    }
    if (prevProps.location.search !== search) {
      this.props.reportsStore.fetchSubscriptionDetails(search);
    }
  }

  onSubmit = (values) =>
    this.props.history.push({
      pathname: APP_ROUTES.REPORTS,
      search: queryString.stringify(values),
    });

  initialValues = () =>
    merge(DEFAULT_QUERY_PARAMS, queryString.parse(this.props.location.search));

  render() {
    const { subscriptions, subscriptionDetails, isLoadingSubscriptionDetails } =
      this.props.reportsStore;

    const { subscription } = queryString.parse(this.props.location.search);

    let subscriptionName = 'default';
    if (subscription) {
      const chosenSubscription = subscriptions.filter(
        (sub) => sub.name === subscription,
      );
      if (chosenSubscription[0]) {
        subscriptionName = chosenSubscription[0].name;
      }
    }

    return (
      <>
        <ReportsForm
          isLoadingSubscriptionDetails={isLoadingSubscriptionDetails}
          subscriptionDetails={subscriptionDetails}
          subscriptionName={subscriptionName}
          columns={columns}
          onSubmit={this.onSubmit}
          initialValues={this.initialValues()}
          subscriptions={subscriptions}
          fetchSubscriptions={this.props.reportsStore.fetchSubscriptions}
        />
        <SubscriptionDetailsList
          columns={columns}
          subscriptionName={subscriptionName}
        />
      </>
    );
  }
}

Reports.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  reportsStore: PropTypes.shape({
    fetchSubscriptionDetails: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
    subscriptions: PropTypes.array,
    isLoadingSubscriptionDetails: PropTypes.bool,
    subscriptionDetails: PropTypes.array,
  }),
};

export default Reports;
