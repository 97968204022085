import Select, { components } from 'react-select';
import * as PropTypes from 'prop-types';
import React from 'react';
import { SelectContainer } from './Containers';

const Menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 10 ? (
        props.children
      ) : (
        <div style={{ padding: '8px 12px' }}>Select maximum of 10 elements</div>
      )}
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.any,
  getValue: PropTypes.func,
};

const ReactSelect = ({
  onChange,
  options,
  width = '400px',
  isMulti,
  margin,
  placeholder,
  customStyles,
  customComponents = {},
  value,
}) => (
  <SelectContainer margin={margin} width={width}>
    <Select
      value={
        isMulti ? value : options.filter((option) => option.value === value)
      }
      placeholder={placeholder}
      isMulti={isMulti}
      TextFieldProps={{
        InputLabelProps: {
          shrink: true,
        },
      }}
      options={options}
      onChange={(option) => {
        if (isMulti) {
          if (!option) {
            onChange([]);
          } else {
            onChange(option);
          }
        } else {
          onChange(option.value);
        }
      }}
      components={{ Menu, ...customComponents }}
      styles={customStyles}
    />
  </SelectContainer>
);

export default ReactSelect;

ReactSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMulti: PropTypes.bool,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  customStyles: PropTypes.any,
  customComponents: PropTypes.any,
  value: PropTypes.any,
};
