import { action, observable } from 'mobx';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class EventsStore {
  @observable isLoading = false;
  @observable isLoadingCounters = false;
  @observable events = [];
  @observable error = null;
  @observable pagesTotal = 0;
  @observable itemsTotal = 0;
  @observable eventsCounters = {};
  @observable barChartData = [];

  @action fetchEvents = async (searchQuery) => {
    this.isLoading = true;
    this.events = [];
    try {
      const {
        data: { data, pagesTotal, itemsTotal },
      } = await API.get(`${API_ROUTES.EVENTS}${searchQuery}`);
      this.events = data;
      this.pagesTotal = pagesTotal;
      this.itemsTotal = itemsTotal;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  @action fetchEventsCounters = async (query) => {
    this.isLoadingCounters = true;
    try {
      const { data } = await API.get(`${API_ROUTES.EVENTS_COUNTERS}${query}`);
      this.eventsCounters = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingCounters = false;
    }
  };

  @action fetchEventsCountsPerDay = async (query) => {
    try {
      const { data } = await API(`${API_ROUTES.EVENTS_COUNTS_PER_DAY}${query}`);
      this.barChartData = data;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoadingCounters = false;
    }
  };
}

export default new EventsStore();
