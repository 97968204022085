import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ChartContainer } from '../_common/components/Containers';
import CustomLineChart from '../articlesAndClicks/components/CustomLineChart';
import ReactSelect from '../_common/components/ReactSelect';
import Loader from '../_common/components/Loader';
import DatePickers from '../_common/components/DatePickers';
import ExcelDownload from '../articlesAndClicks/components/ExcelDownload';

const excelColumns = [
  {
    id: 'date',
    title: 'Date',
    width: 100,
  },
  {
    id: 'publicationsCount',
    title: 'Publications Count',
    width: 120,
  },
];

const AnalystStatistics = ({ analystsStatisticsStore }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(365, 'days').format('YYYY-MM-DD').toString(),
  );
  const [endDate, setEndDate] = useState(
    moment().format('YYYY-MM-DD').toString(),
  );
  const [analystId, setAnalystId] = useState(null);

  const isDateDiffLessThen180 =
    moment(endDate).diff(moment(startDate), 'days') <= 365;
  const isDateDiffPositive =
    moment(endDate).diff(moment(startDate), 'days') > 0;

  const isMounted = useRef(false);

  const {
    fetchPublicationsCount,
    chartData,
    isLoading,
    analysts,
    fetchAnalysts,
    isLoadingAnalysts,
    getAnalystName,
  } = analystsStatisticsStore;

  useEffect(() => {
    if (isMounted.current) {
      if (analystId) {
        fetchPublicationsCount(startDate, endDate, analystId);
      }
    }
  }, [analystId]);

  useEffect(() => {
    if (
      isMounted.current &&
      isDateDiffLessThen180 &&
      isDateDiffPositive &&
      analystId
    ) {
      fetchPublicationsCount(startDate, endDate, analystId);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    isMounted.current = true;
    fetchAnalysts();
  }, []);

  const keys = [
    {
      key: 'publicationsCount',
      label: 'Number of publications for analyst',
      color: '#8e7cc3',
      show: true,
    },
  ];

  let analystName = null;
  if (analystId) {
    analystName = getAnalystName(analystId);
  }

  return (
    <div>
      {(isLoading || isLoadingAnalysts) && <Loader size={60} />}
      {!isLoadingAnalysts && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ReactSelect
            value={analystId}
            onChange={(newValue) => setAnalystId(newValue)}
            options={analysts}
          />
          <div style={{ display: 'flex' }}>
            <DatePickers
              margin="19px 0 0 0"
              dateRangeDays={365}
              size="small"
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <ExcelDownload
              buttonWidth="100%"
              margin="19px 0 0 0"
              buttonHeight={40}
              columns={excelColumns}
              fileName={`Statistics for ${analystName || '(no analyst name)'}`}
              sheetName={analystName || '(no analyst name)'}
              data={chartData}
              isLoading={isLoading}
              disableButton={
                !isDateDiffLessThen180 || !isDateDiffPositive || !analystId
              }
            />
            {isLoading && (
              <div style={{ marginTop: 18 }}>
                <CircularProgress size={20} />
              </div>
            )}
          </div>
        </div>
      )}
      {!isLoading && analystId && (
        <div>
          <ChartContainer>
            <CustomLineChart data={chartData} keys={keys} xAxisKey="date" />
          </ChartContainer>
        </div>
      )}
    </div>
  );
};

AnalystStatistics.propTypes = {
  analystsStatisticsStore: PropTypes.shape({
    fetchPublicationsCount: PropTypes.func,
    fetchAnalysts: PropTypes.func,
    getAnalystName: PropTypes.func,
    analysts: PropTypes.array,
    chartData: PropTypes.array,
    isLoading: PropTypes.bool,
    isLoadingAnalysts: PropTypes.bool,
    excelData: PropTypes.array,
  }),
};

export default inject('analystsStatisticsStore')(observer(AnalystStatistics));
