import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ClientCoveragesList from './components/list';
import ClientCoveragesForm from './components/forms/filters';

@inject('clientCoveragesStore')
@observer
class ClientCoverages extends Component {

  async componentDidMount() {
    await this.updateStore();
  }

  async componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    if (prevProps.location.search !== search) {
      await this.updateStore();
    }
  }

  async onSubmit(newParams) {
    this.props.history.push(await this.props.clientCoveragesStore.mergeParams({ newParams }));
  }

  async updateStore() {
    await this.props.clientCoveragesStore.updateSearch({ search: this.props.location.search });
  }

  async toggleForAnalysts() {
    this.onSubmit({ for_analyst: this.props.clientCoveragesStore.formParams.for_analyst?.toString() !== 'true' });
  }

  render() {
    const {
      formParams,
      DEFAULT_QUERY_PARAMS,
      report,
      totalRowsNumber,
      fetchLists,
      listsFetched,
      isLoading,
      analysts,
      editors,
      publishers,
      formVisible,
      toggleFilters,
    } = this.props.clientCoveragesStore;
    return (
      <> 
        <ClientCoveragesForm
          initialValues={DEFAULT_QUERY_PARAMS}
          onSubmit={async (...args) => this.onSubmit(...args)}
          fetchLists={fetchLists}
          listsFetched={listsFetched}
          analysts={analysts}
          editors={editors}
          publishers={publishers}
          forAnalysts={formParams.for_analyst?.toString() === 'true'}
          toggleForAnalysts={async (...args) => this.toggleForAnalysts(...args)}
          formVisible={formVisible}
          toggleFilters={toggleFilters}
        />
        <ClientCoveragesList
          onSubmit={async (...args) => this.onSubmit(...args)}
          report={report}
          totalRowsNumber={totalRowsNumber}
          isLoading={isLoading}
          forAnalysts={formParams.for_analyst?.toString() === 'true'}
          limit={parseInt(formParams.limit, 10)}
          page={parseInt(formParams.page, 10)}
        />
      </>
    );
  }
};

ClientCoverages.wrappedComponent.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  clientCoveragesStore: PropTypes.shape({
    formParams: PropTypes.object,
    updateSearch: PropTypes.func,
    mergeParams: PropTypes.func,
    DEFAULT_QUERY_PARAMS: PropTypes.object,
    report: PropTypes.array,
    totalRowsNumber: PropTypes.any,
    fetchLists: PropTypes.func,
    listsFetched: PropTypes.bool,
    isLoading: PropTypes.bool,
    analysts: PropTypes.array,
    editors: PropTypes.array,
    publishers: PropTypes.array,
    formVisible: PropTypes.bool,
    toggleFilters: PropTypes.func,
  }),
};

export default ClientCoverages;