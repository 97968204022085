import { action, observable } from 'mobx';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export class ArchivedArticlesStore {
  @observable isLoading = false;
  @observable isLoadingCounters = false;
  @observable archivedArticles = [];
  @observable error = null;
  @observable pagesTotal = 0;
  @observable itemsTotal = 0;
  @observable barChartData = [];

  @action fetchArticles = async (searchQuery) => {
    this.isLoading = true;
    this.archivedArticles = [];
    try {
      const {
        data: { data, pagesTotal, itemsTotal },
      } = await API.get(`${API_ROUTES.ARCHIVED_ARTICLES}${searchQuery}`);
      this.archivedArticles = data;
      this.pagesTotal = pagesTotal;
      this.itemsTotal = itemsTotal;
    } catch (e) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  };

  // @action fetchArticlesCountsPerDay = async (query) => {
  //   try {
  //     const { data } = await API(`${API_ROUTES.ARCHIVED_ARTICLES}${query}`);
  //     this.barChartData = data;
  //   } catch (e) {
  //     this.error = e;
  //   } finally {
  //     this.isLoadingCounters = false;
  //   }
  // };
}

export default new ArchivedArticlesStore();
