import React, { useEffect } from 'react';
import ReactPropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Flex, Box } from 'rebass';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { APP_ROUTES } from '../../_app/routes';
import Loader from '../../_common/components/Loader';
import TextField from '../../_common/components/forms/TextField';
import AutoSave from '../../_common/components/forms/AutoSave';
import FormSelect from '../../_common/components/forms/FormSelect';

export const BRIEFING_TYPE_KEY = 'briefingId';
export const CREATION_FROM_KEY = 'creationFrom';
export const CREATION_TO_KEY = 'creationTo';
export const PUBLICATION_FROM_KEY = 'publicationFrom';
export const PUBLICATION_TO_KEY = 'publicationTo';

function Filters({
  summaryReportsStore: {
    briefingOptions,
    fetchBriefingOptions,
    isLoadingBrefingOptions,
  },
  getInitialValues,
  onSubmit,
  isOpen,
  setOpen,
}) {
  const history = useHistory();

  useEffect(() => {
    fetchBriefingOptions();
  }, []);

  const handleClear = (form) => {
    form.reset();
    history.push({
      pathname: APP_ROUTES.CONTENT_REPORTS,
    });
  };

  return (
    <Form
      onSubmit={(values) => onSubmit({ ...values })}
      initialValues={getInitialValues()}
      render={({ handleSubmit, values, form }) => (
        <form id="SummaryReportsForm" onSubmit={handleSubmit}>
          <AutoSave debounce={1000} save={handleSubmit} values={values} />
          <Paper style={{ padding: '15px 15px 1px 15px', maxWidth: '80vw' }}>
            <Flex mb={20} mx={-15} flexWrap="wrap">
              <Box px={15} width={[1, 1 / 2, 1 / 5]}>
                <Button
                  onClick={() => setOpen(!isOpen)}
                  fullWidth
                  variant={isOpen ? 'outlined' : 'contained'}
                  color="primary"
                >
                  {isOpen ? 'Hide filters' : 'Open filters'}
                </Button>
              </Box>
              {isOpen && (
                <Box px={15} width={[1, 1 / 2, 1 / 5]}>
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={() => handleClear(form)}
                  >
                    Clear filters
                  </Button>
                </Box>
              )}
            </Flex>
            {isOpen && (
              <>
                <Flex mx={-15} flexWrap="wrap">
                  <Box width={[1, 1 / 2, 1 / 5]} px={15}>
                    <TextField
                      label="Creation date from"
                      type="date"
                      name={CREATION_FROM_KEY}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 5]} px={15}>
                    <TextField
                      label="Creation date to"
                      type="date"
                      name={CREATION_TO_KEY}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 5]} px={15}>
                    <TextField
                      label="Publication date from"
                      type="date"
                      name={PUBLICATION_FROM_KEY}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 5]} px={15}>
                    <TextField
                      label="Publication date to"
                      type="date"
                      name={PUBLICATION_TO_KEY}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box width={[1, 1 / 2, 1 / 5]} px={15}>
                    {isLoadingBrefingOptions ? (
                      <Loader />
                    ) : (
                      <FormSelect
                        options={briefingOptions}
                        name={BRIEFING_TYPE_KEY}
                        label="Briefing type"
                      />
                    )}
                  </Box>
                </Flex>
              </>
            )}
          </Paper>
        </form>
      )}
    />
  );
}

Filters.propTypes = {
  summaryReportsStore: ReactPropTypes.shape({
    fetchBriefingOptions: MobXPropTypes.func,
    briefingOptions: MobXPropTypes.array,
    isLoadingBrefingOptions: MobXPropTypes.bool,
  }),
  getInitialValues: ReactPropTypes.func,
  onSubmit: ReactPropTypes.func,
  isOpen: ReactPropTypes.bool,
  setOpen: ReactPropTypes.func,
};

export default inject('summaryReportsStore')(observer(Filters));
