import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const META_LABELS = [
  { label: 'Name:', key: 'company_name' },
  { label: 'Type:', key: 'publication_type' },
  {
    label: 'Published at:',
    key: 'published_at',
    valueFn: (e) => moment(e).format(DATE_FORMAT),
  },
  {
    label: 'Created at:',
    key: 'created_at',
    valueFn: (e) => moment(e).format(DATE_FORMAT),
  },
  { label: 'Article source:', key: 'article_source' },
  { label: 'Content type:', key: 'content_type' },
  { label: 'Sentiment:', key: 'sentiment' },
];

const CONTENT_LABELS = [
  { label: 'Title:', key: 'title' },
  { label: 'Body:', key: 'body' },
];

const style = { whiteSpace: 'nowrap' };

const pickColor = (sentiment) => {
  switch (sentiment) {
    case 'positive':
      return '#eeffee';
    case 'neutral':
      return '#ffffee';
    case 'negative':
      return '#ffeeee';
    default:
      return '#ffffff';
  }
};

const paperStyle = (sentiment) => {
  const color = pickColor(sentiment);
  return {
    display: 'flex',
    'flex-direction': 'row',
    'background-color': color,
  };
};

const SentimentsList = ({ sentiments }) => (
  <>
    {sentiments.map((sentiment, idx) => (
      <Box py={10} key={`sentiment-type-${idx}`} width={1}>
        <Paper style={paperStyle(sentiment.sentiment)}>
          <Box p={20} width={1 / 3}>
            {META_LABELS.filter(({ key }) => sentiment[key]).map(
              ({ label, key, valueFn }, i) => (
                <Flex width={1} key={`sentiment-type-key-${i}`}>
                  <Box pr={10} width={150}>
                    <Text
                      style={style}
                      color="gray"
                      textAlign="right"
                      fontSize={16}
                    >
                      {label}
                    </Text>
                  </Box>
                  <Box width={1}>
                    <Text fontSize={16}>
                      {valueFn ? valueFn(sentiment[key]) : sentiment[key]}
                    </Text>
                  </Box>
                </Flex>
              ),
            )}
          </Box>
          <Box p={20} width={2 / 3}>
            {CONTENT_LABELS.filter(({ key }) => sentiment[key]).map(
              ({ label, key, valueFn }, i) => (
                <Flex width={1} key={`sentiment-type-key-${i}`}>
                  <Box pr={10} width={150}>
                    <Text
                      style={style}
                      color="gray"
                      textAlign="right"
                      fontSize={16}
                    >
                      {label}
                    </Text>
                  </Box>
                  <Box width={1}>
                    <Text fontSize={16}>
                      {valueFn ? valueFn(sentiment[key]) : sentiment[key]}
                    </Text>
                  </Box>
                </Flex>
              ),
            )}
          </Box>
        </Paper>
      </Box>
    ))}
  </>
);

SentimentsList.propTypes = {
  sentiments: PropTypes.array,
};

export default SentimentsList;
