import styled from 'styled-components';

export const MatrixContainer = styled.div`
  margin: 30px 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

export const MatrixLabel = styled.p`
  padding-left: 8px;
  max-width: 200px;
  font-size: 1rem;
  text-align: center;
  min-height: 50px;
`;
