import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Box } from 'rebass';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import API from '../../../_app/api';

const FormSelect = ({
  name,
  label,
  validate,
  endpoint,
  options: defaultOptions,
  labelKey,
  valueKey,
  containerProps = {},
}) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
    if (endpoint) {
      const fetchData = async () => {
        const options = await fetchOptions(endpoint); // eslint-disable-line
        setOptions(options);
      };
      fetchData();
    } else {
      setOptions(defaultOptions);
    }
  }, []);

  return (
    <Field name={name} validate={validate}>
      {(
        { input, meta }, // eslint-disable-line
      ) => (
        <Box mb={16} width={1} {...containerProps}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel ref={inputLabel} htmlFor={`outlined-${name}-simple`}>
              {label}
            </InputLabel>
            <Select
              value={input.value}
              onChange={input.onChange}
              input={
                <OutlinedInput
                  labelWidth={labelWidth}
                  name={name}
                  id={`outlined-${name}-simple`}
                />
              }
            >
              {options.map((option) => (
                <MenuItem
                  key={`form-select-${name}-${option[valueKey]}`}
                  value={option[valueKey]}
                >
                  {option[labelKey]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Field>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  validate: PropTypes.func,
  endpoint: PropTypes.string,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  containerProps: PropTypes.any,
};

FormSelect.defaultProps = {
  options: [],
  endpoint: '',
  valueKey: 'value',
  labelKey: 'label',
};

async function fetchOptions(endpoint) {
  try {
    const { data } = await API(endpoint);
    return [{ label: '', value: '' }, ...data];
  } catch (e) {
    return [];
  }
}

export default FormSelect;
