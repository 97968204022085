import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { ReportTableCell } from './ReportTableCell';
import { ReportColumnsSelector, ReportColumnsSelectorField } from './ReportColumnsSelector';

const ReportTable = ({
  report,
  columns,
  defaultHidden,
  idToPath,
  onSubmit,
  totalRowsNumber,
  limit,
  page,
}) => {
  const history = useHistory();

  const handleChangePage = async (_event, newPage) => {
    await onSubmit({ page: newPage });
  };

  const handleChangeRowsPerPage = async (event) => {
    await onSubmit({ limit: event.target.value, page: 0 });
  };

  const columnOptions = columns.filter((column) => column.label().length > 0).map((column) => ({label: column.label(), id: column.id}));
  const defaultSelectedColumns = (
    defaultHidden === undefined ?
      columnOptions :
      columnOptions.filter((option) => defaultHidden.indexOf(option.label) === -1));
  const [selectedColumns, setSelectedColumns] = useState(defaultSelectedColumns);

  const columnSelected = (column) => {
    const label = column.label();
    if (label.length === 0) {
      return true;
    }
    return selectedColumns.find((sc) => sc.label === label) !== undefined;
  };

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => columnSelected(column) ? (
                <TableCell
                  id={`report_column_${column.id}`}
                  key={column.id}
                  align={column.align()}
                  title={column.label()}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  { column.label() }
                </TableCell>
              ) : null)}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              report &&
                report.map((row) => (
                  <TableRow key={`report_row_${row.id}`}>
                      {
                        columns.map((column) => {
                          if (!columnSelected(column)) {
                            return;
                          }
                          const cell = column.get(row[column.id], row);
                          const value = cell.text || '';
                          const title = value.length > 21 ? value : null;
                          return (
                            <ReportTableCell
                              key={`report_cell_${row.id}-${column.id}`}
                              align={cell.align}
                              title={title}
                              className={column.id === 'id' && 'link'}
                              onClick={column.id !== 'id' ? () => {} :
                                () => history.push(idToPath(row.id))}
                            >
                              { value }
                            </ReportTableCell>
                          );
                        })
                      }
                  </TableRow>
                ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Flex>
        <Box style={{ width: '14em' }}>
          <ReportColumnsSelector
            multiple
            disableCloseOnSelect
            disableClearable
            options={columnOptions}
            value={selectedColumns}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.label === value.label}
            onChange={(event, newValue) => setSelectedColumns(newValue)}
            renderInput={(params) =>
              <ReportColumnsSelectorField {...params} placeholder="Show/hide columns" InputProps={{ ...params.InputProps, disableUnderline: true }} />}
            renderTags={() => (<></>)}
          />
        </Box>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRowsNumber}
          rowsPerPage={limit || 10}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          style={{ display: 'flex' }}
        />
      </Flex>
    </>
  );
}

ReportTable.propTypes = {
  report: PropTypes.array,
  columns: PropTypes.array,
  defaultHidden: PropTypes.array,
  idToPath: PropTypes.func,
  onSubmit: PropTypes.func,
  totalRowsNumber: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
};

export default ReportTable;