import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const ReportColumnsSelectorField = styled(TextField)`
caret-color: transparent;
`;

export const ReportColumnsSelector = styled(Autocomplete)`
  margin: 8px;
  padding: 2px 8px;

  & input::placeholder {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: #000000;
    opacity: 1;
  }
`;