import React, { useState } from 'react';
import { Tab, Tabs, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import CompanyTab from './tabs/Company';
import SectorTab from './tabs/Sector';

const tabs = [
  { label: 'Per Company', page: <CompanyTab /> },
  { label: 'Per Sector', page: <SectorTab /> },
  // { label: 'Per Company Group', page: <CompanyTab /> },
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#8e7cc3',
  },
})((props) => <Tabs {...props} />);

const AlertsStatistics = ({
  alertsStatisticsStore: { resetCompanyStatistics, resetSectorStatistics },
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    resetCompanyStatistics();
    resetSectorStatistics();
    setValue(newValue);
  };

  return (
    <div>
      <StyledTabs variant="fullWidth" value={value} onChange={handleChange}>
        {tabs.map(({ label }) => (
          <Tab key={`tab-${label}`} label={label} />
        ))}
      </StyledTabs>

      {tabs.map(({ page }, index) => (
        <TabPanel key={`tab-panel-${index}`} value={value} index={index}>
          {page}
        </TabPanel>
      ))}
    </div>
  );
};

AlertsStatistics.propTypes = {
  alertsStatisticsStore: PropTypes.shape({
    resetCompanyStatistics: PropTypes.func,
    resetSectorStatistics: PropTypes.func,
  }).isRequired,
};

export default inject('alertsStatisticsStore')(observer(AlertsStatistics));
