import { action, observable } from 'mobx';
import moment from 'moment';
import queryString from 'query-string';
import { API_ROUTES } from '../_app/routes';
import API from '../_app/api';

export const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const simpleDateTimeFormat = 'YYYY-MM-DD HH:mm Z';

export class DashboardStore {
  @observable trafficData = {};
  @observable dateFromHasErrors = false;
  @observable isLoading = false;
  @observable recentEvents = [];
  @observable formData = {
    date_from: moment()
      .startOf('day')
      .subtract(1, 'week')
      .format(dateTimeFormat),
    date_to: moment().endOf('day').add(1, 'day').format(dateTimeFormat),
  };

  @action fetchTraffic = async ({ dateFrom, dateTo, medium }) => {
    this.trafficData = {};

    if (dateFrom && dateFrom !== '') {
      this.formData.date_from = moment(dateFrom, dateTimeFormat)
        .tz('Europe/London')
        .format(dateTimeFormat);
    }

    if (dateTo && dateTo !== '') {
      this.formData.date_to = moment(dateTo, dateTimeFormat)
        .tz('Europe/London')
        .format(dateTimeFormat);
    }

    const from = moment(this.formData.date_from);
    const to = moment(this.formData.date_to);
    this.setDateValidationFlag(!from.isSameOrBefore(to));

    if (medium && medium !== '') {
      this.formData.medium = medium;
    } else if (medium === '') {
      this.formData.medium = undefined;
    }
    if (!this.dateFromHasErrors) {
      try {
        const { data } = await API.get(
          `${API_ROUTES.TRAFFIC}?${queryString.stringify(this.formData)}`,
        );
        this.trafficData = data;
        this.trafficData.traffic = this.trafficData.traffic.map((it) => {
          it.name = moment(it.name)
            .tz('Europe/London')
            .format(simpleDateTimeFormat);
          return it;
        });
      } catch (e) {
        // TODO: handle errors
      }
    }
  };

  @action fetchRecentEvents = async () => {
    this.isLoading = true;
    this.recentEvents = [];

    const query = {
      limit: 5,
      order: 'desc',
      ...this.formData,
    };

    try {
      const {
        data: { data },
      } = await API.get(`${API_ROUTES.EVENTS}?${queryString.stringify(query)}`);
      this.recentEvents = data;
    } catch (e) {
      // TODO: handle errors
    } finally {
      this.isLoading = false;
    }
  };
  @action setDateValidationFlag(flag) {
    this.dateFromHasErrors = flag;
  }
}

export default new DashboardStore();
