import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';

const ReportLabel = ({
  parentLabel,
  parentLink,
  entryId,
}) => (
  <Breadcrumbs aria-label="breadcrumb">
    <Link
      color="inherit"
      to={parentLink}
      style={{ textDecoration: 'none', color: 'grey' }}
    >
      { parentLabel }
    </Link>
    <Typography
      color="inherit"
      style={{ textDecoration: 'none', color: 'grey' }}
    >
      { entryId }
    </Typography>
  </Breadcrumbs>
);

ReportLabel.propTypes = {
  parentLabel: PropTypes.string,
  parentLink: PropTypes.string,
  entryId: PropTypes.number,
};

export default ReportLabel;