import styled from 'styled-components';
import { Flex } from 'rebass';

export const HeaderWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const Filters = styled(Flex)`
  flex: 1;
  max-width: 600px;
  min-width: 600px !important;

  > div {
    margin-left: 15px;
  }
`;
