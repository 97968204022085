import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

export default class MyChart extends PureComponent {
  render() {
    const { data, xAxisDataKey, barDataKey } = this.props;
    return (
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisDataKey} />
          <YAxis />
          <Tooltip />
          <Bar dataKey={barDataKey} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

MyChart.propTypes = {
  data: PropTypes.array,
  xAxisDataKey: PropTypes.string,
  barDataKey: PropTypes.string,
};

MyChart.defaultProps = {
  xAxisDataKey: 'name',
  barDataKey: 'total',
};
