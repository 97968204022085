import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { FormSpy } from 'react-final-form';

let timeout = null;
const AutoSave = ({ values, save, debounce }) => {
  const [stateValues, setStateValues] = useState(values);

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const isStateEqual = isEqual(stateValues, values);
      if (!isStateEqual) {
        setStateValues(values);
        save(values);
      }
    }, debounce);

    return () => clearTimeout(timeout);
  }, [values]);

  return null;
};

AutoSave.propTypes = {
  values: PropTypes.object,
  debounce: PropTypes.number.isRequired,
  save: PropTypes.func.isRequired,
};

export default (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
);
